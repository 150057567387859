import Carousel from "react-bootstrap/Carousel";
import Select from "react-select";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const SectionThree = () => {
  return (
    <>
      <div className="mobile-view-space">
        <h2 className="section-heading">Learn More about Will</h2>
        <div className="border-line"></div>
      </div>
      <div className="desktop">
        <div>
          <ul className="nav nav-pills nav-justified">
            <li className="nav-item">
              <a className="nav-link active" href="#">
                Basics
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Process
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Document
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                All
              </a>
            </li>
          </ul>
        </div>
        <Row className="mt-1 content">
          <div className="col-md-4 mb-sm-3">
            <div className="card">
              <div className="card-body">
                <h4>
                  What are the eligibility for a person to be as a witness?
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Pharetra convallis posuere rbi leo urna molestie at Lorem
                  ipsum dolor sit amet, onsectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Pharetra convallis posuere rbi leo urna molestie at .
                </p>
                <div>
                  <Link to="#" className="readmore">
                    {" "}
                    <b>Read More</b>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-sm-3">
            <div className="card">
              <div className="card-body">
                <h4>
                  What are the eligibility for a person to be as a witness?
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Pharetra convallis posuere rbi leo urna molestie at Lorem
                  ipsum dolor sit amet, onsectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Pharetra convallis posuere rbi leo urna molestie at .
                </p>
                <div>
                  <Link to="#" className="readmore">
                    {" "}
                    <b>Read More</b>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h4>
                  What are the eligibility for a person to be as a witness?
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Pharetra convallis posuere rbi leo urna molestie at Lorem
                  ipsum dolor sit amet, onsectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Pharetra convallis posuere rbi leo urna molestie at .
                </p>
                <div>
                  <Link to="#" className="readmore">
                    {" "}
                    <b>Read More</b>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
      <div className="slider d-none">
        <div>
          {/* <ul className="">
            <li className="nav-item">
              <a className="nav-link active" href="#">
                All
              </a>
            </li>
          </ul> */}
        </div>
        <Carousel controls={false}>
          <Carousel.Item>
            <div className="card">
              <div className="">
                <h4>
                  What are the eligibility for a person to be as a witness?
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Pharetra convallis posuere rbi leo urna molestie at Lorem
                  ipsum dolor sit amet, onsectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Pharetra convallis posuere rbi leo urna molestie at .
                </p>
                <div>
                  <Link to="#" className="readmore">
                    {" "}
                    <b>Read More</b>
                  </Link>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="card">
              <div className="">
                <h4>
                  What are the eligibility for a person to be as a witness?
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Pharetra convallis posuere rbi leo urna molestie at Lorem
                  ipsum dolor sit amet, onsectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Pharetra convallis posuere rbi leo urna molestie at .
                </p>
                <div>
                  <Link to="#" className="readmore">
                    {" "}
                    <b>Read More</b>
                  </Link>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="card">
              <h4>What are the eligibility for a person to be as a witness?</h4>
              <p>
                Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Pharetra convallis posuere rbi leo urna molestie at Lorem ipsum
                dolor sit amet, onsectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Pharetra
                convallis posuere rbi leo urna molestie at .
              </p>
              <div>
                <Link to="#" className="readmore">
                  <b>Read More</b>
                </Link>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export default SectionThree;
