import * as Yup from "yup";
import { notOnlyWhiteSpace } from "../../../../constants";

export const listObj = { description: "" };
export const initialValues = {
  jewelleryList: [listObj],
  
};
export const validationSchema = Yup.object().shape({
  jewelleryList: Yup.array().of(
    Yup.object().shape({
      description: Yup.string()
        .required("*This is required")
        .min(2, "*This must have at least 2 characters")
        .max(100, "*This can't be longer than 100 characters")
        .matches(
          notOnlyWhiteSpace,
          "*Only Alphabets,Numbers and Spaces are allowed "
        ),
    })
  ),
  //weight: Yup.string().required("*This is required")


});

export const getFormData = (values, asset_type) => {
  let formData = new FormData();

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  formData.append("asset_type", asset_type);
  return formData;
};
