const GetData = function (
  state = {
    PersonalDetail: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    masterAssetsList: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    assetSummary: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    idProoflist: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    CountryList: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    StateList: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    pincodeDetail: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    R_pincodeDetail: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    EditAssetDetail: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    masterAssetDetail: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    AssetDetail: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    assetNominee: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    createDraft: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    deleteDraft: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    past_wills: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    current_wills: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    user_category: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    executorList: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    nomineeList: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    get_user_id_proof: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    will_download_link: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    AssetSummaryPdf: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    DeleteAssetSummaryPdf: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    LandingVideos: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    wishExamples: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    userWishes: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    resend_email_otp: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    user_bank_detail: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    currentAssetSummary: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    email_verified: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
  },
  action
) {
  switch (action.type) {
    case "RESET_GET": {
      state = { ...state };
      state[action.key]["loading"] = true;
      state[action.key]["success"] = false;
      state[action.key]["error"] = false;
      state[action.key]["error_msg"] = "";
      state[action.key]["data"] = "";
      return state;
    }
    case "GET_SUCCESS": {
      state = { ...state };
      state[action.key]["loading"] = false;
      state[action.key]["success"] = true;
      state[action.key]["error"] = false;
      state[action.key]["error_msg"] = "";
      state[action.key]["data"] = action.payload;
      return state;
    }
    case "GET_ERROR": {
      state = { ...state };
      state[action.key]["loading"] = false;
      state[action.key]["success"] = false;
      state[action.key]["error"] = true;
      state[action.key]["data"] = "";
      state[action.key]["error_msg"] = action.payload;
      return state;
    }
    case "RESET_SHADOW_HEADER": {
      state[action.key] = action.payload;
      return state;
    }

    default:
      return state;
  }
};

export default GetData;
