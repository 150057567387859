import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import pdficon from "../../../../assets/images/pdf-icon.png";
import { ProgressBar } from "react-bootstrap";

import styled from "styled-components";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

function DropBox({ onDrop }) {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
  } = useDropzone({
    accept: "application/pdf",
    onDrop,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
  });

  const lists = acceptedFiles.map((list) => {
    const size = formatBytes(list.size);
    return (
      <div key={list.path} className="uploadFileList d-flex">
        <div className="">
          <img src={pdficon} />
        </div>
        <div className="" style={{ width: "65%" }}>
          <span>{list.path}</span>
          {/* <ProgressBar now={60} variant="warning" /> */}
          <ProgressBar now={60} />
          {size}
        </div>
      </div>
    );
  });

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <>
      <section className="dropbox-section">
        <Container
          className="dropbox-container"
          {...getRootProps({ isDragAccept, isFocused, isDragReject })}
        >
          <input {...getInputProps()} />
          <p className="tagline">
            Drag and drop, or{" "}
            <Link to="#" className="drag-drop-browse-link" onClick={open}>
              browse
            </Link>{" "}
            the file
            <br />
            <small>Upload only PDF upto 50MB file</small>
          </p>
        </Container>
      </section>
      <aside>
        <div>{lists}</div>
      </aside>
    </>
  );
}

export default DropBox;
