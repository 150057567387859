import { Form } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Layout3 from "../../layout/Layout3";
//import axios from "axios";
//import { api_base, HTTP_OK, HTTP_VALIDATION_ERROR } from "../../../constants";
import { personalDetail, verify_user, willtype } from "../../../route";
import { useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";
import CustomModal from "../common/CustomModal";
import { customiseFormSuccessMsg } from "../../../util";
import Loader from "../common/Loader/Loader";

const stateKey = "create_new_will";

const WillType = (props) => {
  const [loading, setLoading] = useState(false);
  const [value1, setValue1] = useState(false);
  const [value2, setValue2] = useState(false);
  const [planType, setPlanType] = useState();
  const navigate = useNavigate();

  const submitHandler = (values) => {
    if (formik.isValid === true) {
    }
  };
  const formik = useFormik({
    onSubmit: submitHandler,
  });

  const handleForm = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("type", "continue");
    formData.append("will_type", planType);
    props.dispatch({
      type: "createNewWill",
      key: stateKey,
      payload: formData,
    });
  }

  /*
  const handleForm = (id, will_type) => {
    var sess_data = sessionStorage.getItem("data");
    var sessionData = JSON.parse(sess_data);

    axios
      .post(api_base + "/customer/update-will-type", {
        user_id: sessionData.id,
        will_type: planType,
      })
      .then((res) => {
        console.log(res);
        console.log("response is >>", res.data.data.will_type);
        var storedData = sessionStorage.getItem("data");
        let myObject = storedData ? JSON.parse(storedData) : {};
        myObject.will_type = res.data.data.will_type;
        var updatedData = JSON.stringify(myObject);
        sessionStorage.setItem("data", updatedData);
        navigate("/personal-detail");
      });
  };

  useEffect(() => {}, []);
*/

  const basicPlan = () => {
    setValue1(true);
    setValue2(false);
    setPlanType(1);
  };

  const advancePlan = () => {
    setValue2(true);
    setValue1(false);
    setPlanType(2);
  };

  useEffect(()=>{
    setLoading(false);
    if(props.formResult.success==true){
      setTimeout(()=>{
        let sessionData = sessionStorage.getItem("data");
        let myObject = sessionData ? JSON.parse(sessionData) : {};
        myObject.will_type = planType;
        let updatedData = JSON.stringify(myObject);
        sessionStorage.setItem("data", updatedData);
        navigate(personalDetail);
      },2000)
    }
  },[props.formResult]);

  return (
    <Layout3>
      {loading && <Loader />}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props?.formResult?.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {props?.formResult?.success === true && (
        <CustomModal
          body={customiseFormSuccessMsg(props.formResult.message)}
          stateKey={stateKey}
          type="success"
        />
      )}
      <h2>Select your will type</h2>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="col-md-10 m-auto">
            <section className="section-price mt-lg-5 mt-md-4 mt-sm-3">
              <div className="">
                <Row>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="pb-3 head">
                          <div className="d-flex mb-4">
                            <Form.Check
                              value="basic_will"
                              type="radio"
                              onChange={() => basicPlan()}
                              checked={value1}
                            />
                            <b>Basic Plan</b>
                          </div>
                        </div>
                        <div className="pb-3 price">{/* <b>Rs. 100</b> */}</div>
                        <p>
                        You should opt for a Simple WIll if:{" "}
                        </p>
                        <ul>
                          <li>You want to nominate only one beneficiary for everything you have</li>
                          <li>You do not want to individually mention each asset you own</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="pb-3 head">
                          <div className="d-flex mb-4">
                            <Form.Check
                              value="advance_will"
                              type="radio"
                              onChange={() => advancePlan()}
                              checked={value2}
                            />{" "}
                            <b>Advance Plan</b>
                          </div>
                        </div>
                        <div className="pb-3 price">{/* <b>Rs. 100</b> */}</div>
                        <p>
                          You should opt for an advance will if:{" "}
                        </p>
                        <ul>
                          <li>You want to nominate multiple beneficiaries</li>
                          <li>You want to have multiple beneficiary for one or more assets you may have</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
              <div className="will-type-proceed">
                <button
                  type="button"
                  className="mt-auto button"
                  onClick={() => handleForm()}
                >
                  Proceed
                </button>
              </div>
            </section>
          </Form.Group>
        </Form>
      </FormikProvider>
    </Layout3>
  );
};
export default connect((state) => {
  return {formResult: { ...state?.Forms[stateKey] } };
})(WillType);
