import MainLayout from "../../layout/Mainlayout";
import Loader from "../common/Loader/Loader";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { initialValues, validationSchema } from "./util";
import CustomModal from "../common/CustomModal";
import { customiseFormSuccessMsg } from "../../../util";
import { Row, Form, Table } from "react-bootstrap";
import { FormikProvider, useFormik, Field } from "formik";
import dustbinIcon from "../../../assets/images/dustbin.png";
import pencilIcon from "../../../assets/images/pencil.png";
import copyIcon from "../../../assets/images/copyIcon.png";
import FieldWithTooltip from "../common/FieldWithTooltip";
import { useRef } from "react";

import {
  add_your_wish,
  getwishExamples,
  getUserWishes,
  remove_your_wish,
} from "../../../redux/AllThunk/Addyourwish";
import { assetSummary, view_draft } from "../../../route";
import { Link, useNavigate } from "react-router-dom";
import DeleteEntryConfirmation from "../common/DeleteEntryConfirmation";
import GobackArrow from "../common/GobackArrow";
import EditIconWithToolTip from "../common/EditIconWithToolTip";
import DeleteIconWithToolTip from "../common/DeleteIconWithToolTip";

const stateKey = "addyourwish";
const getKey = "wishExamples";
const userWisheskey = "userWishes";
const deleteWishState = "removeyourwish";

const Addyourwish = (props) => {
  const [loading, setLoading] = useState(false);
  const [initial_values, setInitial_values] = useState(initialValues);
  const [wishList, setWishList] = useState([]);
  const [toggleSection, setToggleSection] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch(
      getwishExamples({
        key: getKey,
      })
    );
    props.dispatch(
      getUserWishes({
        key: userWisheskey,
      })
    );
  }, []);

  const submitHandler = (values) => {
    

    if (formik.isValid === true) {
      wishList.push(values.description);
      setInitial_values(initialValues);

      formik.resetForm();
    }
    // } else {
    //   setCaptchaError(true);
    // }
    
    
  };
  const formik = useFormik({
    initialValues: initial_values,
    values: initial_values,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });
  const copyText = (key) => {
    setInitial_values({ description: props.examples.data[key].text });
  };
  const deleteWish = (key) => {
    var wish_list = wishList.filter((x, y) => {
      return y !== key;
    });
    setWishList(wish_list);

    let formData = new FormData();
    formData.append(`type`, "delete");

    if (wish_list.length > 0) {
      setLoading(true);

      wish_list.map((each, index) => {
        formData.append(`wishlist[${index}]`, each);
      });
    } else {
      formData.append(`wishlist`, []);
    }
    props.dispatch(
      add_your_wish({
        payload: formData,
        key: deleteWishState,
      })
    );
  };

  const showDeleteConfirmation = (key) => {
    props.dispatch({
      type: "SET_REMOVE_CONFIRMATION",
      payload: {
        showModal: true,
        rowKey: key,
        action: deleteWish,
      },
    });
  };

  const addWish = () => {
    if (wishList.length > 0) {
      setLoading(true);

      let formData = new FormData();
      wishList.map((each, index) => {
        formData.append(`wishlist[${index}]`, each);
      });
      formData.append(`type`, "add");

      props.dispatch(
        add_your_wish({
          payload: formData,
          key: stateKey,
        })
      );
    } else {
      formik.validateForm();
      formik.setTouched({ description: true });
    }
  };

  const editwish = (key) => {
    setInitial_values({ description: wishList[key] });
    deleteWish(key);
  };

  useEffect(() => {
    setLoading(false);
    if (props.formResult.success == true) {
      setTimeout(function () {
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
        navigate(view_draft);
      }, 1000);
    }
  }, [props.formResult.success]);

  useEffect(() => {
    setLoading(false);
    if (props.deleteWish.success == true) {
      props.dispatch({
        type: "RESET_FORM",
        key: deleteWishState,
      });
      props.dispatch({
        type: "RESET_REMOVE_CONFIRMATION",
      });
    }
  }, [props.deleteWish.success]);

  useEffect(() => {
    if (props.userWishes.success == true) {
      setWishList(props.userWishes.data);
    }
  }, [props.userWishes.data]);

  const refToDescription = useRef(null);
  const executeScroll = () => {
    refToDescription.current.scrollIntoView();
    setTimeout(() => {
      refToDescription.current.focus();
    }, 1000);
  };
  return (
    <MainLayout>
      {loading && <Loader />}
      <DeleteEntryConfirmation />

      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {props?.formResult?.success === true && (
        <CustomModal
          body={customiseFormSuccessMsg(props.formResult.message)}
          stateKey={stateKey}
          type="success"
        />
      )}
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow goto={assetSummary} />
          Add Wish
        </div>
      </div>
      <div className="main-layout-cont">
        <section className="addwish-section-one d-md-flex">
          <div className="col-md-6 col-12">
            <b className="font-18">What is This?</b>
            {toggleSection && (
              <>
                <p>
                  So far you have added your assets and their split amongst
                  beneficiaries.
                </p>
                <p>
                  But a Will can be much more than just asset distribution. It
                  could be a great way to leave a message to your dear ones or
                  leave directions for important decisions to be taken after
                  you.
                </p>
                <p>
                  This section allows you to add any such wishes in your Will.
                  These are wishes that may not relate to your assets.
                </p>
                <p>
                  To help you with the wording & structure, we have mentioned
                  some commonly used Wish examples on the right. You can simply
                  click on the copy button
                  <img src={copyIcon} className="copyIcon" /> and edit any of
                  the examples, or you could write your own from scratch.
                </p>
              </>
            )}
          </div>
          <div className="col-md-6 col-12 examplesDiv">
            <div className="d-flex">
              <div className="col-6">
                <b className="font-18">Examples:</b>
              </div>
              <div className="col-6 d-flex flex-row-reverse">
                <Link
                  to="#"
                  onClick={() => {
                    setToggleSection(!toggleSection);
                  }}
                  className="link font-18 fw-bold"
                >
                  {toggleSection ? "Hide" : "Show"}
                </Link>
              </div>
            </div>
            {toggleSection && (
              <ul className="font-16 custom-scroll">
                {props.examples.data.length > 0 &&
                  props.examples.data.map((e, i) => {
                    return (
                      <li key={`eg${i}`}>
                        <div>
                          <span>{e.text}</span>
                          <img
                            src={copyIcon}
                            className="copyIcon"
                            onClick={() => {
                              copyText(i);
                              executeScroll();
                            }}
                          />
                        </div>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        </section>
        <div className="mt-2">
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="col-md-12">
                <FieldWithTooltip
                  label="Description"
                  name="description"
                  text="Try to use words such that there is no ambiguity
                or confusion<br/> on what you wish for and how it should be executed.<br/>
                It helps to provide as many details as possible.<br/>
                This reduces disputes and makes it easier for your <br/>
                beneficiaries to fulfill your wishes."
                  placeholder="Type in the note to add to your wish"
                  as="textarea"
                  rows={5}
                  style={{ borderRadius: "0.5rem" }}
                  ref={refToDescription}
                />
              </Form.Group>
              <div className="d-flex flex-row-reverse mt-3">
                <button className="small-button" type="submit">
                  Add
                </button>
              </div>
              <h5 className="addedWishesSection">Added Wishes</h5>
              <div className="mb-3">
                <Table responsive className="wishTable">
                  <tbody>
                    {wishList.map((each, index) => {
                      return (
                        <tr key={`w${index}`}>
                          <td className="col-10">{each}</td>
                          <td className="col-2">
                            <EditIconWithToolTip
                              handleClick={() => {
                                editwish(index);
                              }}
                              link={false}
                            />
                            <DeleteIconWithToolTip
                              handleClick={() => {
                                showDeleteConfirmation(index);
                              }}
                            />
                            {/* <img
                              src={pencilIcon}
                              className="editIcon"
                              onClick={() => editwish(index)}
                            />
                            <img
                              src={dustbinIcon}
                              className="dltIcon"
                              onClick={() => showDeleteConfirmation(index)}
                            /> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex flex-row-reverse">
                <button className="button  " type="button" onClick={addWish}>
                  Save & Continue
                </button>
                <button
                  className="button me-5"
                  type="button"
                  onClick={() => {
                    navigate(view_draft);
                  }}
                >
                  Skip & Continue
                </button>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </MainLayout>
  );
};
export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
    examples: { ...state.GetData[getKey] },
    userWishes: { ...state.GetData[userWisheskey] },
    deleteWish: { ...state.Forms[deleteWishState] },
  };
})(Addyourwish);
