import React, { useEffect, useRef } from "react";
import Select from "react-select";

const MaritalStatusDropDown = (props) => {
  const selectInput = useRef();

  const options = [
    { value: "Single", label: "Single" },
    { value: "Married", label: "Married" },
    { value: "Widow", label: "Widow" },
    { value: "Divorced", label: "Divorced" },
  ];

  const formik = props.validation;

  useEffect(() => {
    if (
      formik.value == null ||
      formik.value == undefined ||
      formik.value == ""
    ) {
      selectInput.current.clearValue();
    }
  }, [formik.value]);

  return (
    <>
      <Select
        ref={selectInput}
        options={options}
        name={formik.name}
        onChange={(option) => {
          option !== null &&
            option !== undefined &&
            formik.form.setFieldValue(formik.name, option.value);
        }}
        isInvalid={!!formik.isInvalid}
        placeholder="Select Marital Status"
        onBlur={formik.onBlur}
        openMenuOnFocus={true}
        value={
          options
            ? options.find((option) => {
                return option.value === formik.value;
              })
            : null
        }
        defaultValue={{ value: "", label: "Select Marital Status" }}
        className={
          formik.error &&
          formik.form.touched[formik.name] == true &&
          `is-invalid`
        }
      />
      {formik.form.touched[formik.name] == true && (
        <div className="field-error">{formik.error}</div>
      )}
    </>
  );
};
export default MaritalStatusDropDown;
