import * as Yup from "yup";
import { notOnlyWhiteSpace, alphanumeric } from "../../../../constants";

export const listObj = {
  name: "",
  qty: "",
};
export const initialValues = {
  account_stocks: "list",
  stockList: [listObj],
  client_id: "",
  dp_id: "",
  //bo_id: "",
  
};
export const validationSchema = Yup.object().shape({
  stockList: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().test(
        "account_stocks",
        "*This is required",
        function () {
          return this.from[1].value.account_stocks == "list"
            ? this.originalValue !== undefined
              ? true
              : false
            : true;
        }
      ),
      qty: Yup.number()
        .typeError("A number is required")
        .test("account_stocks", "*This is required", function () {
          return this.from[1].value.account_stocks == "list"
            ? this.originalValue !== undefined
              ? true
              : false
            : true;
        }),
      // name: Yup.string().when(["account_stocks"], {
      //   //is: "all",
      //   is: function (val) {

      //     //return val && val === "00000149" ? true : false;
      //   },
      //   then: Yup.string()
      //     .required("*This is required")
      //     .min(2, "*This must have at least 2 characters")
      //     .max(100, "*This can't be longer than 100 characters")
      //     .matches(
      //       alphanumeric,
      //       "*Only Alphabets,Numbers and Spaces are allowed "
      //     ),
      // }),
      // qty: Yup.number().when("account_stocks", {
      //   is: "all",
      //   then: Yup.number()
      //     .required("*Quantity is required")
      //     .typeError("Only numbers allowed")
      //     .min(0, "Quantity must be greater than or equal to 0"),
      // }),
      // qty: Yup.number()
      //   .required("*Quantity is required")
      //   .typeError("Only numbers allowed")
      //   .min(0, "Quantity must be greater than or equal to 0"),
    })
  ),
  client_id: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  dp_id: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  account_stocks: Yup.string().required("*This is required"),


});

export const getFormData = (values, asset_type) => {
  let formData = new FormData();

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  formData.append("asset_type", asset_type);
  return formData;
};
