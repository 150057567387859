import MasterLayout from "../../layout/MasterLayout";

const Blog_detail = () => {
  return (
    <MasterLayout fluid={true} containerClass="p-lg-0 p-md-0" webFooter="true">
      <section className="blog-detail">
        <section className="py-md-5 py-3 px-md-5 px-3">
          <h2>
            <b>Will making basics</b>
          </h2>
          <p>
            Lorem ipsum dolor sit amet, onsectetur dipiscing elit, sed do
            eiusmod tempor
          </p>
          <small>Posted on:15-JAN-2022</small>
        </section>
        <section className="intro py-md-5 py-4 px-md-5 px-3">
          <div className="heading">Introduction</div>
          <p>
            Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Pharetra
            convallis posuere rbi leo urna molestie at Lorem ipsum dolor sit
            amet, onsectetur adipiscing elit, Lorem ipsum dolor sit amet,
            onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Pharetra convallis posuere rbi leo
            urna molestie at Lorem ipsum dolor sit amet, onsectetur adipiscing
            elit,
          </p>
        </section>
        <section className="content py-5 px-md-5 px-3 d-flex flex-md-row flex-column">
          <div className="col-md-2 col-12">
            <div className="mb-2">
              <b>Content</b>
            </div>
            <ul className="d-flex flex-wrap">
              <li className="mx-2">Defination</li>
              <li className="mx-2">Process</li>
              <li className="mx-2">Eligibility</li>
              <li className="mx-2">history</li>
              <li className="mx-2">Conclusion</li>
            </ul>
          </div>
          <div className="col-md-10 col-12">
            <section id="defination">
              <div className="heading">Defination</div>
              <p className="mt-3">
                Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Pharetra convallis posuere rbi leo urna molestie at Lorem ipsum
                dolor sit amet, onsectetur adipiscing elit,{" "}
              </p>
            </section>
          </div>
        </section>
      </section>
    </MasterLayout>
  );
};
export default Blog_detail;
