import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { api_base, HTTP_OK, HTTP_VALIDATION_ERROR } from "../../constants";

function addForm(action) {
  return axios({
    url: api_base + "/customer/register",
    method: "post",
    data: action.payload.data,
  });
}

function* Form_G(action) {
  try {
    var result = yield call(addForm, action);
    if (result.status === HTTP_OK) {
      sessionStorage.setItem("data", JSON.stringify(result?.data.data));
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.message,
        data: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_VALIDATION_ERROR) {
      yield put({
        type: "FORM_ERROR",
        payload: error?.response?.data,
        moreError: error?.response?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function verificationMailForm(action) {
  return axios({
    url: api_base + "/email/verification-notification",
    method: "post",
    data: action.payload.data,
  });
}

function* Form2(action) {
  try {
    var result = yield call(verificationMailForm, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_VALIDATION_ERROR) {
      yield put({
        type: "FORM_ERROR",
        payload: error?.response?.data,
        moreError: error?.response?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

export function* Register() {
  yield takeEvery("User_register", Form_G);
  yield takeEvery("Send_verification_mail", Form2);
}
