import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, FormikProvider } from "formik";
import { initialValues, validationSchema, asset_sub_type } from "./util";
import { Radio } from "@palmerhq/radio-group";
import { FRadioGroup } from "../../common/RadioButtons";
import FieldWithTooltip from "../../common/FieldWithTooltip";
import GobackArrow from "../../common/GobackArrow";
import { UserBankDetail } from "../../../../redux/AllThunk/UserBankDetail";
import BankList from "./BankList";
import BranchList from "./BranchList";
import CityList from "./CityList";
import CustomModal from "../../common/CustomModal";
import { useParams } from "react-router-dom";
import { decrypt } from "../../../../util";

const stateKey = "AddAssetForm";
const editAssetKey = "EditAssetDetail";
const bankdetailkey = "user_bank_detail";

const BankAccount = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
    props.dispatch(UserBankDetail({ key: bankdetailkey }));
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];
  const { asset_id } = useParams();

  const [entry_exist, setEntry_exist] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    if (Object.keys(selectedBank).length > 0) {
      props.pull_data({
        initialValues: {
          ...initialValues,
          ...formik.values,
          bank_name: selectedBank.name,
        },
        validationSchema,
      });
    }
  }, [selectedBank]);

  useEffect(() => {
    if (Object.keys(selectedBranch).length > 0) {
      props.pull_data({
        initialValues: {
          ...initialValues,
          ...formik.values,
          branch_name: selectedBranch.name,
        },
        validationSchema,
      });
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (Object.keys(selectedCity).length > 0) {
      props.pull_data({
        initialValues: {
          ...initialValues,
          ...formik.values,
          city: selectedCity.name,
        },
        validationSchema,
      });
    }
  }, [selectedCity]);
  const submitHandler = () => {
    let str = "";

    if (
      props.bankdetail.data["acc_number-bank_name"] != undefined &&
      props.bankdetail.data["acc_number-bank_name"].length > 0
    ) {
      str = formik.values.bank_name;
      str = str.toLowerCase();
      str = str.replaceAll(" ", "^");
      str = formik.values.acc_number + "[]" + str;
    }

    const entryExist =
      props.bankdetail.data["acc_number-bank_name"].includes(str);

    if (firstPath === "edit-asset") {
      let entryExistOne = false;
      const assetID = decrypt(asset_id);
      const list = props.bankdetail.data["acc_number-bank_name-id"];

      str = formik.values.bank_name;
      str = str.toLowerCase();
      str = str.replaceAll(" ", "^");

      list.map((each) => {
        const arr = each.split("[]");

        if (arr[0] == formik.values.acc_number && arr[1] == str) {
          if (arr[2] != assetID) {
            entryExistOne = true;
          }
        }
      });

      entryExistOne == false ? formik.handleSubmit() : setEntry_exist(true);
    } else {
      setEntry_exist(entryExist);
      entryExist !== true && formik.handleSubmit();
    }
  };

  return (
    <>
      {entry_exist && (
        <CustomModal
          body=""
          moreError={["Bank Account Already exist."]}
          type="error"
        />
      )}

      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow /> {firstPath === "edit-asset" ? "Update" : "Add"} Bank
          account
        </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          {/* <Form onSubmit={formik.handleSubmit}> */}
          <Form>
            <Row>
              <Form.Group className="col-md-12 mb-3">
                <Form.Label>Account Type</Form.Label>
                <FRadioGroup name="asset_sub_type" autoFocus={false}>
                  {asset_sub_type.map((each, index) => {
                    return (
                      <Radio key={`option${index}`} value={each}>
                        {each}
                      </Radio>
                    );
                  })}
                </FRadioGroup>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Bank Name</Form.Label>
                <Field name="bank_name">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
                <BankList
                  setSelected={(val) => {
                    setSelectedBank(val);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Branch Name</Form.Label>
                <Field name="branch_name">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
                {selectedBank !== undefined && selectedBank !== "" && (
                  <BranchList
                    bankKey={selectedBank?.key}
                    setSelected={(val) => {
                      setSelectedBranch(val);
                    }}
                  />
                )}
              </Form.Group>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>City</Form.Label>
                <Field name="city">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
                {selectedBank !== undefined && selectedBank !== "" && (
                  <CityList
                    bankKey={selectedBank?.key}
                    branchKey={selectedBranch?.key}
                    setSelected={(val) => {
                      setSelectedCity(val);
                    }}
                  />
                )}
              </Form.Group>
            </Row>
            {formik?.values?.asset_sub_type == "Fixed Deposit Account" && (
              <Row>
                <Form.Check className="d-flex col-lg-9 col-md-9 col-sm-12 my-3">
                  <Field
                    type="checkbox"
                    name="all_fds"
                    id="terms"
                    className="form-check-input mt-0"
                    checked={formik.values?.all_fds}
                  />
                  <label
                    htmlFor="terms"
                    className="register-checkbox col-lg-10 col-md-10 col-sm-8"
                  >
                    All FD accounts in this bank
                  </label>
                </Form.Check>
              </Row>
            )}
            {formik.values?.all_fds !== true && (
              <Row>
                <Form.Group className="col-md-4 mb-3">
                  <FieldWithTooltip
                    name="acc_number"
                    label="Account Number"
                    text="You can find this on your bank passbook/ Bank account statement"
                  />
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>Holding type</Form.Label>
                  <FRadioGroup name="ownership_status" autoFocus={false}>
                    <Radio value="Single Account">Single Account</Radio>
                    <Radio value="Joint Account">Joint Account</Radio>
                  </FRadioGroup>
                </Form.Group>
              </Row>
            )}
            <div className="d-flex align-items-end flex-row-reverse ">
              <button
                className="mt-auto form-button button"
                type="button"
                onClick={submitHandler}
              >
                {firstPath === "edit-asset" ? "Update" : "Save"}
              </button>
            </div>

            {/* <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} /> */}
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
    assetDetail: { ...state.GetData[editAssetKey] },
    bankdetail: { ...state.GetData[bankdetailkey] },
  };
})(BankAccount);
