import { web_base_url } from "../../../constants";

const Videosection = (props) => {
  return (
    <img src={web_base_url  + props.vidurl} className="landing-page-gif" />
    // <video
    //   controls={false}
    //   autoPlay={true}
    //   style={{ width: "100%" }}
    //   muted
    //   loop
    // >
    //   <source src={web_base_url + "/" + props.vidurl} type="video/mp4" />
    // </video>
  );
};

export default Videosection;
