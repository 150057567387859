import React, { useEffect, Fragment } from "react";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, FormikProvider, FieldArray } from "formik";
import { initialValues, validationSchema, getFormData, listObj } from "./util";
import SubmitBtn from "../../common/SubmitBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";

const Cash = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} Cash
        </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FieldArray
              name="cashList"
              render={(arrayHelpers) => {
                const { push, form } = arrayHelpers;
                const { values } = form;
                const row =
                  values !== undefined
                    ? values.cashList?.map((each, index) => {
                        return (
                          <Row className="m-0" key={`cashList${index}`}>
                            <Form.Group className="col-md-5 col-10 mb-3">
                              <Form.Label>Amount </Form.Label>
                              <Field name={`cashList.${index}.amount`}>
                                {({ field, meta }) => (
                                  <>
                                    <Form.Control
                                      {...field}
                                      autoComplete="off"
                                      isInvalid={!!meta.error && meta.touched}
                                      value={field.value ? field.value : ""}
                                    />
                                    {!!meta.error && meta.touched && (
                                      <div className="field-error">
                                        {meta.error}
                                      </div>
                                    )}
                                  </>
                                )}
                              </Field>
                            </Form.Group>
                            <Form.Group className="col-md-5 col-10 mb-3">
                              <Form.Label>Currency</Form.Label>
                              <Field name={`cashList.${index}.currency`}>
                                {({ field, meta }) => (
                                  <>
                                    <Form.Control
                                      {...field}
                                      autoComplete="off"
                                      isInvalid={!!meta.error && meta.touched}
                                      value={field.value ? field.value : ""}
                                    />
                                    {!!meta.error && meta.touched && (
                                      <div className="field-error">
                                        {meta.error}
                                      </div>
                                    )}
                                  </>
                                )}
                              </Field>
                            </Form.Group>
                            <div className="col-md-1 col-1">
                              {index > 0 && (
                                <>
                                  <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    className="removeFieldIcon"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </Row>
                        );
                      })
                    : "";

                const result =
                  values !== undefined ? (
                    <Fragment key="cashList">
                      {row}
                      <div className="form-btns mt-4">
                        <button
                          type="button"
                          className="button"
                          onClick={() => push(listObj)}
                        >
                          Add More
                        </button>
                      </div>
                    </Fragment>
                  ) : (
                    <></>
                  );
                return result;
              }}
            ></FieldArray>
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(Cash);
