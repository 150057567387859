import React from "react";
import Layout3 from "../layout/Layout3";

const ServerError = (props) => {
  return (
    <Layout3>
      <h1 className="home-heading">Server Error Page</h1>
    </Layout3>
  );
};

export default ServerError;
