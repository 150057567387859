import * as Yup from "yup";
import {
  notOnlyWhiteSpace,
  phoneRegExp,
  alphanumericWithSpecial,
  numeric,
} from "../../../constants";
import { changeToDMY } from "../../../util";

const sess_data = sessionStorage.getItem("data");
const sess_json = JSON.parse(sess_data);

export const initialValues = {
  name: sess_json !== null ? sess_json.name : "",
  email: sess_json !== null ? sess_json.email : "",
  phone: sess_json !== null ? sess_json.phone : "",
  same_as_permanent: false,
  proof_type: "",
  proof_detail: "",
  aadhar_card: "",
  
  p_add: "",
  p_country: "",
  p_state: "",
  p_city: "",
  p_pincode: "",
  r_add: "",
  r_country: "",
  r_state: "",
  r_city: "",
  r_pincode: "",
  gender: "",
  dob: "",
  marital_status: "",
  religion: "",
};
export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required("*Name is required")
    .min(2, "*Name must have at least 2 characters")
    .max(40, "*Name can't be longer than 40 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  proof_type: Yup.string().required("*This is required"),
  proof_detail: Yup.string()
    .required("*This is required")
    .matches(alphanumericWithSpecial, "*Invalid ID Proof detail"),
  // aadhar_card: Yup.string()
  //   .required("*This is required")
  //   .matches(numeric, "*Only Numbers are allowed ")
  //   .min(12, "*This can't be less than 12 characters")
  //   .max(12, "*This can't be longer than 12 characters"),
  phone: Yup.string()
    .required("*Phone number required")
    .matches(phoneRegExp, "*Phone number is not valid...")
    .max(17, "*Phone must be less than 17 digits")
    .min(6, "*Phone must be minimum 6 digits"),
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  p_add: Yup.string()
    .required("*Address is required")
    .min(2, "*Address Name must have at least 2 characters")
    .max(200, "*Address can't be longer than 200 characters"),
  p_country: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters"),
  p_state: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters"),
  p_city: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters"),
  p_pincode: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters"),
  r_add: Yup.string().when("same_as_permanent", {
    is: false,
    then: Yup.string()
      .required("*This is required")
      .min(2, "*Address Name must have at least 2 characters")
      .max(200, "*Address can't be longer than 200 characters"),
    otherwise: Yup.string().required("*This is required").nullable(),
  }),
  r_country: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters"),
  r_state: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters"),
  r_city: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters"),
  r_pincode: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters"),
  gender: Yup.string()
    .max(6, "*This can't be longer than 6 characters")
    .required("*This is required")
    .nullable(),
  dob: Yup.string().required("*This is required").nullable(),
  // religion: Yup.string().required("*This is required").nullable(),
  marital_status: Yup.string()
    .max(20, "*This can't be longer than 20 characters")
    .required("*This is required")
    .nullable(),


});

export const getFormData = (values) => {
  let formData = new FormData();
  formData.append("name", values.name);
  formData.append("email", values.email);
  formData.append("p_add", values.p_add);
  formData.append("r_add", values.r_add);
  formData.append("p_pincode", values.p_pincode);
  formData.append("r_pincode", values.r_pincode);
  formData.append("p_city", values.p_city);
  formData.append("r_city", values.r_city);
  formData.append("p_state", values.p_state);
  formData.append("r_state", values.r_state);
  formData.append("p_country", values.p_country);
  formData.append("r_country", values.r_country);
  formData.append("proof_type", values.proof_type);
  formData.append("proof_detail", values.proof_detail);
  // formData.append("proof_type", "aadhar_card");
  // formData.append("proof_detail", values.aadhar_card);
  formData.append("same_as_permanent", values.same_as_permanent);
  formData.append("gender", values.gender);
  formData.append("dob", changeToDMY(values.dob));
  formData.append("marital_status", values.marital_status);
  formData.append("religion", values.religion);

  return formData;
};

export const maritalStatusArr = [];
maritalStatusArr["0"] = "Single";
maritalStatusArr["1"] = "Married";
maritalStatusArr["2"] = "Divorced";
maritalStatusArr["3"] = "Widow";
