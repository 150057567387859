import scroll_horizontal from "../../../assets/images/scroll_horizontal.gif";

const Footer = (props) => {
  return (
    <div className="landing-footer container fixed-bottom d-lg-flex d-md-flex">
      <div className="offset-md-4 col-md-4 col-sm-12 text-center fw-bold landingFooter-right">
        {!props.indicatorState && (
          <div onClick={props.changeHandler} style={{cursor:"pointer"}}>
            <img
              src={scroll_horizontal}
              className="scroll_side_gif"
              
            />
            <br />
            <div className="mt-2">Side Scroll</div>
          </div>
        )}
      </div>
      <div className="col-lg-4 col-md-4 col-sm-12" style={{ position: "relative" }}>
        <div
          className={`fw-bold skipToWebLink `}
          onClick={() => {
            props.setShowWebsite(true);
          }}
        >
          Skip to website
        </div>
      </div>
    </div>
  );
};
export default Footer;
