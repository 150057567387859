import { connect } from "react-redux";
import MainLayout from "../../layout/Mainlayout";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import { customiseFormSuccessMsg } from "../../../util";
import { Fragment, useEffect, useState } from "react";
import { Field, useFormik, FormikProvider } from "formik";
import { Form, Row } from "react-bootstrap";
import {
  getFormData,
  validationSchema,
  initialValues,
  religionArr,
} from "./util";
import { Radio } from "@palmerhq/radio-group";
import { FRadioGroup } from "../common/RadioButtons";
import SubmitBtn from "../common/SubmitBtn";
import MuslimPopup from "./MuslimPopup";
import NRIPopup from "./NRIPopup";

const stateKey = "user_category";

const UserCategory = (props) => {
  const [loading, setLoading] = useState(true);
  const [initial_values, setInitial_values] = useState(initialValues);
  const [showContactForm, setshowContactForm] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showNRIForm, setShowNRIForm] = useState(false);

  let data = sessionStorage.getItem("data");
  const sessionData = JSON.parse(data);

  useEffect(() => {
    props.dispatch({
      type: "get_user_category",
      key: stateKey,
    });
  }, []);

  const submitHandler = (values) => {
    

    if (formik.isValid === true) {
      setLoading(true);
      const formData = getFormData(values);
      props.dispatch({
        type: "set_user_category",
        payload: formData,
        key: stateKey,
      });
    }
    // } else {
    //   setCaptchaError(true);
    // }
    
    
  };
  const formik = useFormik({
    initialValues: initial_values,
    values: initial_values,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (props.formResult.error === true || props.formResult.success === true) {
      setLoading(false);
    }
    if (props.formResult.success === true) {
      if (formik.values.q1 == "Muslim") {
        setshowContactForm(true);
      } else if (formik.values.q2 == "Other Nationality") {
        setShowNRIForm(true);
      }
      props.dispatch({
        type: "get_user_category",
        key: stateKey,
      });
    }
  }, [props.formResult.success, props.formResult.error]);

  useEffect(() => {
    let obj = {};
    if (props?.getData?.data?.length > 0) {
      const values = props?.getData.data.split("|");

      values.map((v, i) => {
        obj[`q${i + 1}`] = v;
      });
      if (values[0] == "Other") {
        obj["other_religion"] = values[2];
      } else {
        obj["other_religion"] = "";
      }
      setInitial_values(obj);
    }
    setLoading(false);
  }, [props.getData.data]);

  let tooltip_text = (
    <div className="tool-tip-box">
      <h5>Why do we need this?</h5>
      <p>
        Most wills in India are governed by Indian Succession Act, 1925.
        However, Wills made by people of certain religions are governed by their
        specific laws. For ex. Hindu Succession Act & Muslim Law specify
        different conditions for writing & enforcing a will. We will
        automatically customize your will draft based on what you choose here.
      </p>
    </div>
  );

  return (
    <>
      {showContactForm == true && (
        <MuslimPopup closeHandler={setshowContactForm} />
      )}
      {showNRIForm == true && <NRIPopup closeHandler={setShowNRIForm} />}
      {props?.formResult?.success === true &&
        showContactForm == false &&
        showNRIForm == false && (
          <CustomModal
            body={customiseFormSuccessMsg(props.formResult.message)}
            stateKey={stateKey}
            type="success"
          />
        )}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {loading && <Loader />}
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-column flex-md-row flex-sm-row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <h5>Set your Category</h5>
              <div className="main-layout-cont">
                <Form.Group className="mb-4">
                  <Form.Label className="mb-2">Religion</Form.Label>
                  <FRadioGroup name="q1" autoFocus={false}>
                    {religionArr.map((e, i) => {
                      return (
                        <Fragment key={e}>
                          <Radio
                            value={e}
                            className="col-lg-3 col-md-3 col-sm-4"
                          >
                            {e}
                          </Radio>
                          {i % 4 == 3 && <br />}
                        </Fragment>
                      );
                    })}
                  </FRadioGroup>
                </Form.Group>
                {formik.values.q1 == "Other" && (
                  <Form.Group className="mb-5 col-10">
                    <Field name="other_religion">
                      {({ field, meta }) => (
                        <>
                          <Form.Control
                            {...field}
                            type="text"
                            autoComplete="off"
                            isInvalid={!!meta.error && !!meta.touched}
                            value={field.value ? field.value : ""}
                            placeholder="mention your religion"
                          />
                          {meta.touched && meta.error && (
                            <Form.Control.Feedback type="invalid">
                              {meta.error}
                            </Form.Control.Feedback>
                          )}
                        </>
                      )}
                    </Field>
                  </Form.Group>
                )}
                <Form.Group>
                  <Form.Label className="mb-2">Citizenship</Form.Label>
                  <FRadioGroup name="q2" autoFocus={false}>
                    <Radio key="Indian" value="Indian">
                      Indian
                    </Radio>
                    <Radio key="Other Nationality" value="Other Nationality">
                      Other Nationality
                    </Radio>
                  </FRadioGroup>
                </Form.Group>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              {tooltip_text}
            </div>
          </div>
          {/* {sessionData.religion === "Muslim" &&
            sessionData.muslim_contact_form.length === 0 && (
              <div className="mt-3 mb-3 fw-bold">
                <b>Looks like you need a helping hand</b>
                <br />
                To contact{" "}
                <Link
                  to="#"
                  onClick={() => {
                    setShowContactForm(true);
                  }}
                >
                  click here{" "}
                </Link>
              </div>
            )} */}
          <div className="mt-3 mb-3 offset-md-3 col-md-6 col-12">
            {((sessionData.religion === "Muslim" &&
              sessionData?.muslim_contact_form?.length > 0) ||
              (sessionData.nationality !== "Indian" &&
                sessionData?.nri_contact_form?.length > 0)) && (
              <div className="messageBox">
                Your request is received and we will get in touch soon.
                <br /> You can also contact <b>9175188248</b> for more details
              </div>
            )}
          </div>
          <SubmitBtn text="Save" disabled={disableBtn} />
        </Form>
      </FormikProvider>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
    getData: { ...state.GetData[stateKey] },
  };
})(UserCategory);
