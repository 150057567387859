import ReactTooltip from "react-tooltip";
import styles from "../../../assets/Tooltip.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pencilIcon from "../../../assets/images/pencil.png";
import { Link } from "react-router-dom";

const EditIconWithToolTip = (props) => {
  return (
    <>
      {props.link == false ? (
        <>
          <img
            src={pencilIcon}
            className="editIcon"
            onClick={() => {
              props.handleClick();
            }}
            data-tip="Edit this entry"
          />
          <ReactTooltip
            effect="solid"
            multiline={true}
            place={props.place ? props.place : "top"}
          />
        </>
      ) : (
        <Link to={props.link} data-tip="Edit this entry">
          <img src={pencilIcon} className={`${styles.tooltip} editIcon`} />
          <ReactTooltip
            effect="solid"
            multiline={true}
            place={props.place ? props.place : "top"}
          />
        </Link>
      )}
    </>
  );

  // return (
  //   <>
  //     <Link to={props.link} data-tip={props.text}>
  //       <img
  //         //ref={iconRef}
  //         src={pencilIcon}
  //         className={`${styles.tooltip} editIcon`}
  //       />
  //       <ReactTooltip
  //         effect="solid"
  //         multiline={true}
  //         place={props.place ? props.place : "top"}
  //       />
  //     </Link>
  //   </>
  // );
};
export default EditIconWithToolTip;
