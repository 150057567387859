import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { calculateAge } from "../../../util";

const stateKey = "idProoflist";

const IDProofDropDown = (props) => {
  const selectInput = useRef();
  const [dropdownOptions, setDropDownOptions] = useState([]);

  useEffect(() => {
    props.dispatch({
      type: "get_id_prooflist",
      key: stateKey,
    });
  }, []);

  const formik = props.validation;

  useEffect(() => {
    if (
      formik.value == null ||
      formik.value == undefined ||
      formik.value == ""
    ) {
      selectInput.current.clearValue();
    }
  }, [formik.value]);

  useEffect(() => {
    let options =
      props?.list?.data?.length > 0
        ? props.list.data.map((each) => {
            return { value: each.url_type, label: each.type };
          })
        : [];

    setDropDownOptions(options);
  }, [props.list]);

  useEffect(() => {
    const age =
      formik.form.values?.dob?.length > 0
        ? calculateAge(formik.form.values?.dob)
        : 18;
    let options =
      dropdownOptions.length > 0
        ? dropdownOptions.filter((each) => {
            if (each.value == "birth_certificate" && age > 18) {
              return false;
            }
            return true;
          })
        : [];
    setDropDownOptions(options);
  }, [formik.form.values.dob]);

  return (
    <>
      <Select
        ref={selectInput}
        options={dropdownOptions}
        name={formik.name}
        onChange={(option) => {
          option !== null &&
            option !== undefined &&
            formik.form.setFieldValue(formik.name, option.value);
        }}
        isInvalid={!!formik.isInvalid}
        placeholder="Select Id Proof"
        onBlur={() => {
          formik.form.setFieldTouched(formik.name);
        }}
        value={
          dropdownOptions
            ? dropdownOptions.find((option) => {
                return option.value === formik.value;
              })
            : null
        }
        defaultValue={{ value: "", label: "Select ID Proof" }}
        className={
          formik.error &&
          formik.form.touched[formik.name] == true &&
          `is-invalid`
        }
        openMenuOnFocus={true}
      />
      {formik.form.touched[formik.name] == true && (
        <div className="invalid-feedback mt-3">{formik.error}</div>
      )}
    </>
  );
};
export default connect((state) => {
  return {
    list: { ...state.GetData[stateKey] },
  };
})(IDProofDropDown);
