import * as Yup from "yup";
import { notOnlyWhiteSpace } from "../../../../constants";

export const initialValues = {
  asset_sub_type: "",
  address_1: "",
  address_2: "",
  address_3: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
  ownership_percentage: "",
  
};
export const validationSchema = Yup.object().shape({
  asset_sub_type: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  address_1: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(200, "*This can't be longer than 200 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  address_2: Yup.string()
    .nullable()
    //.required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(200, "*This can't be longer than 200 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  address_3: Yup.string()
    .nullable()
    //.required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(200, "*This can't be longer than 200 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  city: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  state: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  country: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  pincode: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  ownership_percentage: Yup.number()
    .required("*This is required")
    .typeError("Only numbers allowed"),
  // .min(2, "*This must have at least 2 characters")
  // .max(100, "*This can't be longer than 100 characters")
  // .matches(
  //   notOnlyWhiteSpace,
  //   "*Only Alphabets,Numbers and Spaces are allowed "
  // ),

});

export const getFormData = (values, asset_type) => {
  let formData = new FormData();

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  formData.append("asset_type", asset_type);
  return formData;
};

export const asset_sub_type = ["House", "Land"];
