import supportIcon from "../../assets/images/call-icon.png";

const Footer = () => {
  return (
    <footer>
      <div className="row">
        <div className="col-md-4 col-sm-4 col-12 mb-2">
          <div className="footer-contact">
            <img src={supportIcon} />
            <span>Contact for support</span>
          </div>
        </div>
        <div className="col-md-4 col-sm-4 col-12">
          <p className="footer-note">
            If you quit the page it will save as draft
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
