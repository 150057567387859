import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";

const BlogSlider = () => {
  return (
    <>
      <Carousel>
        <Carousel.Item>
          <div className="blog">
            <div className="heading">
              What are the eligibility for a person to be as a witness?
            </div>
            <p>
              Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Pharetra convallis posuere rbi leo urna molestie at Lorem ipsum
              dolor sit amet, onsectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Pharetra convallis
              posuere rbi leo urna molestie at{" "}
            </p>
            <Link to="#">Read More</Link>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="blog">
            <div className="heading">
              What are the eligibility for a person to be as a witness?
            </div>
            <p>
              Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Pharetra convallis posuere rbi leo urna molestie at Lorem ipsum
              dolor sit amet, onsectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Pharetra convallis
              posuere rbi leo urna molestie at{" "}
            </p>
            <Link to="#">Read More</Link>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="blog">
            <div className="heading">
              What are the eligibility for a person to be as a witness?
            </div>
            <p>
              Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Pharetra convallis posuere rbi leo urna molestie at Lorem ipsum
              dolor sit amet, onsectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Pharetra convallis
              posuere rbi leo urna molestie at{" "}
            </p>
            <Link to="#">Read More</Link>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default BlogSlider;
