import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../../common/Loader/Loader";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { splitAndCapitalize } from "./util";

const getkey = "user_bank_detail";

const CityList = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [props.list.data]);

  const listKey =
    // props.branchKey !== ""
    //   ? props.branchKey
    //   :
    props.bankKey.length !== 0 ? props.bankKey : 0;

  const list = props.list.data.cities[listKey];
  var nameList =
    list !== undefined && list !== null
      ? Object.keys(list).length > 0
        ? list.map((each, key) => {
            return (
              <span key={key}>
                <cite
                  onClick={() => {
                    props.setSelected({ name: splitAndCapitalize(each), key });
                  }}
                  style={{ fontStyle: "normal" }}
                >
                  {splitAndCapitalize(each)}
                </cite>
                <FontAwesomeIcon
                  icon={faXmark}
                  className="cancel-icon"
                  onClick={() => {
                    props.setSelected("");
                  }}
                />
              </span>
            );
          })
        : []
      : [];

  return (
    <>
      {loading && <Loader />}
      <div className="name-list">{nameList}</div>
    </>
  );
};
export default connect((state) => {
  return { list: { ...state?.GetData?.[getkey] } };
})(CityList);
