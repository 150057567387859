import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";

import { Field, useFormik, FormikProvider } from "formik";

import { initialValues, validationSchema, getFormData } from "./util";
import Loader from "../../common/Loader/Loader";
import CustomModal from "../../common/CustomModal";
import { customiseFormSuccessMsg, encrypt } from "../../../../util";
import SubmitBtn from "../../common/SubmitBtn";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";

const Gratuity = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} Gratuity
        </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="col-md-6 col-12 mb-3">
                <Form.Label>Account Number</Form.Label>
                <Field name="acc_number">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group className="col-md-6 col-12 mb-3">
                <Form.Label>Employer Name</Form.Label>
                <Field name="employer_name">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(Gratuity);
