import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const LogoutBtn = (props) => {
  const { dispatch } = props;
  const navigate = useNavigate();

  const logout = () => {
    dispatch({
      type: "deleteDraft",
      key: "deleteDraft",
    });
    dispatch({
      type: "User_logout",
      key: "Logout",
    });

  };

  useEffect(() => {
    if (props.formResult.success === true) {
      sessionStorage.clear();
      navigate("/");
    }
  }, [props.formResult.success]);

  return (
    <button className="logout-button button" onClick={logout}>
      Logout
    </button>
  );
};

export default connect(function (state) {
  return {
    formResult: { ...state?.Forms?.Logout },
  };
})(LogoutBtn);
