import Leftmenu from "./Leftmenu";
import Footer from "./Footer";
import MasterLayout from "./MasterLayout";

const MainLayout = (props) => {
  return (
    <>
      <MasterLayout showWillBtn={false} fluid={true} containerClass="p-0">
        <section className="body-cont">
          <div className="row rowDiv" style={{ height: "100vh" }}>
            <Leftmenu />
            <div className="body_cont_main-layout-2 col-md-10 col-lg-10 col-sm-12 col-12">
              <div className="main-layout py-lg-4 py-md-3 py-sm-2 px-lg-2 px-md-2 px-sm-2 px-3">
                {props.children}
              </div>
              <Footer />
            </div>
          </div>
        </section>
      </MasterLayout>
    </>
  );
};

export default MainLayout;
