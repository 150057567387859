import React, { useState, useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, useFormik, FormikProvider } from "formik";
import { login } from "../../../route";
import { initialValues, validationSchema } from "./util";
import CustomModal from "../common/CustomModal";
import Loader from "../common/Loader/Loader";
import signup from "../../../assets/images/signup.png";
import { customiseFormSuccessMsg } from "../../../util";
import Layout3 from "../../layout/Layout3";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PassRequirements } from "../common/PassRequirements";
import Captcha from "../common/Captcha";

const stateKey = "UserRegister";

const Register = (props) => {
  const [captchaError, setCaptchaError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passShow, setPassShow] = useState(false);
  const [c_passShow, setC_PassShow] = useState(false);
  const captchaRef = useRef(null)

  const submitHandler = (values) => {
    if (captchaRef.current.getValue().length >0) {

      if (formik.isValid === true) {
        setLoading(true);
        
        props.dispatch({
          type: "User_register",
          payload: {
            data: {
              name: values.name,
              email: values.email,
              phone: values.phone,
              country_code: values.country_code,
              password: values.password,
              password_confirmation: values.cpass,
              captcha_token:captchaRef.current.getValue(),
            },
          },
          key: stateKey,
        });
      }
    } else {
      setCaptchaError(true);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    setLoading(false);

    if (props.formResult.success == true) {
      setTimeout(function () {
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
        window.location.href = "/verify_user/email";
      }, 2000);
    }else{
      captchaRef.current.reset();
    }
  }, [props.formResult]);

  

  const passHandler = () => {
    setPassShow(!passShow);
  };

  const c_passHandler = () => {
    setC_PassShow(!c_passShow);
  };

  return (
    <Layout3>
      {loading && <Loader />}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {props?.formResult?.success === true && (
        <CustomModal
          body={customiseFormSuccessMsg(props.formResult.message)}
          stateKey={stateKey}
          type="success"
        />
      )}
      <div className="main-layout d-flex registerPage">
        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
          <img src={signup} className=" mt-md-0 mt-sm-0 mb-sm-5 register-img" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 ps-md-4">
          <h1 className="login-title">Create Account</h1>
          <div className="mt-3 mb-3">
            <span className="reg-now me-1">Already a member ? </span>
            <Link to={login} className="reg-now-link yellowColor">
              Log In
            </Link>
          </div>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} className="login-form">
              <Form.Group className="col-lg-9 col-md-9 col-sm-12">
                <Field name="name">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Full Name"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>

              <Form.Group className="col-lg-9 col-md-9 col-sm-12">
                <Field name="email">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="email"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Email ID"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>

              <Form.Group className=" col-lg-9 col-md-9 col-sm-12 d-flex align-items-baseline">
                <Form.Group className="col-lg-1 col-md-1 col-sm-1">
                  +
                </Form.Group>
                <Form.Group className="col-lg-3 col-md-3 col-sm-3">
                  <Field name="country_code">
                    {({ field, meta }) => (
                      <>
                        <Form.Control
                          {...field}
                          type="text"
                          autoComplete="off"
                          isInvalid={!!meta.error && !!meta.touched}
                          value={field.value ? field.value : ""}
                          placeholder="Country Code"
                        />
                        {meta.touched && meta.error && (
                          <Form.Control.Feedback type="invalid">
                            {meta.error}
                          </Form.Control.Feedback>
                        )}
                      </>
                    )}
                  </Field>
                </Form.Group>
                <Form.Group className="ms-2 col-lg-8 col-md-8 col-sm-8">
                  <Field name="phone">
                    {({ field, meta }) => (
                      <>
                        <Form.Control
                          {...field}
                          type="text"
                          autoComplete="off"
                          isInvalid={!!meta.error && !!meta.touched}
                          value={field.value ? field.value : ""}
                          placeholder="Mobile Number"
                        />
                        {meta.touched && meta.error && (
                          <Form.Control.Feedback type="invalid">
                            {meta.error}
                          </Form.Control.Feedback>
                        )}
                      </>
                    )}
                  </Field>
                </Form.Group>
              </Form.Group>
              {/* <Form.Group className="col-md-9">
                <Field name="phone">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Mobile Number"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group> */}

              <Form.Group className="col-lg-9 col-md-9 col-sm-12 position-relative">
                <Field name="password">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type={passShow == true ? `text` : `password`}
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Password"
                        className="password"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
                <FontAwesomeIcon
                  className={
                    passShow ? `d-block toggleEye` : `d-none toggleEye`
                  }
                  onClick={() => {
                    passHandler();
                  }}
                  icon={faEye}
                />
                <FontAwesomeIcon
                  className={
                    passShow ? `d-none toggleEye` : `d-block toggleEye`
                  }
                  onClick={() => {
                    passHandler();
                  }}
                  icon={faEyeSlash}
                />
              </Form.Group>

              <Form.Group className="col-lg-9 col-md-9 col-sm-12 position-relative">
                <Field name="cpass">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type={c_passShow == true ? `text` : `password`}
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Confirm Password"
                        className="password"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
                <FontAwesomeIcon
                  className={
                    c_passShow ? `d-block toggleEye` : `d-none toggleEye`
                  }
                  onClick={() => {
                    c_passHandler();
                  }}
                  icon={faEye}
                />
                <FontAwesomeIcon
                  className={
                    c_passShow ? `d-none toggleEye` : `d-block toggleEye`
                  }
                  onClick={() => {
                    c_passHandler();
                  }}
                  icon={faEyeSlash}
                />
              </Form.Group>
              <div className="my-2">
              <Captcha 
              reference={captchaRef}
              captchaError = {captchaError}
              stateKey={stateKey}
              />
              </div>

              <Form.Check className="d-flex col-lg-9 col-md-9 col-sm-12 mt-3">
                <Field
                  type="checkbox"
                  name="terms"
                  id="terms"
                  className="form-check-input mt-0"
                />
                <label
                  htmlFor="terms"
                  className="register-checkbox col-lg-10 col-md-10 col-sm-8"
                >
                  By clicking this box you are agreeing to the terms and
                  conditions of willyourwish
                </label>
              </Form.Check>
              {formik.errors.terms && formik.touched.terms == true && (
                <div className="field-error mt-2">{formik.errors.terms}</div>
              )}
              <div className="col-md-12 col-sm-12 mt-4">
                <button className="button mt-auto" type="submit">
                  Register
                </button>
              </div>
            </Form>
          </FormikProvider>
          <PassRequirements formik={formik} />
        </div>
      </div>
    </Layout3>
  );
};

export default connect((state) => {
  return { formResult: { ...state?.Forms?.[stateKey] } };
})(Register);
