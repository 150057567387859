export const login = `/login`;
export const register = "/register";
export const forgotpassword = "/forgot-password";
export const servererror = "/server-error";
export const personalDetail = "/personal-detail";
export const selectAsset = "/selectAsset";
export const addAsset = "/add-asset/:asset_type";
export const editAsset = "/edit-asset/:asset_type/:asset_id";
export const addNominee = "/add-nominee";
export const addNomineeToAsset = `/add-nominee/:asset_id/:master_asset_id`;
export const editAssetNominee =
  "/edit-asset-nominee/:asset_id/:master_asset_id";
export const assetSummary = "/asset-summary";
export const viewDraft = "/view-draft";
export const paymentPlans = "/payment-plans";
export const paymentSuccess = "/payment-success";
export const uploadWill = "/upload-will";
export const willUploadSuccess = "/upload-success";
export const accountSetting = "/account-setting";
export const userCategory = "/user-category";
export const addExecutor = "/add-executor";
export const executorList = "/executor_list";
export const addExecutorToWill = "/add_executor_to_will";
export const myNominees = "/my-nominees";
export const resetPassword = "/reset-password";
export const blogs = "/blogs";
export const support = "/support";
export const faq = "/faq";
export const addyourwish = "/addyourwish";
export const email_verify_url = "/email_verify_url/";
export const verify_user = "/verify_user/:type";
export const view_draft = "/view_draft";
export const blog_detail = "/blog-detail/:blog_url";

//=========================design pages============//
export const links = "/links";
export const design = "/design";
export const aadharpage = "/aadharpage";

export const logout = "logout";
export const willtype = "/will-type";
export const userWillType = "/update-will-type-in-user-will";