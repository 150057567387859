import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Card, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useFormik, FormikProvider, Field } from "formik";
import { initialValues, validationSchema, getFormData } from "./util";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import { customiseFormSuccessMsg } from "../../../util";
import { paymentSuccess, view_draft } from "../../../route";
import MainLayout from "../../layout/Mainlayout";
import Coupon from "../Coupon";
import ConfirmationPopup from "./ConfirmationPopup";
import GobackArrow from "../common/GobackArrow";

const stateKey = "make_payment";
const getKey = "PaymentPlan";
const couponKey = "coupon";

const PaymentPlan = (props) => {
  const navigate = useNavigate();

  // const [captchaError, setCaptchaError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initial_values, setInitial_values] = useState(initialValues);
  const [payBtnContent, setPayBtnContent] = useState("Select Plan");
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [enablePayment, setEnablePayment] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  // const [showErrorModal, setShowErrorModal] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  const submitHandler = (values) => {
    

    if (formik.isValid === true) {
      setLoading(true);
      const formData = getFormData(values);
    }
    // } else {
    //   setCaptchaError(true);
    // }
    
    
  };
  const formik = useFormik({
    initialValues: initial_values,
    values: initial_values,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  

  //const makePay = props?.detail?.data;

  const makePay = {
    post_url: "paymentgatwayurl",
    hidden_post_field_msg: "parameters to send to payment gateway",
  };

  const makePayment = () => {
    if (selectedPlan !== 0) {
      setLoading(true);
      props.dispatch({
        type: "payment_success",
        payload: { payment_amount: 1000, payment_plan_id: 4 },
        key: stateKey,
      });
    } else {
      // setShowErrorModal(true);
      // setErrorMessage("Please select plan first");
    }
  };

  useEffect(() => {
    setLoading(false);

    setTimeout(() => {
      if (props.formResult.success) {
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
        navigate(paymentSuccess);
      }
    }, 1000);
  }, [props.formResult.success, props.formResult.error]);

  const selectPlan = (id) => {
    if (id == selectedPlan) {
      setSelectedPlan(0);
      setPayBtnContent("Select Plan");
    } else {
      setSelectedPlan(id);
      if (id == 1) {
        setPayBtnContent("Finalize & Pay");
      } else if (id == 2) {
        setPayBtnContent("Finalize & Pay");
      } else if (id == 3) {
        setPayBtnContent("Finalize & Pay");
      }
    }
  };

  const planOne = [];
  const planTwo = [];
  const planThree = [];
  planOne["heading"] = "Easy Will";
  planOne["price"] = "Rs. 1000";
  planOne["id"] = 1;
  planOne["content"] = (
    <ul className="ps-3">
      <li>Draft with all your assets and beneficiaries</li>
      <li>Upload signed will copy for future use</li>
      <li>Upload 5 amendments to the draft over</li>
    </ul>
  );

  planTwo["heading"] = "Notarize";
  planTwo["price"] = "Rs. 3000";
  planTwo["id"] = 2;
  planTwo["content"] = (
    <ul className="ps-3">
      <li>Draft with all your assets and beneficiaries</li>
      <li>Upload signed will copy for future use</li>
      <li>Upload 5 amendments to the draft over</li>
    </ul>
  );

  planThree["heading"] = "Register";
  planThree["price"] = "Rs. 5000";
  planThree["id"] = 3;
  planThree["content"] = (
    <ul className="ps-3">
      <li>Draft with all your assets and beneficiaries</li>
      <li>Upload signed will copy for future use</li>
      <li>Upload 5 amendments to the draft over</li>
    </ul>
  );
  const planArr = [];
  planArr["0"] = planOne;
  planArr["1"] = planTwo;
  planArr["2"] = planThree;

  useEffect(() => {
    formik.values.confirmation == false && setConfirmation(false);
  }, [formik.values.confirmation]);

  const handleCancelConfirmation = () => {
    setInitial_values({ ...formik.values, confirmation: false });
    formik.values.confirmation = false;
  };

  return (
    <MainLayout>
      {formik.values.confirmation && (
        <ConfirmationPopup
          handleCancelConfirmation={handleCancelConfirmation}
          confirmation={setConfirmation}
        />
      )}
      {props?.formResult?.success === true && (
        <CustomModal
          body={customiseFormSuccessMsg(props.formResult.message)}
          stateKey={stateKey}
          type="success"
        />
      )}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {loading && <Loader />}
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow goto={view_draft} />
          Payment Plans
        </div>
      </div>
      <div className="main-layout-cont">
        <div className="mb-4 payPlanContainer">
          {planArr.length > 0 &&
            planArr.map((i, e) => {
              return (
                <Card
                  className={`text-left payplan-box mb-sm-0 mb-4 ${
                    selectedPlan == i.id ? "selected" : ""
                  }`}
                  onClick={() => {
                    selectPlan(i.id);
                  }}
                  key={`plan${e}`}
                >
                  <Card.Body>
                    <div className="plan-head">{i.heading}</div>
                    <p>{i.price}</p>
                    {i.content}
                  </Card.Body>
                </Card>
              );
            })}
        </div>

        <Coupon />
        <FormikProvider value={formik}>
          <Form action={makePay.post_url}>
            {/* <Form onSubmit={formik.handleSubmit}> 
            <input
                    type="hidden"
                    name="msg"
                    value={makePay.hidden_post_field_msg}
                  /> */}
            <Form.Group className="d-flex mt-sm-5 mt-3 mb-sm-1 mb-3">
              <Field name="confirmation">
                {({ field, meta }) => (
                  <>
                    <input
                      {...field}
                      type="checkbox"
                      autoComplete="off"
                      value={field.value ? field.value : ""}
                      id="confirmation"
                      className="form-check-input mt-0"
                      checked={field.value}
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
              <label htmlFor="confirmation" className="register-checkbox">
                i have reviewed the draft thoroughly and confirm that it is upto
                my satisfaction. I confirm that no changes are required in the
                draft.
              </label>
            </Form.Group>
            {/* 
            <div className="mt-3">
              <div>
                <b>Terms & Condition:</b>
              </div>
              <div style={{ fontSize: "14px" }}>
                Sign the will along with witness and upload it on your
                willyourwish account
              </div>
            </div> */}
            <div className="d-flex flex-row-reverse">
              <button
                onClick={makePayment}
                className="button"
                disabled={
                  selectedPlan !== 0 &&
                  formik.values.confirmation &&
                  confirmation
                    ? false
                    : true
                }
                type="button"
              >
                {payBtnContent}
              </button>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </MainLayout>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
    getData: { ...state.GetData[getKey] },
  };
})(PaymentPlan);
