import React, { useState } from "react";
import { Modal, Form, Row } from "react-bootstrap";
import { useFormik, FormikProvider, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const RemoveExecutorPopup = (props) => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    props.return("");
    setShow(false);
  };

  const formik = useFormik({
    initialValues: { remove_executor: "no" },
    values: { remove_executor: "no" },
    enableReinitialize: true,
  });

  const handleClick = () => {
    props.return(formik.values.remove_executor);
    setShow(false);
  };
  return (
    <>
      <Modal
        show={show}
        centered
        className="executorPopup"
        onHide={() => {
          if (props?.restrictClose) {
            return false;
          } else {
            handleClose();
          }
        }}
        backdrop="static"
      >
        <FontAwesomeIcon
          icon={faCircleXmark}
          onClick={handleClose}
          className="modalCloseIcon"
        />
        <Modal.Body>
          <FormikProvider value={formik}>
            <div className="col-md-12 mb-2">
              <b>Executor Exist</b>
            </div>
            <Row>
              <div className="col-md-8 col-12 font-16 pt-2 mb-sm-0 mb-3">
                Do you want to remove executor ?
              </div>
              <div className="col-md-4 col-8">
                <Field name="remove_executor">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        as="select"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                      </Form.Control>
                      {meta.touched && meta.error && (
                        <div className="field-error">{meta.error}</div>
                      )}
                    </>
                  )}
                </Field>
              </div>
            </Row>
            <p>
              An executor is someone who is responsible for executing, or
              following through on, an assigned task or duty. The feminine
              form,executrix, may sometimes be used.{" "}
            </p>
            <button
              type="button"
              className="mt-auto form-button button"
              onClick={handleClick}
            >
              Next
            </button>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default RemoveExecutorPopup;
