import { all } from "redux-saga/effects";
import { Login } from "./AllSagas/Login";
import { Register } from "./AllSagas/Register";
import { PersonalDetail } from "./AllSagas/PersonalDetail";
import { Logout } from "./AllSagas/Logout";
import { AssetSaga } from "./AllSagas/Asset";
import { Nominee } from "./AllSagas/Nominee";
import { IdProofSaga } from "./AllSagas/IDProof";
import { CountriesSaga } from "./AllSagas/Countries";
import { StatesSaga } from "./AllSagas/States";
import { WillSaga } from "./AllSagas/Will";
import { UpdatePassword } from "./AllSagas/UpdatePassword";
import { UserCategory } from "./AllSagas/UserCategory";
import { Executor } from "./AllSagas/Executor";
import { PaymentSaga } from "./AllSagas/Payments";
import { User_ID_Proof } from "./AllSagas/User_ID_Proof";
import { ForgotPass } from "./AllSagas/ForgotPass";
import { LandingPageVideos } from "./AllSagas/LandingPageVideos";
import { ContactForm } from "./AllSagas/ContactForm";

export default function* RootSaga() {
  yield all([
    Login(),
    Register(),
    PersonalDetail(),
    Logout(),
    AssetSaga(),
    Nominee(),
    IdProofSaga(),
    CountriesSaga(),
    StatesSaga(),
    WillSaga(),
    UpdatePassword(),
    UserCategory(),
    Executor(),
    PaymentSaga(),
    User_ID_Proof(),
    ForgotPass(),
    LandingPageVideos(),
    ContactForm(),
  ]);
}
