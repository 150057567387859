import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const GobackArrow = (props) => {
  const navigate = useNavigate();
  const firstPath = window.location.pathname.split("/")[1];
  return (
    <FontAwesomeIcon
      icon={faAngleLeft}
      className="left-arrow-icon"
      onClick={() => {
        if (props.goto !== undefined) {
          if ("/" + firstPath == props.goto) {
            window.location.reload();
          } else {
            navigate(props.goto);
          }
        } else {
          navigate(-1);
        }
      }}
    />
  );
};
export default GobackArrow;
