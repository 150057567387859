import React, { useEffect, useRef } from "react";
import Select from "react-select";
import { relationDropdown } from "../../../util";
import { Field } from "formik";
import { Form } from "react-bootstrap";

const RelationDropdown = (props) => {
  const selectInput = useRef();

  var options =
    relationDropdown.length > 0
      ? relationDropdown.map((each) => {
          return { value: each, label: each };
        })
      : [];
  const formik = props.formik;

  useEffect(() => {
    if (
      formik.values[props.name] == null ||
      formik.values[props.name] == undefined ||
      formik.values[props.name] == ""
    ) {
      selectInput.current.clearValue();
    }
  }, [formik.values[props.name]]);
  return (
    <>
      <Field name={props.name}>
        {({ field, meta }) => {
          return (
            <>
              <Select
                name={field.name}
                ref={selectInput}
                options={options}
                onChange={(option) => {
                  option !== null &&
                    option !== undefined &&
                    formik.setFieldValue(props.name, option.value);
                }}
                isInvalid={!!formik.touched[field.name]}
                onBlur={() => {
                  formik.setFieldTouched(field.name);
                }}
                value={
                  options
                    ? options.find(
                        (option) => option.value === formik.values[props.name]
                      )
                    : ""
                }
                defaultValue={{ value: "", label: "Select Relation" }}
                className={formik.error && `is-invalid`}
                openMenuOnFocus={true}
              />
              {meta.touched == true && meta.error && (
                <div className="field-error mt-3">{meta.error}</div>
              )}
            </>
          );
        }}
      </Field>

      {formik.values[props.name] == "Other" && (
        <Field name="other_relation">
          {({ field, meta }) => (
            <>
              <Form.Control
                {...field}
                type="text"
                autoComplete="off"
                isInvalid={!!meta.error && !!meta.touched}
                //value={field.value ? field.value : formik.values[field.name]}
                placeholder="type other relationship here..."
                className="mt-3"
              />
              {meta.touched && meta.error && (
                <Form.Control.Feedback type="invalid">
                  {meta.error}
                </Form.Control.Feedback>
              )}
            </>
          )}
        </Field>
      )}
    </>
  );
};
export default RelationDropdown;
