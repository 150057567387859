import * as Yup from "yup";
import { notOnlyWhiteSpace, alphanumericWithSpecial } from "../../../constants";

export const initialValues = {
  nominee_id: "",
  name: "",
  relation: "",
  other_relation: "",
  percent: "",
  proof_type: "",
  proof_detail: "",
  
};

export const validationSchema = Yup.object().shape({
  // name: Yup.string()
  //   .required("*Name is required")
  //   .min(2, "*Name must have at least 2 characters")
  //   .max(100, "*Name can't be longer than 100 characters")
  //   .matches(
  //     notOnlyWhiteSpace,
  //     "*Only Alphabets,Numbers and Spaces are allowed "
  //   ),
  name: Yup.string().required("*Name is required"),
  percent: Yup.number()
    .typeError("Only numbers allowed")
    .required("*This is required")
    .max(100, "*Can't be greater than 100%")
    .test(
      "greaterThanZero",
      "number field must be greater than zero",
      (number) =>
        /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/.test(number)
    ),
  relation: Yup.string().required("*This is required"),
  other_relation: Yup.string().when("relation", {
    is: "Other",
    then: Yup.string()
      .required("*This is required")
      .min(2, "*This must have at least 2 characters")
      .max(100, "*This can't be longer than 100 characters"),
    otherwise: Yup.string().nullable(),
  }),
  proof_type: Yup.string().required("*This is required"),
  proof_detail: Yup.string()
    .required("*This is required")
    .matches(alphanumericWithSpecial, "*Invalid ID Proof detail"),

});

export const getFormData = (values, asset_id) => {
  let formData = new FormData();

  formData.append(`asset_id`, asset_id);

  values.map((each, index) => {
    formData.append(`nominee_id[${index}]`, each.nominee_id);
    formData.append(`name[${index}]`, each.name);
    formData.append(`percent[${index}]`, each.percent);
    formData.append(`proof_type[${index}]`, each.proof_type);
    formData.append(`proof_detail[${index}]`, each.proof_detail);
    formData.append(`relation[${index}]`, each.relation);

    if (each.relation == "Other") {
      formData.append(`other_relation[${index}]`, each.other_relation);
    }
    // else {
    //   formData.append(`relation[${index}]`, each.relation);
    // }
  });
  return formData;
};

export const mapNomineeList = (data) => {
  const nomineeArr =
    data.length > 0 &&
    data.map((each) => {
      return {
        name: each.nominee_detail.name,
        relation:
          each.nominee_detail.relation !== "Other"
            ? each.nominee_detail.relation
            : each.nominee_detail.other_relation,
        percent: each.percent,
        proof_type: each.nominee_detail.proof_type,
        proof_detail: each.nominee_detail.proof_detail,
      };
    });
  return nomineeArr;
};

export const detail = (data) => {
  let str = "";
  switch (data.master_asset?.url_type) {
    case "bank_account": {
      str += data.all_fds == true || data.all_fds == "true" ? "All " : "";
      str += data.asset_sub_type;
      //str += data.acc_number !== null ? " " + data.acc_number : "";
      str +=
        data.all_fds == false || data.all_fds == "false"
          ? " " + data.acc_number
          : "";
      return str;
    }
    case "crypto_currency": {
      str += data.exchange_wallet;
      str += data.type;
      str += data.units;
      str += data.user_id;
      return str;
    }
    case "ppf": {
      str += data.acc_via;
      str += " " + data.acc_number;
      return str;
    }
    case "pf": {
      str += data.acc_number;
      str += " " + data.uan_no;
      return str;
    }
    case "property": {
      str += data.asset_sub_type;
      str += data.city;
      return str;
    }
    case "mutual_funds": {
      let str = [];
      data.mfList.map((each, i) => {
        str[i] = i <= 2 ? each.folio_no + " - " + each.amc : false;
      });
      return str;
    }
    case "stocks_and_etfs": {
      str += data.client_id;
      str += data.dp_id;
      return str;
    }
    case "insurance": {
      str += data.asset_sub_type + " ";
      str += data.policy_number + " ";
      str += data.insurer_company + " ";
      return str;
    }
    case "bonds": {
      let str = [];
      data.bondsList.map((each, i) => {
        str[i] =
          i <= 2
            ? each.bond_id + " - " + each.bond_name + " - " + each.bond_units
            : false;
      });
      return str;
    }
    case "cash": {
      let str = [];
      data.cashList.map((each, i) => {
        str[i] = i <= 2 ? each.amount + " - " + each.currency + ", " : false;
      });
      return str;
    }
    case "sovereign_gold_bond": {
      str += data.sgb_id;
      str += data.sgb_demat;
      return str;
    }
    case "jewellery": {
      let str = [];
      data.jewelleryList.map((each, i) => {
        str[i] = i <= 2 ? each.description.substring(0, 100) : false;
      });
      return str;
    }
    case "others": {
      str += data.description.substring(0, 100);
      return str;
    }
    case "art": {
      str += data.asset_sub_type;
      str += " " + data.description.substring(0, 100);
      return str;
    }
    case "automobiles": {
      let str = "";
      data.vehicleList.map((each, i) => {
        str += i <= 2 && each.vehicle_type + ", ";
        // str[i] =
        //   i <= 2
        //     ? each.vehicle_type +
        //       " - " +
        //       each.vehicle_brand +
        //       " - " +
        //       each.vehicle_number
        //     : false;
      });
      return str;
    }
    case "private-stocks": {
      str += data.asset_sub_type + "  ";
      data.stock_detail.map((each, i) => {
        i <= 2 && (str += each.company_name + ", ");
        // +" - " +
        // (each.percent_unit === "percent"
        //   ? each.no_of_percent + "%"
        //   : each.no_of_shares + " Shares")
      });
      return str;
    }
    case "gratuity": {
      str += data.acc_number + "  ";
      str += data.employer_name + "  ";
      return str;
    }
    case "nps": {
      str += data.pran_number + "  ";
      return str;
    }
    default: {
      return str;
    }
  }
};
