import axios from "../interceptor";
import { call, put, takeEvery } from "redux-saga/effects";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../constants";

function createForm() {
  return axios({
    url: api_base + "/customer/createDraft",
    method: "get",
  });
}

function* createDraft_Form_G(action) {
  var result = yield call(createForm);

  try {
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }else
    if(result.status !== HTTP_OK){
      yield put({
        type: "GET_ERROR",
        payload: result.response?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

//====================================

function pastWillForm(action) {
  return axios({
    url: api_base + "/customer/createPastWillDraft/" + action.payload,
    method: "get",
  });
}

function* pastWillDraft_G(action) {
  var result = yield call(pastWillForm, action);
  try {
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

//====================================

function uploadedWill(action) {
  return axios({
    url: api_base + "/customer/getWills/" + action.payload,
    method: "get",
  });
}

function* uploadedWill_G(action) {
  var result = yield call(uploadedWill, action);
  try {
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

//==============================

function uploadWillForm(action) {
  return axios({
    url: api_base + "/customer/uploadWill",
    method: "post",
    data: action.payload,
  });
}

function* uploadWill_G(action) {
  try {
    var result = yield call(uploadWillForm, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data.message,
        data: result?.data?.data.assetID,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

//====================================

function deleteDraftForm() {
  return axios({
    url: api_base + "/customer/deleteDraft",
    method: "get",
  });
}

function* deleteDraft_Form_G(action) {
  var result = yield call(deleteDraftForm);
  try {
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

//==============================

function createNewWill(action) {
  return axios({
    url: api_base + "/customer/create_new_will",
    method: "post",
    data: action.payload,
  });
}

function* createNewWill_G(action) {
  var result = yield call(createNewWill, action);
  try {
    if (result.status === HTTP_OK) {
      let data = sessionStorage.getItem("data");
      sessionStorage.removeItem("serverError");

      const dataobj = JSON.parse(data);
      dataobj.currentWill = result?.data?.data.currentWill;
      dataobj.currentWillProgress = "unpaid";
      sessionStorage.setItem("data", JSON.stringify(dataobj));

      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data.message,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: result?.response?.data.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function getDownloadLink() {
  return axios({
    url: api_base + "/customer/createDraft",
    method: "get",
  });
}

function* willDownloadLink_G(action) {
  var result = yield call(getDownloadLink);
  try {
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

export function* WillSaga() {
  yield takeEvery("createDraft", createDraft_Form_G);
  yield takeEvery("deleteDraft", deleteDraft_Form_G);
  yield takeEvery("uplodWill", uploadWill_G);
  yield takeEvery("get_uploadedWill", uploadedWill_G);
  yield takeEvery("createNewWill", createNewWill_G);
  yield takeEvery("getPastWillDraft", pastWillDraft_G);
  yield takeEvery("getWillDownloadLink", willDownloadLink_G);
}
