import { Row, Form } from "react-bootstrap";
import { Field } from "formik";
const ContactForm = () => {
  return (
    <>
      <Row>
        <Form.Group className="col-md-4 col-12 mb-3">
          <Form.Label>Full Name</Form.Label>
          <Field name="full_name">
            {({ field, meta }) => (
              <>
                <Form.Control
                  {...field}
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                />
                {meta.touched && meta.error && (
                  <div className="field-error">{meta.error}</div>
                )}
              </>
            )}
          </Field>
        </Form.Group>
        <Form.Group className="col-md-4 col-12 mb-3">
          <Form.Label>Mobile Number</Form.Label>
          <Field name="mobile">
            {({ field, meta }) => (
              <>
                <Form.Control
                  {...field}
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                />
                {meta.touched && meta.error && (
                  <div className="field-error">{meta.error}</div>
                )}
              </>
            )}
          </Field>
        </Form.Group>
        <Form.Group className="col-md-4 col-12 mb-3">
          <Form.Label>Email</Form.Label>
          <Field name="email">
            {({ field, meta }) => (
              <>
                <Form.Control
                  {...field}
                  type="email"
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                />
                {meta.touched && meta.error && (
                  <div className="field-error">{meta.error}</div>
                )}
              </>
            )}
          </Field>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="col-md-4 col-12 mb-3">
          <Form.Label>City</Form.Label>
          <Field name="city">
            {({ field, meta }) => (
              <>
                <Form.Control
                  {...field}
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                />
                {meta.touched && meta.error && (
                  <div className="field-error">{meta.error}</div>
                )}
              </>
            )}
          </Field>
        </Form.Group>
        <Form.Group className="col-md-4 col-12 mb-3">
          <Form.Label>State</Form.Label>
          <Field name="state">
            {({ field, meta }) => (
              <>
                <Form.Control
                  {...field}
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                />
                {meta.touched && meta.error && (
                  <div className="field-error">{meta.error}</div>
                )}
              </>
            )}
          </Field>
        </Form.Group>
        <Form.Group className="col-md-4 col-12 mb-3">
          <Form.Label>Country</Form.Label>
          <Field name="country">
            {({ field, meta }) => (
              <>
                <Form.Control
                  {...field}
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                />
                {meta.touched && meta.error && (
                  <div className="field-error">{meta.error}</div>
                )}
              </>
            )}
          </Field>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="col-md-12 col-12 mb-3">
          <Form.Label>Notes</Form.Label>
          <Field name="notes">
            {({ field, meta }) => (
              <>
                <Form.Control
                  {...field}
                  as="textarea"
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                  placeholder=" Have a specific query? want to add a message to the team?"
                />
                {meta.touched && meta.error && (
                  <div className="field-error">{meta.error}</div>
                )}
              </>
            )}
          </Field>
        </Form.Group>
      </Row>
      <button
        type="submit"
        className="mt-auto form-button button"
        //onClick={handleClick}
      >
        Submit
      </button>
    </>
  );
};
export default ContactForm;
