import React, { Fragment, useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, FormikProvider, FieldArray } from "formik";
import { initialValues, validationSchema, getFormData, listObj } from "./util";
import SubmitBtn from "../../common/SubmitBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";

const Bonds = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} Bonds / Sovereign Gold
          Bond (SGB)
        </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FieldArray
              name="bondsList"
              render={(arrayHelpers) => {
                const { push, remove, form } = arrayHelpers;
                const { values } = form;
                const row =
                  values !== undefined
                    ? values.bondsList?.map((each, index) => {
                        return (
                          <div
                            key={`bondsList${index}`}
                            //className="mb-3"
                            style={{ borderBottom: "2px solid #c1c1c1" }}
                          >
                            <Row className="d-flex flex-row-reverse">
                              <div
                                className="col-md-1"
                                style={{ textAlign: "right" }}
                              >
                                {index > 0 && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faCircleXmark}
                                      className="removeFieldIcon"
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group className="col-md-6">
                                <Form.Label>Bond Issuer </Form.Label>
                                <Field
                                  name={`bondsList[${index}]['bond_issuer']`}
                                >
                                  {({ field, meta }) => (
                                    <>
                                      <Form.Control
                                        {...field}
                                        autoComplete="off"
                                        isInvalid={!!meta.error && meta.touched}
                                        value={field.value ? field.value : ""}
                                      />
                                      {!!meta.error && meta.touched && (
                                        <div className="field-error">
                                          {meta.error}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </Form.Group>
                              <Form.Group className="col-md-6">
                                <Form.Label>Bond Id</Form.Label>
                                <Field name={`bondsList.${index}.bond_id`}>
                                  {({ field, meta }) => {
                                    return (
                                      <>
                                        <Form.Control
                                          {...field}
                                          autoComplete="off"
                                          isInvalid={
                                            !!meta.error && meta.touched
                                          }
                                          value={field.value ? field.value : ""}
                                        />
                                        {!!meta.error && meta.touched && (
                                          <div className="field-error">
                                            {meta.error}
                                          </div>
                                        )}
                                      </>
                                    );
                                  }}
                                </Field>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group className="col-md-6">
                                <Form.Label>Bond Name </Form.Label>
                                <Field
                                  name={`bondsList[${index}]['bond_name']`}
                                >
                                  {({ field, meta }) => (
                                    <>
                                      <Form.Control
                                        {...field}
                                        autoComplete="off"
                                        isInvalid={!!meta.error && meta.touched}
                                        value={field.value ? field.value : ""}
                                      />
                                      {!!meta.error && meta.touched && (
                                        <div className="field-error">
                                          {meta.error}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </Form.Group>
                              <Form.Group className="col-md-6">
                                <Form.Label>Bond Units</Form.Label>
                                <Field name={`bondsList.${index}.bond_units`}>
                                  {({ field, meta }) => {
                                    return (
                                      <>
                                        <Form.Control
                                          {...field}
                                          autoComplete="off"
                                          isInvalid={
                                            !!meta.error && meta.touched
                                          }
                                          value={field.value ? field.value : ""}
                                        />
                                        {!!meta.error && meta.touched && (
                                          <div className="field-error">
                                            {meta.error}
                                          </div>
                                        )}
                                      </>
                                    );
                                  }}
                                </Field>
                              </Form.Group>
                            </Row>
                          </div>
                        );
                      })
                    : "";

                const result =
                  values !== undefined ? (
                    <>
                      {row}
                      <div className="form-btns mt-4">
                        <button
                          type="button"
                          className="button"
                          onClick={() => push(listObj)}
                        >
                          Add More Bonds
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  );
                return result;
              }}
            ></FieldArray>
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(Bonds);
