import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const ConfirmationPopup = (props) => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    props.confirmation(false);
    props.handleCancelConfirmation();
  };

  const handleYes = () => {
    setShow(false);
    props.confirmation(true);
  };

  const handleNo = () => {
    setShow(false);
    props.confirmation(false);
    props.handleCancelConfirmation();
  };

  return (
    <Modal show={show} centered onHide={handleClose} className="executorPopup">
      <FontAwesomeIcon
        icon={faCircleXmark}
        onClick={handleClose}
        className="modalCloseIcon"
        backdrop="static"
      />
      <Modal.Body>
        <b>Are you sure you want to Finalize the will?</b>
        <br />
        <p className="text-left mt-3 ">
          You cannot make any changes to the will beyond this point. You can
          click cancel and go back to make changes if required.
        </p>

        <b>Do you want to Finalize Will?</b>
        <div className="mt-md-5 mt-3">
          <button type="button" className="button col-4" onClick={handleYes}>
            Yes, I Confirm
          </button>
          <button
            type="button"
            className="button col-4 float-end col-offset-1"
            onClick={handleNo}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationPopup;
