import * as React from "react";
import signup from "../../../assets/images/signup.png";
import EmailOTPForm from "../common/EmailOTPForm";
import { connect } from "react-redux";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import { customiseFormSuccessMsg } from "../../../util";
import { useEffect, useState } from "react";
import { accountSetting } from "../../../route";
import { useNavigate } from "react-router-dom";
import Layout3 from "../../layout/Layout3";
import { willtype } from "../../../route";

const emailKey = "verify_email_otp";
const VerifyUser = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);

    if (props.emailForm.success == true) {
      let data = sessionStorage.getItem("data");
      const dataobj = JSON.parse(data);
      dataobj.email_verified_at = props.emailForm.data;
      sessionStorage.setItem("data", JSON.stringify(dataobj));
      setTimeout(function () {
        props.dispatch({
          type: "RESET_FORM",
          key: emailKey,
        });
        const sess_data = sessionStorage.getItem("data");
        const sessionData = JSON.parse(sess_data);
        if (sessionData !== null && sessionData !== undefined) {
          // if(sessionData.will_type == 0)
          // {
          //   navigate(willtype);
          // }
          // else
          // {
            navigate(accountSetting);
          //}
        }
      }, 3000);
    }
  }, [props.emailForm]);
  return (
    <Layout3>
      {loading && <Loader />}
      {props?.emailForm?.error === true && (
        <CustomModal
          body={props.emailForm.message}
          moreError={props.emailForm.moreError}
          stateKey={emailKey}
          type="error"
        />
      )}
      {props?.emailForm?.success === true && (
        <CustomModal
          body={customiseFormSuccessMsg(props.emailForm.message)}
          stateKey={emailKey}
          type="success"
        />
      )}
      <div className="main-layout d-flex verify-email-page">
        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
          <img src={signup} className="mt-md-0 mt-sm-0 mb-sm-5 register-img" />
        </div>
        <EmailOTPForm setLoading={setLoading} />
      </div>
    </Layout3>
  );
};
export default connect((state) => {
  return { emailForm: { ...state?.Forms?.[emailKey] } };
})(VerifyUser);
