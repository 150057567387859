import MasterLayout from "./MasterLayout";

const Layout3 = (props) => {
  return (
    <>
      <MasterLayout fluid={true} showWillBtn={false}>
        <section className="row p-0 layout3section">
          <div className="body_cont_main-layout col-md-12 col-lg-12">
            {props.children}
          </div>
        </section>
      </MasterLayout>
    </>
  );
};
export default Layout3;
