import { Container, Row } from "react-bootstrap";
const WebFooter = () => {
  return (
    <section className="section-footer mt-3">
      <Container className="desktopFooter">
        <div className="pb-4 pt-4">
          <Row>
            <div className="col-md-4">
              <h5>
                <b>Willyourwish</b>
              </h5>
            </div>
            <div className="col-md-4">
              <b>Contact Us</b>
            </div>
            <div className="col-md-4">
              <b>About Us</b>
            </div>
          </Row>
          <Row>
            <div className="col-md-4">
              <b>Mon-Fri: 8am to 5pm</b>
            </div>
            <div className="col-md-4">
              <div>Bhairav: +91 8502384084</div>
              <div>Vairav: +91 8502384084</div>
            </div>
            <div className="col-md-4">
              <div>Legal Updates</div>
              <div>Support</div>
            </div>
          </Row>
        </div>
        <hr />
        <Row className="footer">
          <div className="col-md-6">All Rights reserved</div>
          <div className="col-md-6 d-flex flex-row-reverse">
            Privacy Policy | Terms and Conditions
          </div>
        </Row>
      </Container>
      <Container className="smallFooter d-none">
        <Row>
          <div className="col-7 leftSection">
            <div>
              <b>Willyourwish</b>
            </div>
            <div>Bhairav: +91 8502384084</div>
            <div>Vairav: +91 8502384084</div>
            <div>
              <b>Mon-Fri: 8am to 5pm</b>
            </div>
          </div>
          <div className="col-5">
            <div>Legal Updates</div>
            <div>Support</div>
          </div>
        </Row>
        <hr />
        <Row>
          <div className="col-4">All Rights reserved</div>
          <div className="col-4">Privacy Policy</div>
          <div className="col-4">Terms and Conditions</div>
        </Row>
      </Container>
    </section>
  );
};
export default WebFooter;
