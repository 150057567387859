import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";

import { Field, useFormik, FormikProvider } from "formik";

import { initialValues, validationSchema, getFormData } from "./util";
import Loader from "../../common/Loader/Loader";
import CustomModal from "../../common/CustomModal";
import { customiseFormSuccessMsg, encrypt } from "../../../../util";
import { selectAsset } from "../../../../route";
import SubmitBtn from "../../common/SubmitBtn";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";

const SGB = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];
  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} Sovereign Gold Bond
          (SGB)
        </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
              <Form.Group className="col-md-6">
                <Form.Label>SGB Id</Form.Label>
                <Field name="sgb_id">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>

              <Form.Group className="col-md-6">
                <Form.Label>SGB Demat</Form.Label>
                <Field name="sgb_demat">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(SGB);
