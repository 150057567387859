import axios from "../interceptor";
import { call, put, takeEvery } from "redux-saga/effects";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../constants";

function addForm(action) {
  return axios({
    url: api_base + "/customer/contact-form",
    method: "post",
    data: action.payload,
  });
}

function* formG(action) {
  try {
    var result = yield call(addForm, action);
    if (result.status === HTTP_OK) {
      //============================//
      let data = sessionStorage.getItem("data");
      const dataobj = JSON.parse(data);
      if (result.data.via == "NRI_form") {
        dataobj.nri_contact_form = result.data.date;
      } else if (result.data.via == "muslim_form") {
        dataobj.muslim_contact_form = result.data.date;
      }
      sessionStorage.setItem("data", JSON.stringify(dataobj));

      //====================//

      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.message,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "FORM_ERROR",
        payload: error?.response?.data,
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function h_addForm(action) {
  return axios({
    url: api_base + "/customer/home-contact-form",
    method: "post",
    data: action.payload,
  });
}

function* h_formG(action) {
  try {
    var result = yield call(h_addForm, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.message,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "FORM_ERROR",
        payload: error?.response?.data,
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

export function* ContactForm() {
  yield takeEvery("contact_form", formG);
  yield takeEvery("home_contact_form", h_formG);
}
