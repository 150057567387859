import React from "react";
import  ReCAPTCHA  from "react-google-recaptcha";
import CustomModal from "./CustomModal";
const Captcha = (props)=>{
    return <>
    <ReCAPTCHA 
    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
    onChange={(v)=>{props.onChange(v)}}
    ref={props.reference}
    />

    {props.captchaError === true && (
        <CustomModal
        body="Captcha Error"
        moreError={["Please Check on I'm not a robot!"]}
        stateKey={props.stateKey}
        type="error"
      />
    )}
    </>
}
export default  Captcha;