import axios from "../interceptor";
import { call, put, takeEvery } from "redux-saga/effects";
import { api_base } from "../../constants";

function Log_out() {
  return axios({
    url: api_base + "/customer/logout",
    method: "get",
  });
}

function* Logout_Generator(action) {
  try {
    var result = yield call(Log_out, action);
    if (result?.status) {
      yield put({
        type: "FORM_SUCCESS",
        payload: { success: true },
        key: action.key,
      });
    } else {
      yield put({
        type: "LOGOUT_FAIL",
        payload: "logout failed",
        key: action.key,
      });
    }
  } catch (error) {
    yield put({
      type: "LOGOUT_FAIL",
      payload: "Something went wrong",
      key: action.key,
    });
  }
}
export function* Logout() {
  yield takeEvery("User_logout", Logout_Generator);
}
