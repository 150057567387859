import React, { useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { FormikProvider } from "formik";
import { initialValues, validationSchema, getFormData } from "./util";
import SubmitBtn from "../../common/SubmitBtn";
import FieldWithTooltip from "../../common/FieldWithTooltip";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";

const PF = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} PF
        </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <FieldWithTooltip
                  label="PF Account Number"
                  name="acc_number"
                  text="You can find this on this Salary Slip/Employment records/ EPFO Account statement."
                  placeholder="ABCDE01234567890123456 "
                />
              </Form.Group>

              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <FieldWithTooltip
                  name="uan_no"
                  label="UAN Number"
                  text="This is your Unique Account Number You can find this on this Salary Slip/Employment records/ EPFO Account statement"
                  placeholder="0123456789012"
                />
              </Form.Group>
            </Row>
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state, props) => {
  return {
    formResult: { ...state.Forms[stateKey] },
    assetDetail: { ...state.GetData[props?.editAssetKey] },
  };
})(PF);
