import {
  asset_sub_type,
  initialValues,
  validationSchema,
  detailObj,
} from "./util";
import { Fragment, useEffect } from "react";
import { Field, FormikProvider, FieldArray } from "formik";
import { Form, Row } from "react-bootstrap";
import { Radio } from "@palmerhq/radio-group";
import { FRadioGroup } from "../../common/RadioButtons";
import SubmitBtn from "../../common/SubmitBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";

const PrivateStocks = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} Private
          Stocks/ESPOPs/RSUs
        </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
              <Form.Group className="col-md-12">
                <Form.Label>Asset Type</Form.Label>
                <FRadioGroup name="asset_sub_type" autoFocus={false}>
                  {asset_sub_type.map((each, index) => {
                    return (
                      <Radio
                        key={`option${index}`}
                        value={each}
                        className="col-lg-4 col-md-4 col-sm-4 col-12"
                      >
                        {each}
                      </Radio>
                    );
                  })}
                </FRadioGroup>
              </Form.Group>
            </Row>
            <FieldArray
              name="stock_detail"
              render={(arrayHelpers) => {
                const { push, remove, form } = arrayHelpers;
                const { values } = form;
                const row =
                  values !== undefined
                    ? values.stock_detail?.map((each, index) => {
                        return (
                          <div
                            key={`stock_detail${index}`}
                            className="mb-lg-5 mb-md-5 mb-3"
                            style={{ borderBottom: "2px solid #c1c1c1" }}
                          >
                            <div className="d-flex mb-3">
                              <Form.Group className="col-md-10 col-11">
                                <Form.Label>Company Name </Form.Label>
                                <Field
                                  name={`stock_detail[${index}]['company_name']`}
                                >
                                  {({ field, meta }) => (
                                    <>
                                      <Form.Control
                                        {...field}
                                        autoComplete="off"
                                        isInvalid={!!meta.error && meta.touched}
                                        value={field.value ? field.value : ""}
                                      />
                                      {!!meta.error && meta.touched && (
                                        <div className="field-error">
                                          {meta.error}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </Form.Group>

                              <div className="col-md-1 col-1">
                                {index > 0 && (
                                  <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    className="removeFieldIcon"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="d-lg-flex d-md-flex mb-3">
                              <Form.Group className="col-md-4 col-12">
                                <Form.Label>Select One</Form.Label>
                                <FRadioGroup
                                  name={`stock_detail.${index}.percent_unit`}
                                  autoFocus={false}
                                >
                                  <Radio value="percent">Percentage</Radio>
                                  <Radio value="no_of_share">
                                    No. of shares
                                  </Radio>
                                </FRadioGroup>
                              </Form.Group>
                              {each.percent_unit !== "percent" && (
                                <Form.Group className="col-md-4 col-12 mt-sm-0 mt-2">
                                  <Form.Label>No of shares</Form.Label>
                                  <Field
                                    name={`stock_detail.${index}.no_of_shares`}
                                  >
                                    {({ field, meta }) => (
                                      <>
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          autoComplete="off"
                                          isInvalid={
                                            !!meta.error && !!meta.touched
                                          }
                                          value={field.value ? field.value : ""}
                                        />
                                        {meta.touched && meta.error && (
                                          <Form.Control.Feedback type="invalid">
                                            {meta.error}
                                          </Form.Control.Feedback>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </Form.Group>
                              )}
                              {each.percent_unit == "percent" && (
                                <Form.Group className="col-md-4 col-12 mt-sm-0 mt-2">
                                  <Form.Label>No of Percentage</Form.Label>
                                  <Field
                                    name={`stock_detail.${index}.no_of_percent`}
                                  >
                                    {({ field, meta }) => (
                                      <>
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          autoComplete="off"
                                          isInvalid={
                                            !!meta.error && !!meta.touched
                                          }
                                          value={field.value ? field.value : ""}
                                        />
                                        {meta.touched && meta.error && (
                                          <Form.Control.Feedback type="invalid">
                                            {meta.error}
                                          </Form.Control.Feedback>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </Form.Group>
                              )}
                            </div>
                          </div>
                        );
                      })
                    : "";

                const result =
                  values !== undefined ? (
                    <>
                      {row}
                      <div className="form-btns mt-1">
                        <button
                          type="button"
                          className="button"
                          onClick={() => push(detailObj)}
                        >
                          Add More Folio
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  );
                return result;
              }}
            ></FieldArray>
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default PrivateStocks;
