import { encryptSecret } from "../constants";
import store from "../redux/Store";

var CryptoJS = require("crypto-js");
export const customiseFormSuccessMsg = (msg) => {
  return (
    <>
      <div>{msg}</div>
      <div>Please wait you are being redirected</div>
    </>
  );
};

export function encrypt(data) {
  return CryptoJS.Rabbit.encrypt(JSON.stringify(data), encryptSecret)
    .toString()
    .replace(/\//g, "Por21Ld");
}

export function decrypt(data) {
  var bytes = CryptoJS.Rabbit.decrypt(
    data.replaceAll("Por21Ld", "/"),
    encryptSecret
  );
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export function searchFor(toSearch, objects, objkey) {
  var FOUND = -1;
  for (var i = 0; i < objects.length; i++) {
    if (objects[i][objkey] == toSearch) {
      FOUND = i;
      break;
    }
  }
  return FOUND;
}

export const ID_PROOF_ARR = [];
ID_PROOF_ARR["0"] = "Pan Card";
ID_PROOF_ARR["1"] = "Aadhar Card";
ID_PROOF_ARR["2"] = "Voter Id";
ID_PROOF_ARR["3"] = "Driving License";

export const relationDropdown = [];
relationDropdown["0"] = "Spouse";
relationDropdown["1"] = "Child";
relationDropdown["2"] = "Father";
relationDropdown["3"] = "Mother";
relationDropdown["4"] = "Sister";
relationDropdown["5"] = "Brother";
relationDropdown["6"] = "Cousin";
relationDropdown["7"] = "Friend";
relationDropdown["8"] = "Neighbour";
relationDropdown["9"] = "Other";

export function changeToYMD(date, sign = "-") {
  var givenDate = new Date(date),
    month = "" + (givenDate.getMonth() + 1),
    day = "" + givenDate.getDate(),
    year = givenDate.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join(sign);
}

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function changeToDMY(date, sign = "-") {
  var givenDate = new Date(date),
    month = "" + monthNames[givenDate.getMonth()],
    day = "" + givenDate.getDate(),
    year = givenDate.getFullYear();

  if (day.length < 2) day = "0" + day;

  return [day, month, year].join(sign);
}

export function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function array_chunk(arr, size) {
  if (!Array.isArray(arr)) {
    throw new TypeError("Input should be Array");
  }

  if (typeof size !== "number") {
    throw new TypeError("Size should be a Number");
  }

  var result = [];
  for (var i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, size + i));
  }

  return result;
}

export function calculateAge(dob) {
  const birthday = parseDate(dob);
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

// parse a date in yyyy-mm-dd format
function parseDate(input) {
  var parts = input.match(/(\d+)/g);
  return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
}

export const RemoveFromFieldArray = (obj) => {
  store.dispatch({
    type: "REMOVE_FROM_FIELDARRAY",
    payload: {
      showModal: true,
      removeRow: false,
      helper: obj.field,
      index: obj.index,
    },
  });
};

//loop through object
// const keys = Object.keys(each.asset_detail);
// {
//   keys.forEach((key, index) => {
//     console.log(`${key}: ${each.asset_detail[key]}`);
//   });
// }
