import * as Yup from "yup";
import { notOnlyWhiteSpace, amountFormat } from "../../../../constants";

export const listObj = {
  amount: "",
  currency: "",
};
export const initialValues = {
  cashList: [listObj],
  
};
export const validationSchema = Yup.object().shape({
  cashList: Yup.array().of(
    Yup.object().shape({
      currency: Yup.string()
        .required("*This is required")
        .max(100, "*This can't be longer than 100 characters")
        .matches(notOnlyWhiteSpace, "*Only Spaces are not allowed "),
      amount: Yup.string()
        .required("*This is required")
        //.typeError("Only numbers allowed")
        .matches(amountFormat, "*Amount is invalid"),
    })
  ),
  // amount: Yup.string()
  //   .required("*This is required")
  //   .max(100, "*This can't be longer than 100 characters")
  //   .matches(
  //     notOnlyWhiteSpace,
  //     "*Only Alphabets,Numbers and Spaces are allowed "
  //   ),

});

export const getFormData = (values, asset_type) => {
  let formData = new FormData();

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  formData.append("asset_type", asset_type);
  return formData;
};
