import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Worker, Viewer, ProgressBar } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
//import { assetSummary, paymentPlans } from "../../../route";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faPencil } from "@fortawesome/free-solid-svg-icons";

const getKey = "createDraft";
const deleteKey = "deleteDraft";

const ViewDraft2 = (props) => {
  const [loading, setLoading] = useState(true);
  //const [show, setShow] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    props.dispatch({
      type: "getPastWillDraft",
      payload: props.id,
      key: getKey,
    });
  }, []);

  useEffect(() => {
    setLoading(false);
    setShow(true);
  }, [props.getData.data]);

  const closePdfView = () => {
    props.dispatch({
      type: "deleteDraft",
      key: deleteKey,
    });
    props.handleClose();
  };

  useEffect(() => {
    if (props.deleteData.success == true) {
      props.dispatch({
        type: "RESET_GET",
        key: getKey,
      });
    }
  }, [props.deleteData.success]);

  const renderPage: RenderPage = (props: RenderPageProps) => {
    return (
      <div style={{ userSelect: "none" }}>
        {props.canvasLayer.children}
        <div
          style={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            justifyContent: "center",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        ></div>
        {props.annotationLayer.children}
        {props.textLayer.children}
      </div>
    );
  };

  return (
    <>
      <Modal
        show={show}
        centered
        className=""
        onHide={() => {
          if (props?.restrictClose) {
            return false;
          } else {
            closePdfView();
          }
        }}
        size="xl"
        backdrop="static"
      >
        <FontAwesomeIcon
          icon={faCircleXmark}
          onClick={closePdfView}
          className="modalCloseIcon"
        />
        <Modal.Body>
          <div className="col-md-4 mb-3">
            <b>Will Draft</b>
          </div>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.3)",
                height: "750px",
              }}
            >
              {props.getData?.data?.fileLink !== "" &&
                props.getData?.data?.fileLink !== undefined && (
                  <Viewer
                    fileUrl={props.getData?.data?.fileLink}
                    renderLoader={(percentages) => (
                      <div style={{ width: "240px" }}>
                        <ProgressBar progress={Math.round(percentages)} />
                      </div>
                    )}
                    renderPage={renderPage}
                  />
                )}
            </div>
          </Worker>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect((state) => {
  return {
    getData: { ...state.GetData[getKey] },
    deleteData: { ...state.GetData[deleteKey] },
  };
})(ViewDraft2);
