import * as Yup from "yup";

import { PDF_FORMATS } from "../../../../constants";

export const initialValues = {
  
  file: "",
};

export const validationSchema = Yup.object().shape({
  //   file: Yup.mixed()
  //     .required("*Document File is required")
  //     .test("fileSize", "Your Document is too big ", (value) => {
  //       if (value !== undefined) {
  //         var filesize = value && Math.round(value.size / 1024);
  //         return filesize && filesize < 3072;
  //       } else {
  //         return true;
  //       }
  //     })
  //     .test(
  //       "FILE_FORMAT",
  //       "Uploaded file has unsupported format.",
  //       (value) => !value || (value && PDF_FORMATS.includes(value.type))
  //     ),

});

export const getFormData = (values) => {
  let formData = new FormData();

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  return formData;
};
