import { passwordRegex } from "../../../constants";
import * as Yup from "yup";
export const validationSchema = Yup.object().shape({
  //   Yup.string()
  //     .min(6, "*Captcha must have at least 6 characters")
  //     .max(6, "*Captcha can't be longer than 6 characters")
  //     .required("*Captcha is required"),
  currentPassword: Yup.string().required("*Current Password is required"),
  newPassword: Yup.string()
    .min(8, "*New Password must have at least 8 characters")
    .max(15, "*Password must not be more than 15 characters")
    .matches(
      passwordRegex,
      "*Password must fullfill requirements,refer to the notes "
    )
    .required("*New Password  is required"),
  confirmNewPassword: Yup.string()
    .min(8, "*Confirm Password  must have at least 8 characters")
    .max(15, "*Password must not be more than 15 characters")
    .matches(
      passwordRegex,
      "*Password must fullfill requirements,refer to the notes "
    )
    .required("*Confirm Password is required")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "New Password and Confirm Passwords don't match"
    ),
});

export const initialValues = {
  
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

export const getFormData = (values) => {
  let formData = new FormData();

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  return formData;
};
