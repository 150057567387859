import { connect } from "react-redux";
import { Form, Row } from "react-bootstrap";
import { Field, FormikProvider, useFormik } from "formik";
import { initialValues, validationSchema, getFormData } from "./util";
import { useState, useEffect } from "react";
import CustomModal from "../common/CustomModal";
import SubmitBtn from "../common/SubmitBtn";
import PassToggle from "../common/PassToggle";
const stateKey = "changePassword";

const PasswordSetting = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //loadCaptchaEnginge(6);
    setLoading(false);
  }, []);

  const submitHandler = (values) => {
    

    if (formik.isValid === true) {
      setLoading(true);
      const { user_captcha, ...formval } = values;
      const formData = getFormData(formval);
      props.dispatch({
        type: "Change_Password",
        payload: formData,
        key: stateKey,
      });
    }
    // } else {
    //   setCaptchaError(true);
    // }
    
    
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (props.formResult?.success === true) {
      setLoading(false);

      setTimeout(() => {
        formik.resetForm();
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
      }, 1000);
    }
  }, [props?.formResult?.success]);

  return (
    <>
      <div className="acc-page-heading">Change Password </div>

      {props.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {props.formResult.success === true && (
        <CustomModal
          body={props.formResult.message}
          stateKey={stateKey}
          type="success"
        />
      )}
      <div className="main-layout-cont passwordSetting">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="col-md-4 mb-3  position-relative">
                <Form.Label>Current Password</Form.Label>
                <PassToggle name="currentPassword" />
              </Form.Group>
              <Form.Group className="col-md-4 mb-3  position-relative">
                <Form.Label>New Password</Form.Label>
                <PassToggle name="newPassword" />
              </Form.Group>
              <Form.Group className="col-md-4 mb-3 position-relative">
                <Form.Label>Confirm New Password</Form.Label>
                <PassToggle name="confirmNewPassword" />
              </Form.Group>
            </Row>

            <SubmitBtn text="Update" />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
export default connect((state) => {
  return { formResult: { ...state?.Forms?.[stateKey] } };
})(PasswordSetting);
