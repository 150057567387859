import React, { useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Form, Row } from "react-bootstrap";
import { Field } from "formik";
const getKey = "pincodeDetail";

const CountryStateCityPincode = (props) => {
  const formik = props.formik;
  const pinCodeField = props.fieldname.pin;
  const countryField = props.fieldname.country;
  const stateField = props.fieldname.state;
  const cityField = props.fieldname.city;

  var city =
    props?.pincodeDetail?.data?.length > 0
      ? props?.pincodeDetail?.data[0]?.Circle
      : "";
  var state =
    props?.pincodeDetail?.data?.length > 0
      ? props.pincodeDetail.data[0].State
      : "";
  var country =
    props?.pincodeDetail?.data?.length > 0
      ? props.pincodeDetail.data[0].Country
      : "";

  useEffect(() => {
    var newObj = {};
    newObj[countryField] = country;
    newObj[stateField] = state;
    newObj[cityField] = city;

    const initVal = formik.values;
    props.setInitial_values({ ...initVal, ...newObj });
  }, [city, state, country]);

  const getCountryStateCity = () => {
    props.dispatch({
      type: "get_country_state_city",
      payload: formik.values[pinCodeField],
      key: getKey,
    });
  };

  return (
    <>
      <Row className="">
        <Form.Group className="col-md-3 col-12 mb-3">
          <Form.Label>Pincode</Form.Label>
          <Field name={props.fieldname.pin}>
            {({ field, meta }) => (
              <>
                <Form.Control
                  {...field}
                  type="text"
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                  onBlur={getCountryStateCity}
                />
                {meta.touched && meta.error && (
                  <Form.Control.Feedback type="invalid">
                    {meta.error}
                  </Form.Control.Feedback>
                )}
              </>
            )}
          </Field>
        </Form.Group>

        <Form.Group className="col-md-3 col-12 mb-3">
          <Form.Label>city</Form.Label>
          <Field name={cityField}>
            {({ field, meta }) => (
              <>
                <Form.Control
                  {...field}
                  type="text"
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                />
                {meta.touched && meta.error && (
                  <Form.Control.Feedback type="invalid">
                    {meta.error}
                  </Form.Control.Feedback>
                )}
              </>
            )}
          </Field>
        </Form.Group>

        <Form.Group className="col-md-3 col-12 mb-3">
          <Form.Label>State</Form.Label>
          <Field name={stateField}>
            {({ field, meta }) => (
              <>
                <Form.Control
                  {...field}
                  type="text"
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                  onBlur={getCountryStateCity}
                />
                {meta.touched && meta.error && (
                  <Form.Control.Feedback type="invalid">
                    {meta.error}
                  </Form.Control.Feedback>
                )}
              </>
            )}
          </Field>
        </Form.Group>
        <Form.Group className="col-md-3 col-12 mb-3">
          <Form.Label>Country</Form.Label>
          <Field name={countryField}>
            {({ field, meta }) => (
              <>
                <Form.Control
                  {...field}
                  type="text"
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                />
                {meta.touched && meta.error && (
                  <Form.Control.Feedback type="invalid">
                    {meta.error}
                  </Form.Control.Feedback>
                )}
              </>
            )}
          </Field>
        </Form.Group>
      </Row>
    </>
  );
};
export default connect((state) => {
  return {
    pincodeDetail: state?.GetData[getKey],
  };
})(CountryStateCityPincode);
