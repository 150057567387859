import { Navigate } from "react-router-dom";
import { personalDetail, verify_user,willtype,accountSetting } from "../../route";

export default function Private({ children }) {
  const sess_data = sessionStorage.getItem("data");
  const sessionData = JSON.parse(sess_data);
  const firstPath = window.location.pathname.split("/")[1];

  if (sessionData !== null && sessionData !== undefined) {
    if (sessionData.email_verified_at == null && 
      firstPath !== "verify_user"){
      return <Navigate to="/verify_user/email" replace />;
    }
    else if (sessionData.email_verified_at !== null && 
      sessionData.currentWill ==null &&
      "/" + firstPath !== accountSetting && 
      "/" + firstPath !== willtype
      )
    {
      return <Navigate to={accountSetting} replace />;
    }else if(
      sessionData.email_verified_at !== null &&
      sessionData.currentWill !==null &&
      "/" + firstPath !== accountSetting &&
      "/" + firstPath !== personalDetail && 
      (
        sessionData.religion ==null ||
        sessionData.nationality == null || 
        sessionData.religion === "Muslim" ||
        sessionData.nationality !== "Indian"
      ))
    {
      return <Navigate to={personalDetail} replace />;
    }
  } else {
    return <Navigate to="/" replace />;
  }
  return children;
}
