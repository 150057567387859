import React, { useEffect, Fragment } from "react";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, FormikProvider, FieldArray } from "formik";
import { initialValues, validationSchema, listObj, getFormData } from "./util";
import SubmitBtn from "../../common/SubmitBtn";
import { Radio } from "@palmerhq/radio-group";
import { FRadioGroup } from "../../common/RadioButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";

const Stocks = (props) => {
  const boxRef = React.createRef();

  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  let tooltip_text = (
    <p>
      Download CAS (Consolidated Account Statement) from NSDL to find all the
      stocks/securities across borkers held by you in one place. Link for NSDL
      is given below. Make sure you register it with the same email id which you
      have been using for investing in in stocks Link for NSDL CAS:
      <a href="https://nsdlcas.nsdl.com/">https://nsdlcas.nsdl.com/</a> (If you
      are already registered here, you would be getting monthly emails from NSDL
      CAS)
    </p>
  );

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} Stocks and ETFs
        </div>
      </div>

      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div className="d-lg-flex d-md-flex">
              <div className="col-lg-8 col-md-8 col-12">
                <Row className="">
                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label className="mb-2">
                      Client ID{" "}
                      <FontAwesomeIcon
                        style={{ color: "#575757" }}
                        icon={faInfoCircle}
                        onMouseEnter={() => {
                          boxRef.current.style.visibility = "visible";
                        }}
                        onMouseLeave={() => {
                          boxRef.current.style.visibility = "hidden";
                        }}
                      />
                    </Form.Label>
                    <Field name="client_id">
                      {({ field, meta }) => (
                        <>
                          <Form.Control
                            {...field}
                            type="text"
                            autoComplete="off"
                            isInvalid={!!meta.error && !!meta.touched}
                            value={field.value ? field.value : ""}
                            onFocus={() => {
                              boxRef.current.style.visibility = "visible";
                            }}
                            onBlur={() => {
                              boxRef.current.style.visibility = "hidden";
                            }}
                          />
                          {meta.touched && meta.error && (
                            <Form.Control.Feedback type="invalid">
                              {meta.error}
                            </Form.Control.Feedback>
                          )}
                        </>
                      )}
                    </Field>
                  </Form.Group>
                  <Form.Group className="col-md-6 mb-3">
                    <Form.Label className=" mb-2">
                      DP ID{" "}
                      <FontAwesomeIcon
                        style={{ color: "#575757" }}
                        icon={faInfoCircle}
                        onMouseEnter={() => {
                          boxRef.current.style.visibility = "visible";
                        }}
                        onMouseLeave={() => {
                          boxRef.current.style.visibility = "hidden";
                        }}
                      />
                    </Form.Label>
                    <Field name="dp_id">
                      {({ field, meta }) => (
                        <>
                          <Form.Control
                            {...field}
                            type="text"
                            autoComplete="off"
                            isInvalid={!!meta.error && !!meta.touched}
                            value={field.value ? field.value : ""}
                            onFocus={() => {
                              boxRef.current.style.visibility = "visible";
                            }}
                            onBlur={() => {
                              boxRef.current.style.visibility = "hidden";
                            }}
                          />
                          {meta.touched && meta.error && (
                            <Form.Control.Feedback type="invalid">
                              {meta.error}
                            </Form.Control.Feedback>
                          )}
                        </>
                      )}
                    </Field>
                  </Form.Group>
                </Row>
                <Form.Group className="col-md-12 mb-3">
                  <FRadioGroup name="account_stocks" autoFocus={false}>
                    <Radio value="list">List Stocks Below</Radio>
                    <Radio value="all">All Stocks under this account</Radio>
                  </FRadioGroup>
                </Form.Group>
                {formik?.values?.account_stocks == "list" && (
                  <FieldArray
                    name="stockList"
                    render={(arrayHelpers) => {
                      const { push, remove, form } = arrayHelpers;
                      const { values } = form;
                      const row =
                        values !== undefined
                          ? values.stockList?.map((each, index) => {
                              return (
                                <Row className="" key={`stockList${index}`}>
                                  <Form.Group className="col-md-6 mb-3">
                                    <Form.Label>Name </Form.Label>
                                    <Field name={`stockList.${index}.name`}>
                                      {({ field, meta }) => (
                                        <>
                                          <Form.Control
                                            {...field}
                                            autoComplete="off"
                                            isInvalid={
                                              !!meta.error && !!meta.touched
                                            }
                                            value={
                                              field.value ? field.value : ""
                                            }
                                          />
                                          {meta.error && meta.touched && (
                                            <div className="field-error">
                                              {meta.error}
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </Form.Group>
                                  <Form.Group className="col-md-5 mb-3">
                                    <Form.Label>Quantity</Form.Label>
                                    <Field name={`stockList.${index}.qty`}>
                                      {({ field, meta }) => (
                                        <>
                                          <Form.Control
                                            {...field}
                                            autoComplete="off"
                                            isInvalid={
                                              !!meta.error && !!meta.touched
                                            }
                                            value={
                                              field.value ? field.value : ""
                                            }
                                          />
                                          {meta.error && meta.touched && (
                                            <div className="field-error">
                                              {meta.error}
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </Form.Group>

                                  {index > 0 && (
                                    <div className="col-md-1">
                                      <FontAwesomeIcon
                                        icon={faCircleXmark}
                                        className="removeFieldIcon"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                      />
                                    </div>
                                  )}
                                </Row>
                              );
                            })
                          : "";

                      const result =
                        values !== undefined ? (
                          <Fragment key="stockList">
                            {row}
                            <div className="form-btns mt-1 mb-3">
                              <button
                                type="button"
                                className="button"
                                onClick={() => push(listObj)}
                              >
                                Add More
                              </button>
                            </div>
                          </Fragment>
                        ) : (
                          <></>
                        );
                      return result;
                    }}
                  ></FieldArray>
                )}
              </div>
              <div
                className="col-lg-4 col-md-4 col-12 stock-etf-box"
                ref={boxRef}
              >
                {tooltip_text}
              </div>
            </div>
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(Stocks);
