import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Form, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, useFormik, FormikProvider } from "formik";
import {
  initialValues,
  validationSchema,
  getFormData,
  mapNomineeList,
  detail,
} from "./util";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import {
  ID_PROOF_ARR,
  decrypt,
  customiseFormSuccessMsg,
  searchFor,
  relationDropdown,
} from "../../../util";
import { assetSummary } from "../../../route";
import IDProofDropdown from "../common/IDProofDropdown";
import MainLayout from "../../layout/Mainlayout";
import RelationDropdown from "../common/RelationDropdown";
import dustbinIcon from "../../../assets/images/dustbin.png";
import pencilIcon from "../../../assets/images/pencil.png";
import ErrorModal from "../common/ErrorModal";
import MyNomineesDropdown from "../common/MyNomineesDropdown.js";
import AddNonmiee from "../AddNominee";
import EditIconWithToolTip from "../common/EditIconWithToolTip";
import DeleteIconWithToolTip from "../common/DeleteIconWithToolTip";

const stateKey = "AddAssetNominee";
const getNomineekey = "assetNominee";
const masterAssetDetailKey = "masterAssetDetail";
const AssetDetailKey = "AssetDetail";
const getListkey = "nomineeList";

const AddAssetNominee = (props) => {
  const navigate = useNavigate();
  const firstPath = window.location.pathname.split("/")[1];
  const { asset_id, master_asset_id } = useParams();
  const [selectedNominee, setSelectedNominee] = useState({});
  const [initial_values, setInitial_values] = useState(initialValues);
  const assetId = decrypt(asset_id);
  // const [captchaError, setCaptchaError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nomineeList, setNomineeList] = useState([]);
  const [totalPercent, setTotalPercent] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    props.dispatch({
      type: "get_asset_detail",
      payload: decrypt(asset_id),
      key: AssetDetailKey,
    });
    if (firstPath === "edit-asset-nominee") {
      props.dispatch({
        type: "get_Asset_nominee",
        payload: decrypt(asset_id),
        key: getNomineekey,
      });
    }
  }, []);

  useEffect(() => {
    if (firstPath === "edit-asset-nominee") {
      const mappedData = mapNomineeList(props.assetNominee.data);
      if (
        props.assetNominee.loading == false &&
        props.assetNominee.success == true &&
        props.assetNominee.data.length > 0
      ) {
        setNomineeList(mappedData);
      }
      setLoading(props.assetNominee.loading);
    }
  }, [props.assetNominee]);

  useEffect(() => {
    calculatePercent();
  }, [nomineeList]);

  const SaveForm = () => {
    
    if (totalPercent !== 100) {
      setErrorMsg("The total of nominee percentage should sum up to 100%");
      setShowErrorModal(true);
    } else {
      setLoading(true);
      const formData = getFormData(nomineeList, assetId);
      if (firstPath === "edit-asset-nominee") {
        props.dispatch({
          type: "update_Asset_nominee",
          payload: formData,
          key: stateKey,
        });
      } else {
        props.dispatch({
          type: "add_Asset_nominee",
          payload: formData,
          key: stateKey,
        });
      }
    }
    //}
    // } else {
    //   setCaptchaError(true);
    // }
    
    
  };

  const submitHandler = () => {
    formik.validateForm();
    if (formik.isValid === true) {
      const { user_captcha, ...rest } = formik.values;
      const { percent, proof_detail } = formik.values;
      let newTotal = parseFloat(totalPercent) + parseFloat(percent);
      const findIndex = searchFor(proof_detail, nomineeList, "proof_detail");
      if (newTotal > 100) {
        setShowErrorModal(true);
        setErrorMsg("Distribution can't be more than 100");
      } else if (findIndex !== -1) {
        setErrorMsg("Nominee already added");
        setShowErrorModal(true);
      } else {
        setTotalPercent(newTotal);
        nomineeList.push(rest);
        setNomineeList(nomineeList);
        setSelectedNominee({});
        formik.resetForm();
      }
    }
  };
  const formik = useFormik({
    initialValues: initial_values,
    values: initial_values,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    setLoading(false);
    if (props.formResult.success == true) {
      setTimeout(function () {
        setNomineeList([]);
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });

        props.dispatch({
          type: "RESET_FORM",
          key: "AddNominee",
        });
        navigate(assetSummary);
      }, 1000);
    }
  }, [props.formResult]);

  const deleteNominee = (key) => {
    var nominee_list = nomineeList.filter((x, y) => {
      return y !== key;
    });
    setNomineeList(nominee_list);
    calculatePercent();
  };

  const editNominee = (key) => {
    setSelectedNominee(nomineeList[key]);
    deleteNominee(key);
  };

  const calculatePercent = () => {
    var newTotal = 0;
    nomineeList.length > 0 &&
      nomineeList.map((each) => {
        newTotal += parseFloat(each.percent);
      });
    setTotalPercent(newTotal);
  };
  

  const handleModalclose = () => {
    setShowErrorModal(false);
    setErrorMsg("");
  };

  useEffect(() => {
    if (props.newNomineeData.success == true) {
      props.dispatch({
        type: "get_nomineeList",
        key: "nomineeList",
      });
      setSelectedNominee({
        ...props.newNomineeData.data,
        percent: formik.values.percent,
      });
    }
  }, [props.newNomineeData.loading]);

  useEffect(() => {
    if (Object.keys(selectedNominee).length !== 0) {
      if (selectedNominee !== "new") {
        setInitial_values({
          ...formik.values,
          nominee_id: selectedNominee.id,
          name: selectedNominee.name,
          relation: selectedNominee.relation,
          proof_type: selectedNominee.proof_type,
          proof_detail: selectedNominee.proof_detail,
          percent: selectedNominee.percent,
          other_relation: selectedNominee.other_relation,
        });
      }
    } else {
      setInitial_values(initialValues);
    }
  }, [selectedNominee]);

  useEffect(() => {
    setLoading(false);
  }, [props.assetDetail.data]);
  const assetDetail = detail(props.assetDetail.data);


  return (
    <>
      {loading && <Loader />}
      {(selectedNominee == "new" || props.list.data.length==0) && (
        <AddNonmiee
          formik={formik}
          name="name"
          setSelectedNominee={setSelectedNominee}
        />
      )}
      {(selectedNominee !== "new" && props.list.data.length>0) && (
        <MainLayout>
          {showErrorModal === true && (
            <ErrorModal
              body={errorMsg}
              moreError=""
              handleClose={handleModalclose}
              type="error"
            />
          )}

          {props?.formResult?.error === true && (
            <CustomModal
              body={props.formResult.message}
              moreError={props.formResult.moreError}
              stateKey={stateKey}
              type="error"
            />
          )}
          {props?.formResult?.success === true && (
            <CustomModal
              body={customiseFormSuccessMsg(props.formResult.message)}
              stateKey={stateKey}
              type="success"
            />
          )}
          <div className="layout__headings">
            <div className="page-heading">
              {firstPath === "edit-asset-nominee"
                ? "Update Nominee"
                : "Add Nominee"}
            </div>
            {/* <div className="page-sub-heading">
              Asset: {props.masterAssetName?.data?.type}
              <Link
                className="editAssetLink"
                to={`/edit-asset/${props?.masterAssetName?.data.url_type}/${asset_id}`}
              >
                Edit
              </Link>
            </div> */}
            <div className="page-sub-heading">
              Asset: {detail(props.assetDetail.data)}
              <Link
                className="editAssetLink"
                to={`/edit-asset/${props.assetDetail.data?.master_asset?.url_type}/${asset_id}`}
              >
                Edit
              </Link>
            </div>
          </div>
          <div className="main-layout-cont">
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Form.Group className="col-md-4 col-sm-4 col-12 mb-3">
                    <Form.Label>Nominee Name</Form.Label>
                    <Field name="name">
                      {({ meta, field }) => {
                        return (
                          <>
                            <Form.Control
                              {...field}
                              type="text"
                              autoComplete="off"
                              isInvalid={!!meta.error && !!meta.touched}
                              value={field.value ? field.value : ""}
                            />
                            {meta.touched && meta.error && (
                              <Form.Control.Feedback type="invalid">
                                {meta.error}
                              </Form.Control.Feedback>
                            )}
                          </>
                        );
                      }}
                    </Field>
                    <MyNomineesDropdown
                      setSelectedNominee={setSelectedNominee}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4 col-sm-4 col-12  mb-3">
                    <Form.Label>ID Proof Type</Form.Label>
                    <Field name="proof_type">
                      {({ field, meta, form }) => {
                        return (
                          <IDProofDropdown
                            validation={{
                              ...field,
                              isInvalid: !!meta.error,
                              error: meta.error,
                              form: form,
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Form.Group>
                  <Form.Group className="col-md-4 col-sm-4 col-12  mb-3">
                    <Form.Label>ID Proof Detail</Form.Label>
                    <Field name="proof_detail">
                      {({ field, meta }) => (
                        <>
                          <Form.Control
                            {...field}
                            type="text"
                            autoComplete="off"
                            isInvalid={!!meta.error && !!meta.touched}
                            value={field.value ? field.value : ""}
                          />
                          {meta.touched && meta.error && (
                            <Form.Control.Feedback type="invalid">
                              {meta.error}
                            </Form.Control.Feedback>
                          )}
                        </>
                      )}
                    </Field>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="col-md-4 col-sm-4 col-12  mb-3">
                    <Form.Label>Relationship</Form.Label>
                    <RelationDropdown formik={formik} name="relation" />
                  </Form.Group>
                  <Form.Group className="col-md-4 col-sm-4 col-12  mb-3">
                    <Form.Label>Nominee Percentage</Form.Label>
                    <Field name="percent">
                      {({ field, meta }) => (
                        <>
                          <Form.Control
                            {...field}
                            type="text"
                            autoComplete="off"
                            isInvalid={!!meta.error && !!meta.touched}
                            value={field.value ? field.value : ""}
                          />
                          {meta.touched && meta.error && (
                            <Form.Control.Feedback type="invalid">
                              {meta.error}
                            </Form.Control.Feedback>
                          )}
                        </>
                      )}
                    </Field>
                  </Form.Group>
                  <Form.Group className="col-md-2 col-sm-2 col-12  mb-3">
                    {Object.keys(selectedNominee).length > 0 ? (
                      <button className="add-nominee-btn" type="submit">
                        Add
                      </button>
                    ) : (
                      <button
                        className="add-nominee-btn"
                        onClick={() => {
                          setSelectedNominee("new");
                        }}
                        type="button"
                      >
                        Add New
                      </button>
                    )}
                    {/* 
                      <button className="add-nominee-btn" type="submit">
                        Add
                      </button>
                     */}
                  </Form.Group>
                </Row>
              </Form>
            </FormikProvider>
            <div className="mt-5 mb-3">
              <Table responsive className="nomineeTable">
                <thead>
                  <tr>
                    <td>Nominee Name</td>
                    <td>Percentage</td>
                    <td>Relation</td>
                    <td>ID Proof</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {nomineeList.map((each, index) => {
                    return (
                      <tr key={`nom${index}`}>
                        <td>{each.name}</td>
                        <td>{each.percent}%</td>
                        <td>
                          {each.relation !== "Other"
                            ? each.relation
                            : each.other_relation}
                        </td>
                        <td>{each.proof_detail}</td>
                        <td>
                          <EditIconWithToolTip
                            link={false}
                            handleClick={() => {
                              editNominee(index);
                            }}
                          />
                          <DeleteIconWithToolTip
                            handleClick={() => {
                              deleteNominee(index);
                            }}
                          />
                          {/* <img
                            src={pencilIcon}
                            className="editIcon"
                            onClick={() => editNominee(index)}
                          /> 
                          <img
                            src={dustbinIcon}
                            className="dltIcon"
                            onClick={() => deleteNominee(index)}
                          />*/}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot
                  className={(totalPercent !== 100) == true ? "error" : ""}
                >
                  <tr>
                    <td>Total Percentage</td>
                    <td>{totalPercent}%</td>
                  </tr>
                </tfoot>
              </Table>
            </div>
            <div className="d-flex align-items-end flex-row-reverse ">
              <button
                className="mt-auto form-button button"
                onClick={SaveForm}
                type="button"
                disabled={props?.list.data.length == 0 && true}
              >
                {firstPath === "edit-asset-nominee" ? "Update" : "Save"}
              </button>
            </div>
            {firstPath === "edit-asset-nominee" && (
              <Link to={assetSummary}>
                <button className="button" type="button">
                  Skip Nominee Update
                </button>
              </Link>
            )}
          </div>
        </MainLayout>
      )}
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[stateKey] },
    masterAssetName: { ...state?.GetData?.[masterAssetDetailKey] },
    assetDetail: { ...state?.GetData?.[AssetDetailKey] },
    assetNominee: { ...state?.GetData?.[getNomineekey] },
    newNomineeData: { ...state?.Forms?.AddNominee },
    list: { ...state?.GetData?.[getListkey] },
  };
})(AddAssetNominee);
