import React, { useState, useEffect,useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import login from "../../../assets/images/login.png";
import { Field, useFormik, FormikProvider } from "formik";
import {
  forgotpassword,
  register,
  accountSetting,
  personalDetail,
} from "../../../route";

import { initialValues, validationSchema } from "./util";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import Layout3 from "../../layout/Layout3";
import PassToggle from "../common/PassToggle";
import Captcha from "../common/Captcha";

const stateKey = "Login";

const Login = (props) => {
  const [captchaError, setCaptchaError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const captchaRef = useRef(null)


  let data = sessionStorage.getItem("data");
  const sessionData = JSON.parse(data);
  console.log('sessionData',sessionData);


  const submitHandler = (values) => {
    if (captchaRef.current.getValue().length >0) {

      if (formik.isValid === true) {
        setLoading(true);
        props.dispatch({
          type: "User_login",
          payload: {
            data: {
              email: values.email,
              password: values.password,
              captcha_token:captchaRef.current.getValue(),
            },
          },
          key: stateKey,
        });
      }
    } else {
        setCaptchaError(true);
    }    
  };
  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    setLoading(false);

    if (props.formResult.success == true) {
      setTimeout(function () {
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
        //====navigate function here can't be used because with navigate page will not refresh and auth token will not set in axios
        
          window.location.href = accountSetting
          //navigate(accountSetting);
      }, 2000);
    }else{
      captchaRef.current.reset();
    }
  }, [props.formResult]);

  
  return (
    <Layout3>
      {loading && <Loader />}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {props?.formResult?.success === true && (
        <>
          <CustomModal
            body="You have successfully logged in with the mentioned credentials."
            stateKey={stateKey}
            type="success"
          />
        </>
      )}
      <div className="main-layout d-flex">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-md-start text-center">
          <img src={login} className="login-img" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <h1 className="login-title">Welcome Back,</h1>
          <div className="mt-3 mb-3">
            <span className="reg-now me-1">Not a member yet? </span>
            <Link to={register} className="reg-now-link yellowColor">
              Register
            </Link>
          </div>

          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} className="login-form">
              <Form.Group className="col-md-10 mb-2">
                <Field name="email">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="email"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Email ID"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>

              <Form.Group className="col-md-10 mb-2 position-relative">
                <PassToggle name="password" placeholder="Password" />
              </Form.Group>
              <div className="my-2">
              <Captcha 
              reference={captchaRef}
              captchaError = {captchaError}
              stateKey={stateKey}
              />
              </div>
              <Row className="btnRoww text-sm-center text-center text-md-left">
                <div className="col-md-6 col-sm-12 mt-2 mb-4">
                  <button className="button mt-auto" type="submit">
                    Log In
                  </button>
                </div>
                <div className="col-md-4 col-sm-12">
                  <Link
                    to={forgotpassword}
                    className="reg-now-link yellowColor"
                  >
                    Forgot password?
                  </Link>
                </div>
              </Row>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </Layout3>
  );
};

export default connect((state) => {
  return { formResult: { ...state?.Forms?.[stateKey] } };
})(Login);
