import * as Yup from "yup";
import { passwordRegex } from "../../../constants";

export const initialValues = {
  
  email: "",
  token: "",
  password: "",
  password_confirmation: "",
};

export const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "*New Password must have at least 8 characters")
    .max(15, "*New Password can't exceed then 15 characters")
    .matches(
      passwordRegex,
      "*Password must fullfill requirements,refer to the notes "
    )
    .required("*New Password  is required"),
  password_confirmation: Yup.string()
    .min(8, "*Confirm Password  must have at least 8 characters")
    .max(15, "*Confirm Password can't exceed then 15 characters")
    .matches(
      passwordRegex,
      "*Password must fullfill requirements,refer to the notes "
    )
    .required("*Confirm Password is required")
    .oneOf(
      [Yup.ref("password"), null],
      "New Password and Confirm Passwords don't match"
    ),
  //   Yup.string()
  //     .min(6, "*Captcha must have at least 6 characters")
  //     .max(6, "*Captcha can't be longer than 6 characters")
  //     .required("*Captcha is required"),
});
