import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../common/Loader/Loader";
import MainLayout from "../../layout/Mainlayout";
import { Card } from "react-bootstrap";
import { array_chunk } from "../../../util";
import styles from "../../../assets/Tooltip.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import AssetTooltip from "./AssetToolTip";
import GobackArrow from "../common/GobackArrow";
import { assetSummary } from "../../../route";

const getKey = "masterAssetsList";

const SelectAsset = (props) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    props.dispatch({
      type: "get_master_assets",
      key: getKey,
    });
  }, []);

  useEffect(() => {
    setLoading(false);
    props.getData.data.length > 0 &&
      setRows(array_chunk(props.getData.data, 4));
  }, [props.getData.data]);

  return (
    <MainLayout>
      {loading && <Loader />}

      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          Add Assets{" "}
          <span
            className={`${styles.tooltip}`}
            data-tip="This is where you specify which assets you have and how you want to split them between your beneficiaries"
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
        </div>
        <div className="page-sub-heading">
          Select an asset from list below, we will help you add its details in
          easy steps.
          <br /> You will come back to this screen again to keep adding more
          assets
        </div>
      </div>

      <div className="main-layout-cont">
        <div className="selectAssetContainer  justify-content-md-start  justify-content-center">
          {props.getData.data.length > 0 &&
            props.getData.data.map((each, e) => {
              return each.tooltip?.length > 0 ? (
                <Fragment key={`card${e}`}>
                  <Card
                    className="text-center me-lg-5 me-md-5 me-sm-5 me-2 p-0 asset-box"
                    data-tip={each.tooltip}
                  >
                    <Link
                      to={`/add-asset/${each.url_type}`}
                      className=""
                      key={each.url_type}
                    >
                      <Card.Body>
                        <img src={each.icon} className="asset-icon" />
                        <div className="asset-name">{each.type}</div>
                      </Card.Body>
                    </Link>
                  </Card>
                  <AssetTooltip />
                </Fragment>
              ) : (
                <Fragment key={`card${e}`}>
                  <Card className="text-center me-lg-5 me-md-5 me-sm-5 me-2 p-0 asset-box">
                    <Link
                      to={`/add-asset/${each.url_type}`}
                      className=""
                      key={each.url_type}
                    >
                      <Card.Body>
                        <img src={each.icon} className="asset-icon" />
                        <div className="asset-name">{each.type}</div>
                      </Card.Body>
                    </Link>
                  </Card>
                </Fragment>
              );
            })}
        </div>
        <div className="d-flex flex-row-reverse">
          {/* <button type="button" className="button">
            Continue
          </button> */}
          <Link to={assetSummary} className="button">
            Continue
          </Link>
        </div>
      </div>
    </MainLayout>
  );
};

export default connect((state) => {
  return { getData: { ...state.GetData[getKey] } };
})(SelectAsset);
