import { Navbar, Nav } from "react-bootstrap";
import Logout from "../components/common/Logout";
import { accountSetting, login } from "../../route";
import hamburger from "../../assets/images/hamburger.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MakeWillBtn from "../components/common/MakeWillBtn";
import Logo from "../components/common/Logo";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = (props) => {
  const { showWillBtn } = props;
  const navigate = useNavigate();
  const firstPath = window.location.pathname.split("/")[1];

  const exclude_shadow = [
    "login",
    "register",
    "forgot-password",
    "reset-password",
    "support",
    "faq",
    "blogs",
    "blog-detail",
    "verify_user",
  ];

  const exlude_loginlink = ["login", "register"];

  let data = sessionStorage.getItem("data");
  const dataobj = JSON.parse(data);
  const [dropboxDisplay, setDropboxDisplay] = useState(false);
  const handleToggle = () => {
    setDropboxDisplay(!dropboxDisplay);
  };

  const goToAccountSetting = () => {
    navigate(accountSetting);
  };

  return (
    <Navbar
      className={`top-bar ${
        firstPath !== "" && !exclude_shadow.includes(firstPath)
          ? `navbar-shadow`
          : ``
      }`}
    >
      <Link to="/" className="col-lg-4 col-md-4 col-sm-4 col-4">
        <Logo />
      </Link>
      <div className="top_nav_head_right lg-div col-lg-8 col-md-8 col-sm-8 col-8">
        {showWillBtn !== false &&
          dataobj !== null &&
          dataobj?.currentWillProgress !== "unpaid" && (
            <div className="makeWillBtnDiv flex-row-reverse col-4 ">
              <MakeWillBtn />
            </div>
          )}

        <div className="top_nav_head_user text-end col-7">
          {dataobj == null ? (
            <>
              {!exlude_loginlink.includes(firstPath) && (
                <div className="user-drop-down">
                  <Link className="header-loginLink" to={login}>
                    Login
                  </Link>
                </div>
              )}
            </>
          ) : (
            <div
              className="user-drop-down float-end text-center"
              onClick={() => {
                handleToggle();
              }}
            >
              <div className="user_name">
                Hello <span className="down-vector"></span> {dataobj?.name},
              </div>
              <ul
                className="drop-down-box"
                style={{ display: dropboxDisplay == true ? "block" : "none" }}
              >
                <li
                  onClick={() => {
                    goToAccountSetting();
                  }}
                >
                  Account
                </li>
                <Logout />
              </ul>
            </div>
          )}
        </div>
        {props.isOpen ? (
          <div
            className="top_nav_head_ham_menu m-sm-1 m-3 col-1 text-center"
            onClick={() => {
              props.setIsOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faXmark} style={{ color: "#000" }} />
          </div>
        ) : (
          <div
            className="top_nav_head_ham_menu m-sm-1 m-auto col-1 text-center"
            onClick={() => {
              props.setIsOpen(true);
            }}
          >
            <img src={hamburger} alt="menu" />
          </div>
        )}
      </div>

      <div className="top_nav_head_rightt md-div col-lg-8 col-md-8 col-sm-8 col-8">
        <div className="col-12 d-flex">
          <div className="top_nav_head_user col-10 text-center">
            {dataobj == null ? (
              <>
                {!exlude_loginlink.includes(firstPath) && (
                  <div className="user-drop-down">
                    <Link className="header-loginLink" to={login}>
                      Login
                    </Link>
                  </div>
                )}
              </>
            ) : (
              <div
                className="user-drop-down float-end  text-center"
                onClick={() => {
                  handleToggle();
                }}
              >
                <div className="user_name">
                  Hello <span className="down-vector"></span>
                </div>
                <ul
                  className="drop-down-box"
                  style={{ display: dropboxDisplay == true ? "block" : "none" }}
                >
                  <li
                    onClick={() => {
                      goToAccountSetting();
                    }}
                  >
                    Account
                  </li>
                  <Logout />
                </ul>
              </div>
            )}
          </div>

          {props.isOpen ? (
            <div
              className="top_nav_head_ham_menu m-sm-1 m-auto col-2 text-center"
              onClick={() => {
                props.setIsOpen(false);
              }}
            >
              <FontAwesomeIcon icon={faXmark} style={{ color: "#000" }} />
            </div>
          ) : (
            <div
              className="top_nav_head_ham_menu m-sm-1 m-auto col-2 text-end"
              onClick={() => {
                props.setIsOpen(true);
              }}
            >
              <img src={hamburger} alt="menu" />
            </div>
          )}
        </div>
        <div className="col-10 text-end" style={{ color: "#000" }}>
          {dataobj !== null && dataobj?.name}
        </div>
      </div>
    </Navbar>
  );
};

export default Header;
