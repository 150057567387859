const SubmitBtn = (props) => {
  return (
    <div className="d-flex align-items-end flex-row-reverse ">
      <button {...props} className="mt-auto form-button button" type="submit">
        {props.text}
      </button>
    </div>
  );
};

export default SubmitBtn;
