import * as Yup from "yup";
import { notOnlyWhiteSpace, alphanumericWithSpecial } from "../../../constants";

export const initialValues = {
  name: "",
  relation: "",
  proof_type: "",
  proof_detail: "",
  dob: "",
  other_relation: "",
  
};

export const gaurd_init = {
  g_name: "",
  g_relation: "",
  g_proof_type: "",
  g_proof_detail: "",
  g_dob: "",
  g_address: "",
  g_pincode: "",
  g_country: "",
  g_state: "",
  g_city: "",
};

export const nominee_validation = {
  name: Yup.string()
    .required("*Name is required")
    .min(2, "*Name must have at least 2 characters")
    .max(40, "*Name can't be longer than 41 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  dob: Yup.string().required("*This is required"),
  relation: Yup.string()
    .max(40, "*This can't be longer than 41 characters")
    .required("*This is required"),
  proof_type: Yup.string()
    .max(40, "*This can't be longer than 41 characters")
    .required("*This is required"),
  proof_detail: Yup.string()
    .required("*This is required")
    .max(40, "*This can't be longer than 41 characters")
    .matches(alphanumericWithSpecial, "*Invalid ID Proof detail"),

  other_relation: Yup.string().when("relation", {
    is: "Other",
    then: Yup.string()
      .required("*This is required")
      .max(40, "*This can't be longer than 41 characters")
      .matches(
        notOnlyWhiteSpace,
        "*Only Alphabets,Numbers and Spaces are allowed "
      ),
  }),

};

export const gaurdian_validation = {
  g_name: Yup.string()
    .required("*Name is required")
    .min(2, "*Name must have at least 2 characters")
    .max(40, "*Name can't be longer than 41 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  g_dob: Yup.string().required("*This is required"),
  g_relation: Yup.string()
    .max(40, "*This can't be longer than 41 characters")
    .required("*This is required"),
  g_proof_type: Yup.string()
    .max(40, "*This can't be longer than 41 characters")
    .required("*This is required"),
  g_proof_detail: Yup.string()
    .required("*This is required")
    .max(40, "*This can't be longer than 41 characters")
    .matches(alphanumericWithSpecial, "*Invalid ID Proof detail"),
  g_address: Yup.string()
    .required("*Address is required")
    .min(2, "*Address Name must have at least 2 characters")
    .max(100, "*Address can't be longer than 100 characters"),


};

export const getFormData = (values) => {
  let formData = new FormData();
  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  return formData;
};
