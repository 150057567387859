import axios from "../interceptor";
import { call, put, takeEvery } from "redux-saga/effects";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../constants";

function addForm(action) {
  return axios({
    url: api_base + "/customer/update_personal_detail",
    method: "post",
    data: action.payload,
  });
}

function* Form_G(action) {
  try {
    var result = yield call(addForm, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: result?.response?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function getForm(action) {
  return axios({
    url: api_base + "/customer/get_personal_detail",
    method: "get",
  });
}

function* get_Form_G(action) {
  try {
    var result = yield call(getForm, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}
export function* PersonalDetail() {
  yield takeEvery("personal_detail", Form_G);
  yield takeEvery("get_personal_detail", get_Form_G);
}
