import { Form } from "react-bootstrap";
import { Field, FormikProvider, useFormik } from "formik";
import { connect } from "react-redux";
import { initialValues, validationSchema } from "./util";
import { Link } from "react-router-dom";
import {
  verify_email_otp,
  resend_email_otp,
} from "../../../../redux/AllThunk/VerifyEmail";
import { useNavigate } from "react-router-dom";
import { accountSetting } from "../../../../route";
import { useEffect } from "react";
import CustomModal from "../CustomModal";

const stateKey = "verify_email_otp";
const resendKey = "resend_email_otp";

const EmailOTPForm = (props) => {
  const navigate = useNavigate();

  const submitHandler = (values) => {
    
    if (formik.isValid === true) {
      props.setLoading(true);

      props.dispatch(
        verify_email_otp({
          payload: {
            otp: values.otp,
          },
          key: stateKey,
        })
      );
    }
    // } else {
    //   setCaptchaError(true);
    // }
  };
  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  const resendOTP = () => {
    props.setLoading(true);
    props.dispatch(resend_email_otp({ key: resendKey }));
  };

  useEffect(() => {
    props.setLoading(false);
    setTimeout(function () {
      props.dispatch({
        type: "RESET_GET",
        key: resendKey,
      });
    }, 2000);
  }, [props.ResendForm.data]);

  return (
    <>
      {props?.ResendForm?.error === true && (
        <CustomModal
          body={props.ResendForm.error_msg}
          moreError={props.ResendForm.error_msg}
          stateKey={resendKey}
          type="error"
        />
      )}
      {props?.ResendForm?.success === true && (
        <CustomModal
          body={props.ResendForm.data}
          stateKey={resendKey}
          type="success"
        />
      )}
      <div className="col-lg-6 col-md-6 col-sm-12 ps-md-4">
        <h1 className="login-title">Verify Your Email Address</h1>
        <div className="mt-3 mb-3">
          <p className="reg-now">Check your email for OTP</p>
          <span className="reg-now me-1">Didn't received mail? </span>
          <Link to="#" className="reg-now-link yellowColor" onClick={resendOTP}>
            Resend
          </Link>
        </div>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} className="login-form">
            <Form.Group className="col-md-9">
              <Field name="otp">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="text"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      placeholder="Please enter OTP here"
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
            </Form.Group>

            <div className="col-md-9 mt-4 text-center">
              <button className="button mt-auto" type="submit">
                Verify
              </button>
            </div>

            {/* <div className="mt-3 col-md-9 text-center">
              <div className="yellowColor">OR</div>
              <button
                className="button mt-auto skipandgobtn"
                type="button"
                onClick={() => {
                  navigate(accountSetting);
                }}
                style={{ maxWidth: "15rem" }}
              >
                Skip and Go to dashboard
              </button>
            </div> */}
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
export default connect((state) => {
  return {
    ResendForm: { ...state.GetData[resendKey] },
  };
})(EmailOTPForm);
