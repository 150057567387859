import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../common/Loader/Loader";
import { selectAsset, addExecutor, addyourwish } from "../../../route";
import { detail } from "./util";
import { encrypt } from "../../../util";
import DeleteConfirmation from "../common/DeleteConfirmation";
import MainLayout from "../../layout/Mainlayout";
import { Row, Table } from "react-bootstrap";
import dustbinIcon from "../../../assets/images/dustbin.png";
import pencilIcon from "../../../assets/images/pencil.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import GobackArrow from "../common/GobackArrow";
import SharePopup from "./SharePopup";
import EditIconWithToolTip from "../common/EditIconWithToolTip";
import DeleteIconWithToolTip from "../common/DeleteIconWithToolTip";
import CustomModal from "../common/CustomModal";

const getKey = "assetSummary";
const deleteAssetKey = "deleteAsset";
const shareOnEmail = "shareOnEmail";

const AssetSummary = (props) => {
  const [loading, setLoading] = useState(true);
  const [deleteAssetId, setDeleteAssetId] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getAssetSummay();

    props.dispatch({
      type: "RESET_GET",
      key: "deleteDraft",
    });
  }, []);

  const getAssetSummay = () => {
    props.dispatch({
      type: "get_asset_summary",
      key: getKey,
    });
  };

  useEffect(() => {
    setLoading(false);
  }, [props.getData.data]);

  const proceedDelete = () => {
    props.dispatch({
      type: "deleteAsset",
      payload: { asset_id: deleteAssetId },
      key: deleteAssetKey,
    });
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (props.deleteAsset.success == true) {
      getAssetSummay();
      //alert("asset Deleted successfully");
      props.dispatch({
        type: "RESET_FORM",
        key: deleteAssetKey,
      });
      setDeleteAssetId(0);
      setShowDeleteModal(false);
    }
  }, [props.deleteAsset]);

  let i = 0;
  let assetDetail;

  useEffect(() => {
    if (props.emailShare.success == true || props.emailShare.error == true) {
      setShowSharePopup(false);
    }
  }, [props.emailShare]);

  return (
    <MainLayout>
      {showSharePopup && <SharePopup sharePopup={setShowSharePopup} />}
      {props?.emailShare?.error === true && (
        <CustomModal
          body={props.emailShare.message}
          moreError={props.emailShare.moreError}
          stateKey={shareOnEmail}
          type="error"
        />
      )}
      {props?.emailShare?.success === true && (
        <CustomModal
          body={props.emailShare.message}
          stateKey={shareOnEmail}
          type="success"
        />
      )}
      <DeleteConfirmation
        showConfirmModal={showDeleteModal}
        proceedSubmit={proceedDelete}
        cancelSubmit={cancelDelete}
      />
      {loading && <Loader />}
      <div className="layout__headings">
        <Row>
          <div className="col-md-6 col-sm-6 col-6">
            <div className="page-heading">
              <GobackArrow goto={selectAsset} /> Summary of all assets{" "}
            </div>
          </div>
          <div className="col-md-6 d-flex flex-row-reverse p-0  col-sm-6 col-6">
            {/* <Link to={selectAsset} className="addAssetBtn2 ms-1">
              Add Asset
            </Link> */}

            <Link to={selectAsset} className="addAssetBtn ms-1">
              Add Asset
            </Link>
            <button
              type="button"
              className="btn-2 me-1"
              onClick={() => {
                setShowSharePopup(true);
              }}
            >
              <FontAwesomeIcon icon={faShareNodes} className="me-2 shareIcon" />
              Share
            </button>
          </div>
        </Row>
      </div>
      <div className="main-layout-cont">
        <div className="mb-3">
          <div style={{ overflowX: "scroll" }}>
            <Table className="assetSummaryTable">
              <thead>
                <tr className="d-flex">
                  <td className="col-md-1 col-sm-2">Sr. No</td>
                  <td className="col-md-3 col-sm-4">Asset</td>
                  <td className="col-md-3 col-sm-4">Beneficiaries</td>
                  <td className="col-md-2 col-sm-3">Percentage</td>
                  <td className="col-md-2 col-sm-3">Relation</td>
                  <td className="col-md-1 col-sm-2"></td>
                </tr>
              </thead>
              <tbody>
                {props.getData?.data?.data?.length > 0 &&
                  props.getData.data.data.map((each, index) => {
                    assetDetail = detail(
                      each.asset_detail,
                      each.master_asset.url_type
                    );
                    i++;
                    return (
                      <tr key={`row${index}`} className="d-flex">
                        <td className="col-md-1 col-sm-2">{i}</td>
                        <td className="col-md-3 col-sm-4">
                          <b>{each.master_asset.type}</b>
                          <div className="assetDetailCol">
                            {assetDetail.map((e, i) => (
                              <Fragment key={i}>
                                {e}
                                <br />
                              </Fragment>
                            ))}
                          </div>
                        </td>
                        <td className="col-md-3 col-sm-4">
                          {each.nominees.map((e, i) => (
                            <Fragment key={i}>
                              {e.nominee_detail.name}
                              <br />
                            </Fragment>
                          ))}
                        </td>
                        <td className="col-md-2 col-sm-3">
                          {each.nominees.map((e, i) => (
                            <Fragment key={i}>
                              {e.percent}%
                              <br />
                            </Fragment>
                          ))}
                        </td>
                        <td className="col-md-2 col-sm-3">
                          {each.nominees.map((e, i) => (
                            <Fragment key={i}>
                              {e.nominee_detail.relation !== "Other"
                                ? e.nominee_detail.relation
                                : e.nominee_detail.other_relation}
                              <br />
                            </Fragment>
                          ))}
                        </td>
                        <td className="col-md-1 col-sm-2">
                          <EditIconWithToolTip
                            text="Edit this entry"
                            link={`/edit-asset/${
                              each.master_asset.url_type
                            }/${encrypt(each.id)}`}
                          />
                          <DeleteIconWithToolTip
                            handleClick={() => {
                              setDeleteAssetId(each.id);
                              setShowDeleteModal(true);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="d-flex align-items-end flex-row-reverse">
          <button
            className="mt-auto form-button button"
            onClick={() => {
              navigate(addyourwish);
            }}
            type="button"
          >
            Continue
          </button>
        </div>
      </div>
    </MainLayout>
  );
};

export default connect((state) => {
  return {
    getData: { ...state.GetData[getKey] },
    deleteAsset: { ...state.Forms[deleteAssetKey] },
    emailShare: { ...state?.Forms?.[shareOnEmail] },
  };
})(AssetSummary);
