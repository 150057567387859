import { Row, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useFormik, FormikProvider, Field } from "formik";
import {
  initialValues_popup,
  validationSchema_popup,
  getNRIFormData,
} from "./util";
import { connect } from "react-redux";
import CustomModal from "../common/CustomModal";
import { customiseFormSuccessMsg } from "../../../util";
import Loader from "../common/Loader/Loader";
import ContactForm from "./ContactForm";

const stateKey = "contactForm";
const NRIPopup = (props) => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    props.closeHandler(false);
    setShow(false);
  };

  const submitHandler = (values) => {
    

    if (formik.isValid === true) {
      setShow(false);
      setLoading(true);
      const formData = getNRIFormData(values);
      props.dispatch({
        type: "contact_form",
        payload: formData,
        key: stateKey,
      });
    }
    // } else {
    //   setCaptchaError(true);
    // }
    
    
  };

  const formik = useFormik({
    initialValues: initialValues_popup,
    values: initialValues_popup,
    validationSchema: validationSchema_popup,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (props.formResult.error === true || props.formResult.success === true) {
      setLoading(false);
      setShow(false);
    }
    if (props.formResult.success === true) {
      setTimeout(() => {
        props.closeHandler(false);
        //formik?.resetForm();
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
      }, 3000);
    }
  }, [props.formResult.success, props.formResult.error]);

  return (
    <>
      {/* {loading && <Loader />} */}

      {loading && ""}

      {props?.formResult?.success === true && (
        <CustomModal
          body={customiseFormSuccessMsg(props.formResult.message)}
          stateKey={stateKey}
          type="success"
        />
      )}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      <Modal
        show={show}
        centered
        className="executorPopup"
        onHide={() => {
          if (props?.restrictClose) {
            return false;
          } else {
            handleClose();
          }
        }}
        size="xl"
        backdrop="static"
      >
        <FontAwesomeIcon
          icon={faCircleXmark}
          onClick={handleClose}
          className="modalCloseIcon"
        />
        <Modal.Body>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} className="main-layout-cont">
              <div className="col-md-12 mb-2">
                <b>Looks like you need a helping hand</b>
              </div>
              <p>
                Sorry, Willyourwish is currently built to handle Indian Laws
                only. This means we can only create will drafts for Indian
                Citizens. If you are an OCI/PIO and would like to know about
                making a Will for Indian Assets, please get in touch with us by
                filling up the Form below. We will get you in touch with a Legal
                Expert to discuss your queries.
              </p>
              <ContactForm />
            </Form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(NRIPopup);
