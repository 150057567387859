import * as Yup from "yup";

export const initialValues = { email: "", password: "",  };
export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  password: Yup.string().required("Password is required"),

});
