import Header from "./Header";
import { Container } from "react-bootstrap";
import BurgerMenuPage from "../components/BurgerMenuPage";
import { useState } from "react";
import WebFooter from "../components/WebFooter";

const MasterLayout = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen == false && (
        <Container
          fluid={props.fluid == true ? true : false}
          className={props.containerClass}
        >
          <Header
            showWillBtn={props.showWillBtn}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
          {props.children}
          {props.webFooter && <WebFooter />}
        </Container>
      )}
      <BurgerMenuPage setIsOpen={setIsOpen} isOpen={isOpen} />
    </>
  );
};

export default MasterLayout;
