import ContactForm from "../ContactForm";
import { Row } from "react-bootstrap";

const HelpDeskSection = () => {
  return (
    <Row>
      <div className="col-md-5">
        <h2 className="why-trust-head">
          Not sure about <br /> anything, talk to us
        </h2>
        <h4 className="why-trust-head head2 d-none">
          Not sure about anything, talk to us
        </h4>
        <p>
          We understand your problems closely and that’s the reason we have
          everthing covered. You can completely trust us on lawyers,
          legalalities, process.
        </p>
      </div>
      <div className="col-md-7" style={{ zIndex: 1 }}>
        <ContactForm />
      </div>
    </Row>
  );
};

export default HelpDeskSection;
