import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import { customiseFormSuccessMsg } from "../../../util";
import MainLayout from "../../layout/Mainlayout";
import DragNDrop from "../common/DragNDrop";
import GobackArrow from "../common/GobackArrow";
import { view_draft } from "../../../route";

const stateKey = "uploadWill";

const UploadWill = (props) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <MainLayout>
        {props?.formResult?.success === true && (
          <CustomModal
            body={customiseFormSuccessMsg(props.formResult.message)}
            stateKey={stateKey}
            type="success"
          />
        )}
        {props?.formResult?.error === true && (
          <CustomModal
            body={props.formResult.message}
            moreError={props.formResult.moreError}
            stateKey={stateKey}
            type="error"
          />
        )}
        {loading && <Loader />}
        <div className="layout__headings">
          <div className="page-heading">
            <GobackArrow goto={view_draft} />
            Upload the document
          </div>
        </div>
        <div className="main-layout-cont">
          <p className="col-md-8">
            Please upload the signed copy of the will including the Witness
            signatures. Post which you can access the will at anytime you want.
            You can also email the copy to anybody you want to.
          </p>
          <h3 className="layout__headings">Instructions/Next Steps:</h3>
          <div className="col-md-9">
            <ol className="pay-success-ol">
              <li>Sign the downloaded copy of the will.</li>
              <li>Have the witnesses sign the will too.</li>
              <li>Mention the date on the will.</li>
              <li>
                Upload the signed copy on My Account section on
                willyourwish.com.
              </li>
              <li>You can email the file to whosoever you want to.</li>
            </ol>
          </div>
          <button
            onClick={() => {
              setShowModal(true);
            }}
            className="mt-auto button"
          >
            Upload
          </button>
        </div>
      </MainLayout>
      {showModal && <DragNDrop handleClose={handleClose} />}
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[stateKey] },
  };
})(UploadWill);
