import * as Yup from "yup";
import { numeric } from "../../../../constants";

export const initialValues = {
  otp: "",
};

export const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .required("*This is required")
    .matches(numeric, "*Only Numbers are allowed")
    .test(
      "len",
      "*Must be exactly 6 digits",
      (val) => val !== undefined && val !== null && val.length === 6
    ),
});
