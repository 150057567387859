import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./views/components/Login";
import Home from "./views/components/Home";
import Register from "./views/components/Register";
//import PersonalDetail from "./views/components/PersonalDetail/original-index";
import PersonalDetail from "./views/components/PersonalDetail/index";
import SelectAsset from "./views/components/SelectAsset";
import AddAsset from "./views/components/AddAsset/AddAsset";
import ServerError from "./views/components/ServerError";
import AddNominee from "./views/components/AddNominee";
import AddAssetNominee from "./views/components/AddAssetNominee";
import AssetSummary from "./views/components/AssetSummary";
import ViewDraft from "./views/components/ViewDraft";
import PaymentPlan from "./views/components/PaymentPlan";
import PaymentSuccess from "./views/components/PaymentSuccess";
import UploadWill from "./views/components/UploadWill";
import AccountSetting from "./views/components/AccountSetting";
import WillUploadSuccess from "./views/components/WillUploadSuccess";
import UserCategory from "./views/components/UserCategory";
import AddExecutor from "./views/components/AddExecutor";
import ExecutorList from "./views/components/ExecutorList";
import MyNominees from "./views/components/MyNominees";
import Aadhar_page from "./views/components/Aadhar_page";
import OtherLinksPage from "./views/design/OtherLinksPage";
import Design from "./views/design/index";
import ForgotPassword from "./views/components/ForgotPassword";
import ResetPassword from "./views/components/ResetPassword";
import Blogs from "./views/components/Blogs";
import Support from "./views/components/Support";
import Faq from "./views/components/Faq";
import Addyourwish from "./views/components/Addyourwish";
import VerifyEmail from "./views/components/VerifyEmail";
import Auth from "./views/components/AuthRoute";
import Private from "./views/components/PrivateRoute";
import VerifyUser from "./views/components/VerifyUser";
import Page404 from "./views/components/Page404";
import ViewDraftPage from "./views/components/ViewDraft/page";
import externalJs from "../src/externalJs";
import ScrollToTop from "./views/components/common/ScrollToTop";
import Blog_detail from "./views/components/Blogs/Blog_detail";
import WillType from "./views/components/WillType/index";

import {
  login,
  register,
  personalDetail,
  selectAsset,
  servererror,
  addAsset,
  editAsset,
  addNominee,
  addNomineeToAsset,
  assetSummary,
  editAssetNominee,
  viewDraft,
  paymentPlans,
  paymentSuccess,
  uploadWill,
  accountSetting,
  willUploadSuccess,
  userCategory,
  addExecutor,
  executorList,
  myNominees,
  aadharpage,
  design,
  links,
  forgotpassword,
  resetPassword,
  faq,
  support,
  blogs,
  addyourwish,
  email_verify_url,
  verify_user,
  view_draft,
  blog_detail,
  willtype
} from "./route";
import IdleTimerContainer from "./views/components/common/IdleTimerContainer";

// export function AddLibrary(urlOfTheLibrary) {
//   const script = document.createElement("script");
//   script.src = urlOfTheLibrary;
//   script.async = true;
//   document.body.appendChild(script);
// }

function App() {
  let data = sessionStorage.getItem("data");
  const dataobj = JSON.parse(data);
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        {dataobj !== null && <IdleTimerContainer />}
        <Routes>
          <Route
            path={login}
            element={
              <Auth>
                <Login />
              </Auth>
            }
          />
          <Route
            path={register}
            element={
              <Auth>
                <Register />
              </Auth>
            }
          />
          <Route
            path={forgotpassword}
            element={
              <Auth>
                <ForgotPassword />
              </Auth>
            }
          />
          <Route path={resetPassword} element={<ResetPassword />} />
          <Route
            path={personalDetail}
            element={
              <Private>
                <PersonalDetail />
              </Private>
            }
          />
          <Route
            path={verify_user}
            element={
              <Private>
                <VerifyUser />
              </Private>
            }
          />
          <Route
            path={selectAsset}
            element={
              <Private>
                <SelectAsset />
              </Private>
            }
          />
          <Route
            path={addyourwish}
            element={
              <Private>
                <Addyourwish />
              </Private>
            }
          />
          <Route
            path={addAsset}
            element={
              <Private>
                <AddAsset />
              </Private>
            }
          />
          <Route
            path={editAsset}
            element={
              <Private>
                <AddAsset />
              </Private>
            }
          />
          <Route
            path={addNominee}
            element={
              <Private>
                <AddNominee />
              </Private>
            }
          />
          <Route
            path={addNomineeToAsset}
            element={
              <Private>
                <AddAssetNominee />
              </Private>
            }
          />
          <Route
            path={editAssetNominee}
            element={
              <Private>
                <AddAssetNominee />
              </Private>
            }
          />
          <Route
            path={assetSummary}
            element={
              <Private>
                <AssetSummary />
              </Private>
            }
          />
          <Route
            path={viewDraft}
            element={
              <Private>
                <ViewDraft />
              </Private>
            }
          />
          <Route
            path={paymentPlans}
            element={
              <Private>
                <PaymentPlan />
              </Private>
            }
          />
          <Route
            path={paymentSuccess}
            element={
              <Private>
                <PaymentSuccess />
              </Private>
            }
          />
          <Route
            path={uploadWill}
            element={
              <Private>
                <UploadWill />
              </Private>
            }
          />
          <Route
            path={accountSetting}
            element={
              <Private>
                <AccountSetting />
              </Private>
            }
          />
          <Route
            path={willUploadSuccess}
            element={
              <Private>
                <WillUploadSuccess />
              </Private>
            }
          />
          <Route
            path={userCategory}
            element={
              <Private>
                <UserCategory />
              </Private>
            }
          />
          <Route
            path={addExecutor}
            element={
              <Private>
                <AddExecutor />
              </Private>
            }
          />
          <Route
            path={executorList}
            element={
              <Private>
                <ExecutorList />
              </Private>
            }
          />
          <Route
            path={myNominees}
            element={
              <Private>
                <MyNominees />
              </Private>
            }
          />
          <Route
            path={view_draft}
            element={
              <Private>
                <ViewDraftPage />
              </Private>
            }
          />
          <Route
            path={willtype}
            element={
              <Private>
                <WillType />
              </Private>
            }
          />
          <Route path={servererror} element={<ServerError />} />
          <Route path="/" element={<Home />} />
          <Route path={faq} element={<Faq />} />
          <Route path={support} element={<Support />} />
          <Route path={blogs} element={<Blogs />} />
          <Route path={links} element={<OtherLinksPage />} />
          <Route path={design} element={<Design />} />
          <Route path={aadharpage} element={<Aadhar_page />} />
          <Route path={email_verify_url} element={<VerifyEmail />} />
          <Route path={blog_detail} element={<Blog_detail />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
      {/* {AddLibrary()} */}
    </>
  );
}

export default App;
