import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, useFormik, FormikProvider } from "formik";
import { initialValues, validationSchema, getFormData } from "./util";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import { customiseFormSuccessMsg } from "../../../util";
import { selectAsset,addNominee } from "../../../route";
import CountryStateCityPincode from "../common/CountryStateCityPincode";
import R_CountryStateCityPincode from "../common/R_CountryStateCityPincode";
import GenderDropDown from "../common/GenderDropDown";
import MaritalStatusDropDown from "../common/MaritalStatusDropDown";
import SubmitBtn from "../common/SubmitBtn";
import FieldWithTooltip from "../common/FieldWithTooltip";
import PrimaryIDProof from "../common/PrimaryIDProof";

const stateKey = "PersonalDetail";
const getKey = "PersonalDetail";

const PersonalDetailForm = (props) => {
  const navigate = useNavigate();
  const firstPath = window.location.pathname.split("/")[1];
  const [loading, setLoading] = useState(true);
  const [initial_values, setInitial_values] = useState(initialValues);
  const [readonly, setReadonly] = useState(false);

  useEffect(() => {
    props.dispatch({
      type: "get_personal_detail",
      key: getKey,
    });
  }, []);

  useEffect(() => {
    setInitial_values({
      ...initial_values,
      ...props.getData.data,
      same_as_permanent:
        props.getData.data.same_as_permanent == 1 ? true : false,
    });
    setLoading(false);
  }, [props.getData.data]);

  const submitHandler = (values) => {
    

    if (formik.isValid === true) {
      setLoading(true);
      const formData = getFormData(values);
      props.dispatch({
        type: "personal_detail",
        payload: formData,
        key: stateKey,
      });
    }   
  };
  const formik = useFormik({
    initialValues: initial_values,
    values: initial_values,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    const { same_as_permanent, ...rest } = formik.values;

    if (formik.values.same_as_permanent === true) {
      setInitial_values({
        ...rest,
        same_as_permanent: true,
        r_add: formik.values.p_add,
        r_country: formik.values.p_country,
        r_state: formik.values.p_state,
        r_city: formik.values.p_city,
        r_pincode: formik.values.p_pincode,
      });
      setReadonly(true);
    } else {
      setInitial_values({
        ...rest,
        same_as_permanent: false,
        r_add: "",
        r_country: "",
        r_state: "",
        r_city: "",
        r_pincode: "",
      });
      setReadonly(false);
    }
  }, [formik.values.same_as_permanent]);

  useEffect(() => {
    if (props.formResult.error === true || props.formResult.success === true) {
      setLoading(false);
    }
    if (props.formResult.success === true) {
      setTimeout(() => {
        formik.resetForm();
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
        if (firstPath !== "account-setting") {
          console.log("I am here");
          const sess_data = sessionStorage.getItem("data");
          const sessionData = JSON.parse(sess_data);
          if (sessionData !== null && sessionData !== undefined) {
            if(sessionData.will_type == 1)
            {
              if(sessionData.user_will_type == 1)
              {
                navigate(addNominee);
              }
              else
              {
                navigate(selectAsset);
              }
            }
            else
            {
              if(sessionData.user_will_type == 1)
              {
                navigate(addNominee);
              }
              else
              {
                navigate(selectAsset);
              }
            }
          }
        }
      }, 1000);

      props.dispatch({
        type: "get_personal_detail",
        key: getKey,
      });
    }
  }, [props.formResult.success, props.formResult.error]);

  

  return (
    <>
      {props?.formResult?.success === true && (
        <CustomModal
          body={
            firstPath !== "account-setting"
              ? customiseFormSuccessMsg(props.formResult.message)
              : props.formResult.message
          }
          stateKey={stateKey}
          type="success"
        />
      )}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {loading && <Loader />}
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Row className="">
            <Form.Group className="col-md-4 col-12 mb-3">
              <FieldWithTooltip
                label="Your full name"
                name="name"
                text="Write it exactly as it appears in your adhar card"
              />
            </Form.Group>
            <Form.Group className="col-md-4 col-12 mb-3">
              <Form.Label>Mobile number</Form.Label>
              <Field name="phone">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="text"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      // readOnly
                      value={field.value ? field.value : ""}
                      placeholder="Enter your phone number"
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
            </Form.Group>
            <Form.Group className="col-md-4 col-12 mb-3">
              <Form.Label>Email Id</Form.Label>
              <Field name="email">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="email"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      placeholder="Enter your email Id"
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="col-md-4 col-12 mb-3">
              <Form.Label>ID Proof Type</Form.Label>
              <Field name="proof_type">
                {({ field, meta, form }) => (
                  <PrimaryIDProof
                    validation={{
                      ...field,
                      isInvalid: !!meta.error,
                      error: meta.error,
                      form: form,
                    }}
                  />
                )}
              </Field>
              {/* <Field name="aadhar_card">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="text"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field> */}
            </Form.Group>
            <Form.Group className="col-md-4 col-12 mb-3">
              <Form.Label>Proof Detail</Form.Label>
              <Field name="proof_detail">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="text"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      placeholder="Enter Prood detail"
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
            </Form.Group>

            <Form.Group className="col-md-4 col-12 mb-3">
              <Form.Label>Gender</Form.Label>
              <Field name="gender">
                {({ field, meta, form }) => (
                  <GenderDropDown
                    validation={{
                      ...field,
                      //isInvalid: !!meta.error && !!meta.touched,
                      error: meta.error,
                      form: form,
                    }}
                  />
                )}
              </Field>
            </Form.Group>
          </Row>
          <Row className="">
            <Form.Group className="col-md-4 col-12 mb-3">
              <Form.Label>Date of Birth</Form.Label>
              <Field name="dob">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="date"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
            </Form.Group>
            <Form.Group className="col-md-4 col-12 mb-3">
              <Form.Label>Marital Status</Form.Label>
              <Field name="marital_status">
                {({ field, meta, form }) => (
                  <MaritalStatusDropDown
                    validation={{
                      ...field,
                      isInvalid: !!meta.error,
                      error: meta.error,
                      form: form,
                    }}
                  />
                )}
              </Field>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="col-md-12 mb-3 col-12">
              <FieldWithTooltip
                label="Permanent Address"
                name="p_add"
                as="textarea"
                text="Write it exactly as it appears in your adhar card"
              />
            </Form.Group>

            <CountryStateCityPincode
              setInitial_values={setInitial_values}
              formik={formik}
              fieldname={{
                pin: "p_pincode",
                country: "p_country",
                state: "p_state",
                city: "p_city",
              }}
            />
          </Row>
          <Row className="mt-3">
            <Form.Group className="col-md-12 mb-3">
              <Form.Label>
                Residential Address{" "}
                <span className="ms-2">
                  <Field
                    type="checkbox"
                    name="same_as_permanent"
                    id="same_as_permanent"
                    className="form-check-input mt-1 me-1"
                  />
                  <label
                    htmlFor="same_as_permanent"
                    className="form-check-label"
                  >
                    Same as permanent Address
                  </label>
                </span>
              </Form.Label>
              <Field name="r_add">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      as="textarea"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      readOnly={readonly}
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
            </Form.Group>
            <R_CountryStateCityPincode
              setInitial_values={setInitial_values}
              formik={formik}
              fieldname={{
                pin: "r_pincode",
                country: "r_country",
                state: "r_state",
                city: "r_city",
              }}
              readonly={readonly}
            />
          </Row>

          {/* <Row>
            <Form.Group className="col-md-4 mb-3">
              <Form.Label>Religion</Form.Label>
              <Field name="religion">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="text"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      readOnly={true}
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
            </Form.Group>
          </Row> */}
          <SubmitBtn text="Update" />
        </Form>
      </FormikProvider>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
    getData: { ...state.GetData[getKey] },
  };
})(PersonalDetailForm);
