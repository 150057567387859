import axios from "../interceptor";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../constants";

export const UserBankDetail = (action) => {
  return (dispatch) => {
    axios({
      url: api_base + "/customer/getUserBankDetail",
      method: "get",
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "GET_SUCCESS",
            payload: response?.data?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "GET_ERROR",
            payload: response?.response?.data,
            moreError: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_ERROR",
          payload: error?.response?.data,
          moreError: error?.response?.data,
          key: action.key,
        });
      }
    );
  };
};
