import MasterLayout from "../../layout/MasterLayout";
import FaqSection from "../FaqSection";
import { Container } from "react-bootstrap";

const Faq = () => {
  return (
    <MasterLayout fluid={true} containerClass="p-lg-0 p-md-0" webFooter="true">
      <section className="faq-section-one pt-md-5 pt-4 pb-md-5 ps-md-5">
        <div className="container-fluid">
          <div className="col-md-6 col-12">
            <h2 className="yellowColor">FAQs</h2>
            <p>
              Questions? Find <br /> your answer here
            </p>
          </div>
          <div className="d-flex flex-row faq-topics pt-md-5 pt-3 pb-md-0 mb-md-0 mb-4">
            <div className="col-md-2 col-3 px-md-0 px-2">
              <b className="title">Topics:</b>
            </div>
            <div className="col-md-2 col-3 px-md-0 px-2">Charges</div>
            <div className="col-md-3 col-4 px-md-0 px-2">
              Terms & Conditions
            </div>
            <div className="col-md-2 col-3 px-md-0 px-2">Editing of Will</div>
            <div className="col-md-3 col-4 px-md-0 px-2">Will Transferable</div>
          </div>
        </div>
      </section>
      <section className="ps-md-5">
        <div className="col-md-11 col-12">
          <FaqSection containerClasses="p-0" />
        </div>
      </section>
    </MasterLayout>
  );
};
export default Faq;
