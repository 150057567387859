import { connect } from "react-redux";
import { Form, Row } from "react-bootstrap";
import { Field, FormikProvider, useFormik } from "formik";
import { initialValues, validationSchema, getFormData } from "./util";
import { useState, useEffect } from "react";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";

const stateKey = "contactForm";

const ContactForm = (props) => {
  const [loading, setLoading] = useState(false);

  const submitHandler = (values) => {
    
    if (formik.isValid === true) {
      setLoading(true);
      const { user_captcha, ...formval } = values;
      const formData = getFormData(formval);
      props.dispatch({
        type: "home_contact_form",
        payload: formData,
        key: stateKey,
      });
    }
    // } else {
    //   setCaptchaError(true);
    // }
    
    
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    setLoading(false);

    if (props.formResult?.success === true) {
      setTimeout(() => {
        formik.resetForm();
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
      }, 3000);
    }
  }, [props?.formResult?.success, props?.formResult?.error]);

  return (
    <>
      {loading && <Loader />}

      {props?.formResult?.success === true && (
        <CustomModal
          body={props.formResult.message}
          stateKey={stateKey}
          type="success"
        />
      )}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className="contactForm">
          <Row className="mb-lg-4 mb-md-4 mb-sm-2">
            <div className="col-md-6">
              <Field name="full_name">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      placeholder="Full Name"
                    />
                    {meta.touched && meta.error && (
                      <div className="field-error">{meta.error}</div>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className="col-md-6">
              <Field name="email">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="email"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      placeholder="Email Id"
                    />
                    {meta.touched && meta.error && (
                      <div className="field-error">{meta.error}</div>
                    )}
                  </>
                )}
              </Field>
            </div>
          </Row>
          <Row className="mb-lg-4 mb-md-4 mb-sm-2">
            <div className="col-md-12">
              <Field name="notes">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      as="textarea"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      placeholder="Purpose of contacting"
                      rows="6"
                    />
                    {meta.touched && meta.error && (
                      <div className="field-error">{meta.error}</div>
                    )}
                  </>
                )}
              </Field>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 text-lg-start text-md-start text-sm-start text-center">
              <button className="button" type="submit">
                Ask for help
              </button>
            </div>
          </Row>
        </Form>
      </FormikProvider>
    </>
  );
};

export default connect((state) => {
  return { formResult: { ...state?.Forms?.[stateKey] } };
})(ContactForm);
