import axios from "../interceptor";
import { call, put, takeEvery } from "redux-saga/effects";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../constants";

function getForm(action) {
  return axios({
    url: api_base + "/customer/getStateList",
    method: "post",
    data: action.payload,
  });
}

function* get_Form_G(action) {
  try {
    var result = yield call(getForm, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

export function* StatesSaga() {
  yield takeEvery("get_stateList", get_Form_G);
}
