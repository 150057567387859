import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";

import { Field, useFormik, FormikProvider } from "formik";

import {
  initialValues,
  validationSchema,
  getFormData,
  asset_sub_type,
} from "./util";
import { Radio } from "@palmerhq/radio-group";
import { FRadioGroup } from "../../common/RadioButtons";
import SubmitBtn from "../../common/SubmitBtn";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";

const Insurance = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} Insurance
        </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
              <Form.Group className="col-md-12">
                <Form.Label>Type</Form.Label>
                <FRadioGroup name="asset_sub_type" autoFocus={false}>
                  {asset_sub_type.map((each, index) => {
                    return (
                      <Radio value={each} key={`radio${index}`}>
                        {each}
                      </Radio>
                    );
                  })}
                </FRadioGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group className="col-md-4">
                <Form.Label>Insurance Policy Number</Form.Label>
                <Field name="policy_number">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Label>Insurer Company</Form.Label>
                <Field name="insurer_company">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(Insurance);
