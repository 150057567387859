import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../common/Loader/Loader";
import { changeToDMY } from "../../../util";
import {
  faFile,
  faArrowUpFromBracket,
  faArrowUp,
  faAngleDown,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { assetSummary, uploadWill } from "../../../route";
import ViewDraft2 from "../ViewDraft2";
import AssetSummaryDropDownItem from "../common/AssetSummaryDropDownItem";

const getKey = "past_wills";

const PastWill = (props) => {
  const [loading, setLoading] = useState(true);
  const [showDraftPopup, setShowDraftPopup] = useState(false);
  const [draftKey, setDraftKey] = useState("");

  const draftModal_closeHandler = () => {
    setShowDraftPopup(false);
    setDraftKey("");
  };
  useEffect(() => {
    props.dispatch({
      type: "get_uploadedWill",
      payload: "past",
      key: getKey,
    });
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [props.List.data]);

  const getStatus = (status) => {
    return status == "pay"
      ? "Upload Pending"
      : status == "download"
      ? "Downloaded"
      : status == "upload"
      ? "Uploaded"
      : "";
  };
  var i = 0;
  const viewDraft = (id) => {
    setShowDraftPopup(true);
    setDraftKey(id);
  };
  return (
    <>
      {loading && <Loader />}
      {showDraftPopup == true && (
        <ViewDraft2 handleClose={draftModal_closeHandler} id={draftKey} />
      )}
      <div className="acc-page-heading">Past Will</div>
      <div className="main-layout-cont">
        {props.List.data.length > 0 ? (
          props.List.data.map((each, index) => {
            i++;
            return (
              <div key={`willList${index}`} className="mb-6">
                <div className="rowStyle py-2 px-3 mb-3 d-lg-flex  d-md-flex ">
                  <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                    <b>{each.will_name}</b>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 d-flex my-1">
                    <div className="col-4">
                      Status: <br />
                      {getStatus(each.progress)}
                    </div>
                    {/* <div className="greyColor d-flex">
                      <FontAwesomeIcon
                        icon={faFile}
                        className="file-icon greyColor"
                      />
                      Rs. 100
                    </div> */}
                    <div className="col-4 text-center">
                      <div className="disabledEdit greyColor d-flex">
                        <FontAwesomeIcon
                          icon={faPencil}
                          className="file-icon greyColor"
                        />
                        <div>Edit</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-flex greyColor">
                        <FontAwesomeIcon
                          icon={faArrowUpFromBracket}
                          className="file-icon greyColor"
                        />
                        <div>Upload</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 d-flex mt-sm-2">
                    <div className="col-6">Executor</div>
                    <div className="col-6 text-end">
                      <Dropdown className="willPageDropDown">
                        <Dropdown.Toggle>
                          Download{" "}
                          <FontAwesomeIcon icon={faAngleDown} className="" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#"
                            onClick={() => {
                              viewDraft(each.id);
                            }}
                          >
                            Draft
                          </Dropdown.Item>
                          {each.downloadLink !== null && (
                            <Dropdown.Item href={each.downloadLink}>
                              Signed Copy
                            </Dropdown.Item>
                          )}
                          <AssetSummaryDropDownItem id={each.id} />
                          {/* <Dropdown.Item href={assetSummary}>
                          Asset Summary
                        </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <div className="mb-3 font-16">
                  <div className="col-md-8 d-flex">
                    <div>Created on: {changeToDMY(each.created_at)} | </div>
                    <div>Modified on: {changeToDMY(each.updated_at)} | </div>
                    <div>
                      Status: <b>{getStatus(each.progress)}</b>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <b>Progress:</b>
                </div>
                <div className="progressBar d-flex pt-3 pb-3 ps-lg-3 ps-md-3  ps-sm-3">
                  <div
                    className={`pointer col-sm-4 col-4 ${
                      (each.progress == "paid" ||
                        each.progress == "download" ||
                        each.progress == "upload" ||
                        each.progress == "receive_final_mail") &&
                      "active"
                    }`}
                  >
                    <div className={`pointer-icon start`}></div>
                    <b className="pointer-text start mt-3">Pay</b>
                  </div>
                  <div className={`pointer col-sm-4 col-4 d-flex`}>
                    <div className="col-6">
                      <div
                        className={`pointer-icon start ${
                          (each.progress == "download" ||
                            each.progress == "upload" ||
                            each.progress == "receive_final_mail") &&
                          "active"
                        }`}
                      ></div>
                      <b
                        className={`pointer-text center1 x mt-3 ${
                          (each.progress == "download" ||
                            each.progress == "upload" ||
                            each.progress == "receive_final_mail") &&
                          "active"
                        }`}
                      >
                        Download
                      </b>
                    </div>
                    <div className="d-flex flex-row-reverse col-6">
                      <div
                        className={`pointer-icon end ${
                          (each.progress == "upload" ||
                            each.progress == "receive_final_mail") &&
                          "active"
                        }`}
                      ></div>
                      <b
                        className={`pointer-text center2  d-flex flex-row-reverse ${
                          (each.progress == "upload" ||
                            each.progress == "receive_final_mail") &&
                          "active"
                        }`}
                      >
                        Upload
                      </b>
                    </div>
                  </div>
                  <div
                    className={`pointer col-sm-4 col-4 d-flex flex-row-reverse ${
                      each.progress == "receive_final_mail" && "active"
                    }`}
                  >
                    <div className={`pointer-icon end`}></div>
                    <b className="pointer-text text-end ms-2">
                      Received final mail
                    </b>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div key={`willList0`} className="mb-6">
            <div className="rowStyle pt-3 pb-3 pe-3 ps-3 mb-3 d-flex">
              Not Any Past Wills
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    List: { ...state?.GetData?.[getKey] },
  };
})(PastWill);
