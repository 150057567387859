import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import gmailIcon from "../../../assets/images/gmail-icon.png";
import whatsappIcon from "../../../assets/images/whatsapp-icon.png";
import downloadIcon from "../../../assets/images/download-icon.png";
import { connect } from "react-redux";
import Loader from "../common/Loader/Loader";
import { share_summary_via_email } from "../../../redux/AllThunk/Share";
import CustomModal from "../common/CustomModal";

const getKey = "currentAssetSummary";
const shareOnEmail = "shareOnEmail";

const SharePopup = (props) => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");

  const downloadCurrentAssetSummary = () => {
    props.dispatch({
      type: "download_current_asset_summary",
      key: getKey,
    });
  };

  const Cancel = () => {
    setShow(false);
    props.sharePopup(false);
    props.dispatch({
      type: "delete_asset_summary_pdf",
      key: getKey,
    });
  };
  useEffect(() => {
    downloadCurrentAssetSummary();
  }, []);
  useEffect(() => {
    if (props.result.success == true) {
      // setDownloadLink(props.result.data.downloadLink);
    }
  }, [props.result]);

  const shareOnGmail = () => {
    setLoading(true);
    props.dispatch(
      share_summary_via_email({
        payload: { filepath: props.result.data.fileLink },
        key: shareOnEmail,
      })
    );
  };

  useEffect(() => {
    if (props.emailShare.success == true || props.emailShare.error == true) {
      setLoading(false);
      //setShow(false);
      //props.sharePopup(false);
    }
  }, [props.emailShare]);

  return (
    <>
      {loading && <Loader style={{ zIndex: 99999 }} />}

      <iframe id="my_iframe" className="d-none" src={downloadLink}></iframe>

      <Modal
        show={show}
        className=""
        onHide={() => {
          return false;
        }}
      >
        <FontAwesomeIcon
          icon={faCircleXmark}
          onClick={() => {
            Cancel();
          }}
          className="modalCloseIcon"
        />
        <Modal.Body>
          <p className="text-center">
            <b>Share asset summary via</b>
          </p>
          <div className="mt-3 d-flex">
            <div className="col-4 text-center">
              <img
                src={gmailIcon}
                className="gmail-icon w-50"
                onClick={() => {
                  shareOnGmail();
                }}
              />
            </div>
            <div className="col-4 text-center">
              <img src={whatsappIcon} className="whatsapp-icon w-50" />
            </div>
            <div className="col-4 text-center">
              <img
                src={downloadIcon}
                className="download-icon w-50"
                onClick={() => {
                  // downloadCurrentAssetSummary();
                  setDownloadLink(props.result.data.downloadLink);
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect((state) => {
  return {
    result: { ...state?.GetData?.[getKey] },
    emailShare: { ...state?.Forms?.[shareOnEmail] },
  };
})(SharePopup);
