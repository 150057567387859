import React from "react";
import { Row } from "react-bootstrap";
import LogoutBtn from "./LogoutBtn";
import profileIcon from "../../assets/images/profilepic.png";
import MasterLayout from "./MasterLayout";
import MakeWillBtn from "../components/common/MakeWillBtn";

export default function Layout2(props) {
  let data = sessionStorage.getItem("data");
  const dataobj = JSON.parse(data);

  return (
    <MasterLayout fluid={true} showWillBtn={true}>
      <section className="body-cont-2">
        <Row className="subheader">
          <div className="col-sm-6 col-6  d-flex leftSection">
            <img src={profileIcon} className="profile-pic" />
            <div className="username ms-3">Hello {dataobj?.name},</div>
          </div>
          <div className="col-sm-6 col-6 rightSection d-flex flex-row-reverse">
            {/* <LogoutBtn /> */}
            {dataobj !== null && dataobj?.currentWillProgress !== "unpaid" ? (
              <MakeWillBtn />
            ) : (
              <LogoutBtn />
            )}
          </div>
        </Row>
        <Row className="box-shadow border-10 mt-3">{props.children}</Row>
      </section>
    </MasterLayout>
  );
}
