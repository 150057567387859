import * as Yup from "yup";
import {
  notOnlyWhiteSpace,
  alphanumeric,
  phoneRegExp,
  emailRegExp,
} from "../../../constants";

export const initialValues = {
  q1: "Hindu",
  q2: "Indian",
  other_religion: "",
};
export const validationSchema = Yup.object().shape({
  q1: Yup.string().required("*This is required"),
  q2: Yup.string().required("*This is required"),
  other_religion: Yup.string().when("q1", {
    is: "Other",
    then: Yup.string()
      .required("*This is required")
      .matches(
        notOnlyWhiteSpace,
        "*Only Alphabets,Numbers and Spaces are allowed "
      ),
  }),


});

export const getFormData = (values) => {
  let formData = new FormData();
  let str = "";

  str += values["q1"] + "|";
  str += values["q2"] + "|";

  if (values["q1"] == "Other") {
    str += values["other_religion"];
  } else {
    str = str.slice(0, -1);
  }
  formData.append(`answer_set`, str);
  return formData;
};

export const religionArr = [
  "Hindu",
  "Muslim",
  "Jain",
  "Christian",
  "Buddhist",
  "Sikh",
  "Atheist",
  "Other",
];

const sess_data = sessionStorage.getItem("data");
const sessionData = JSON.parse(sess_data);

export const initialValues_popup = {
  full_name: sessionData !== null ? sessionData.name : "",
  mobile: sessionData !== null ? sessionData.phone : "",
  email: sessionData !== null ? sessionData.email : "",
  city: "",
  state: "",
  country: "",
  notes: "",
  
};
export const validationSchema_popup = Yup.object().shape({
  full_name: Yup.string()
    .required("*This is required")
    .max(100, "*Name can't be longer than 100 characters")
    .matches(alphanumeric, "*Only Alphabets,Numbers and Spaces are allowed "),
  mobile: Yup.string()
    .required("*This is required")
    .matches(phoneRegExp, "*Phone number is not valid")
    .max(17, "*Phone must be less than 17 digits")
    .min(6, "*Phone must be minimum 6 digits"),
  email: Yup.string()
    .required("*This is required")
    .matches(emailRegExp, "*Please enter valid email address "),
  city: Yup.string()
    .required("*This is required")
    .max(56, "*Phone must be less than or 56 digits")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  state: Yup.string()
    .required("*This is required")
    .max(56, "*Phone must be less than 56 digits")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  country: Yup.string()
    .required("*This is required")
    .max(56, "*Phone must be less than 56 digits")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  notes: Yup.string()
    .required("*This is required")
    .max(500, "*This can't be longer than 500 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),


});

export const getPopupFormData = (values) => {
  let formData = new FormData();
  formData.append(`via`, "muslim_form");

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  return formData;
};

export const getNRIFormData = (values) => {
  let formData = new FormData();
  formData.append(`via`, "NRI_form");

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  return formData;
};
