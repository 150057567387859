const Forms = function (
  state = {
    Login: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    Logout: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    UserRegister: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    PersonalDetail: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    AddAssetForm: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    AddNominee: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    AddAssetNominee: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    deleteAsset: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    uploadWill: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    changePassword: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    user_category: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    AddExecutor: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    add_executor_to_will: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    make_payment: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    create_new_will: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    add_user_id_proof: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    removeExecutor: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    ForgotPass: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    ResetPass: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    contactForm: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    addyourwish: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    removeyourwish: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    verify_email_otp: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    coupon: {
      loading: true,
      error: false,
      success: false,
      message: "",
      moreError: [],
    },
    shareOnEmail: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
  },
  action
) {
  switch (action.type) {
    case "RESET_FORM": {
      state = { ...state };
      state[action.key]["loading"] = true;
      state[action.key]["success"] = false;
      state[action.key]["error"] = false;
      state[action.key]["moreError"] = [];
      state[action.key]["message"] = "";
      return state;
    }
    case "FORM_SUCCESS": {
      state = { ...state };
      state[action.key]["success"] = true;
      state[action.key]["error"] = false;
      state[action.key]["message"] = action.payload;
      state[action.key]["loading"] = false;
      state[action.key]["moreError"] = [];
      state[action.key]["data"] = action?.data;
      return state;
    }
    case "FORM_ERROR": {
      state = { ...state };
      state[action.key]["success"] = false;
      state[action.key]["error"] = true;
      state[action.key]["message"] = action.payload;
      state[action.key]["moreError"] =
        action.moreError !== undefined ? action.moreError : [];
      state[action.key]["loading"] = false;
      return state;
    }
    default:
      return state;
  }
};

export default Forms;
