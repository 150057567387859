import { Link } from "react-router-dom";
import MasterLayout from "../../layout/MasterLayout";
import { Accordion, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Terminology from "./Terminology";
import Item from "./Item";
import HelpDeskSection from "../HomeSliders/HelpDeskSection";
import FaqSection from "../FaqSection";

const Support = (props) => {
  return (
    <MasterLayout webFooter={true} fluid={true} containerClass="p-lg-0 p-md-0">
      <section className="support">
        <section className="one py-md-5 py-2">
          <h2 className="yellowColor">Support Learn</h2>
          <div className="col-9 paragraph mb-md-4 mb-3 fw-bold">
            We have covered it all even if you have very little knowledge about
            will making.
          </div>
          <div>
            <div className="mb-md-3 mb-2">
              <b>Know inside out:</b>
            </div>
            <div className="d-flex flex-column">
              <a href="#will_types" className="mb-2">
                Types of will
              </a>
              <a href="#terminology" className="mb-2">
                Terminologies
              </a>
              <a href="#will_content" className="mb-2">
                Content of will
              </a>
              <a href="#faq" className="mb-2">
                FAQs
              </a>
            </div>
          </div>
        </section>
        <section
          id="will_types"
          className="will_types d-md-flex py-4 px-md-4 px-0"
        >
          <div className="col-md-6 col-12 p-lg-5 px-md-0 px-4">
            <h2 className="yellowColor fw-bold">Different Types of will</h2>
            <p>
              There are types of will according to the user’s need. Read through
              the types of will and identify which type of will suits your
              situation.
            </p>
          </div>
          <div className="col-md-6 col-12">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Simple Will</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Testamentary trust Will</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Joint Will</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Living Will</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </section>
        <section id="terminology" className="terminology py-5">
          <h1>
            <b>Terminologies of will</b>
          </h1>
          <Terminology />
          <Row className="mt-5">
            <div className="col-lg-4 col-md-4 col-sm-12 mb-md-5 mb-4 ">
              <Item title="Beneficiary 1">
                A beneficiary is someone who receives an inheritance through a
                will. Anyone you leave property to in your will is one of your
                beneficiaries.
              </Item>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mb-md-5 mb-4">
              <Item no="02" title="Beneficiary 2">
                A beneficiary is someone who receives an inheritance through a
                will. Anyone you leave property to in your will is one of your
                beneficiaries.
              </Item>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mb-md-5 mb-4">
              <Item no="02" title="Beneficiary 3">
                A beneficiary is someone who receives an inheritance through a
                will. Anyone you leave property to in your will is one of your
                beneficiaries.
              </Item>
            </div>
          </Row>
          <Row className="">
            <div className="col-lg-4 col-md-4 col-sm-12 mb-md-5 mb-4">
              <Item title="Beneficiary 1">
                A beneficiary is someone who receives an inheritance through a
                will. Anyone you leave property to in your will is one of your
                beneficiaries.
              </Item>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mb-md-5 mb-4">
              <Item no="02" title="Beneficiary 2">
                A beneficiary is someone who receives an inheritance through a
                will. Anyone you leave property to in your will is one of your
                beneficiaries.
              </Item>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mb-md-5 mb-4">
              <Item no="02" title="Beneficiary 3">
                A beneficiary is someone who receives an inheritance through a
                will. Anyone you leave property to in your will is one of your
                beneficiaries.
              </Item>
            </div>
          </Row>
          <div className="col-12 text-center mt-md-5 mt-3">
            <button className="button">Show More</button>
          </div>
        </section>
        <section id="will_content" className="will_content py-3">
          <h4 className="mb-md-4 mb-2">
            <b>Content of will</b>
          </h4>
          <p>
            <b>Understand the anatomy of the will.</b> At willyourwish we not
            only provide you the ease of making will but also make you
            understand each an every process and its importance
          </p>
          <div className=""></div>
        </section>
        <section
          id="section-help"
          className="section-help mt-lg-5 mt-md-4 mt-sm-3"
        >
          <HelpDeskSection />
        </section>
        <section id="faq" className="faq">
          <h4 className="mb-md-5 mb-4">
            <b>Yet not clear? Read our FAQ </b>
          </h4>
          <FaqSection containerClasses="p-0" />
        </section>
      </section>
    </MasterLayout>
  );
};

export default connect((state) => {
  return {};
})(Support);
