import MainLayout from "../../layout/Mainlayout";
import okIcon from "../../../assets/images/icon-ok.png";
import ViewDraft from "./index";
import ExecutorPopup from "../common/ExecutorPopup";
import RemoveExecutorPopup from "../common/RemoveExecutorPopup";
import CustomModal from "../common/CustomModal";

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addExecutor, addyourwish, assetSummary } from "../../../route";
import GobackArrow from "../common/GobackArrow";

const removeExeKey = "removeExecutor";
const addExeKey = "AddExecutor";
const getKey = "assetSummary";

const Page = (props) => {
  const [showDraftPopup, setShowDraftPopup] = useState(false);
  const [showExecutorPopup, setShowExecutorPopup] = useState(false);
  const [showExecutorRemovePopup, setShowExecutorRemovePopup] = useState(false);
  const [addNewExecutor, setAddNewExecutor] = useState("");
  const [addedExecutor, setAddedExecutor] = useState("");
  const [executor_id, setExecutor_id] = useState(null);
  const navigate = useNavigate();

  const getAssetSummay = () => {
    props.dispatch({
      type: "get_asset_summary",
      key: getKey,
    });
  };
  useEffect(() => {
    getAssetSummay();
  }, []);

  useEffect(() => {
    //setLoading(false);
    props.getData.data?.currentWillData?.executor_id !== null &&
      props.getData.data?.currentWillData?.executor_id !== undefined &&
      setExecutor_id(props.getData.data?.currentWillData?.executor_id);
  }, [props.getData.data]);

  const view_draft_handle = () => {
    setAddNewExecutor("");

    if (executor_id !== null && executor_id !== undefined) {
      setShowExecutorRemovePopup(true);
    } else {
      setShowExecutorPopup(true);
    }
  };

  const draftModal_closeHandler = () => {
    setShowDraftPopup(false);
  };

  //======executor==========

  const executor_return_handler = (props) => {
    setAddNewExecutor(props);
    setShowExecutorPopup(false);
  };

  const remove_executor_handler = (removeExe) => {
    if (removeExe == "yes") {
      props.dispatch({
        type: "remove_executor",
        key: removeExeKey,
      });
    } else {
      setShowExecutorRemovePopup(false);
      setShowDraftPopup(true);
    }
  };

  useEffect(() => {
    if (addNewExecutor === "yes") {
      navigate(addExecutor);
    } else if (addNewExecutor === "no") {
      setShowDraftPopup(true);
    }
  }, [addNewExecutor]);

  useEffect(() => {
    if (props.removeExe.success == true) {
      setExecutor_id(null);
      setTimeout(() => {
        getAssetSummay();

        props.dispatch({
          type: "RESET_FORM",
          key: removeExeKey,
        });
        setShowDraftPopup(true);
      }, 1000);
    }
  }, [props.removeExe]);

  useEffect(() => {
    if (props.addExe.success == true) {
      setTimeout(() => {
        props.dispatch({
          type: "RESET_FORM",
          key: addExeKey,
        });
        setShowDraftPopup(true);
      }, 1000);
    }
  }, [props.addExe]);
  return (
    <MainLayout>
      {props?.removeExe?.success === true && (
        <CustomModal
          body={props.removeExe.message}
          stateKey={removeExeKey}
          type="success"
        />
      )}
      {showExecutorPopup === true && (
        <ExecutorPopup return={executor_return_handler} />
      )}
      {showExecutorRemovePopup === true && (
        <RemoveExecutorPopup return={remove_executor_handler} />
      )}
      {showDraftPopup == true && (
        <ViewDraft handleClose={draftModal_closeHandler} />
      )}
      {/* {showSuccessModal == true && (
        <CustomModal
          body={"The document has successfully been downloaded"}
          stateKey={stateKey}
          type="success"
        />
      )}
      {loading && <Loader />} */}
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow goto={addyourwish} />
          <img src={okIcon} className="pay-success-icon" /> Congratulations,
          your will Draft is ready
        </div>
      </div>
      <div
        className="main-layout-cont"
        style={{ marginTop: "27px", fontSize: "18px" }}
      >
        <p className="col-md-8">
          We have taken all the information you provided and prepared a legally
          valid, comprehensive Will document for you.
        </p>
        <h3 className="layout__headings">Instructions/Next Steps:</h3>
        <div className="col-md-9">
          <ol className="pay-success-ol">
            <li>
              Click on View Draft button below to view your auto generated will
              - this is a draft copy for review purpose only.
            </li>
            <li>Read through the draft completely & carefully.</li>
            <li>
              Make sure you have covered all known assets and all your wishes
            </li>
            <li>
              If you wish to change any assets, allocations or wishes, you can
              click on Edit Will button, or relevant section in the left hand
              menu to make change.
            </li>
            <li>You can make as many changes as would like</li>
            <li>
              Once you are satisfied, click “Finalize will”, and we will take
              you through the last couple of steps to Legalize the will.
            </li>
            <li>
              Once you click “Finalize Will”, you will not be able to make any
              more changes to the draft.
            </li>
          </ol>
        </div>
        <div className="d-lg-flex d-md-flex ">
          <div className="col-md-6 col-sm-12 col-12 mb-3 text-bold">
            {executor_id !== "" &&
              executor_id !== null &&
              executor_id !== undefined &&
              "Executor is added"}{" "}
          </div>

          <div className="col-md-6 col-sm-12 col-12 d-flex ">
            <div className="col-md-6 col-6">
              <button
                className="button"
                onClick={() => {
                  navigate(assetSummary);
                }}
                type="button"
              >
                Edit Will
              </button>
            </div>
            <div className="col-md-6 col-6 d-flex justify-content-end">
              <button
                className="button"
                onClick={() => {
                  view_draft_handle();
                }}
                type="button"
              >
                View Draft
              </button>
            </div>
          </div>
        </div>
        {/* <button
          className="mt-auto  download-button "
          onClick={() => {
            DownloadHandler();
          }}
        >
          <img src={downloadIcon} />
          Download
        </button>
        <button
          onClick={upload_doc}
          className="mt-auto button"
          disabled={upload_enabled}
        >
          Next
        </button> */}
      </div>
    </MainLayout>
  );
};
export default connect((state) => {
  return {
    removeExe: { ...state.Forms[removeExeKey] },
    addExe: { ...state?.Forms?.[addExeKey] },
    getData: { ...state.GetData[getKey] },
  };
})(Page);
