import * as Yup from "yup";
import { alphanumeric, notOnlyWhiteSpace } from "../../../../constants";

export const detailObj = {
  company_name: "",
  percent_unit: "percent",
  no_of_percent: "",
  no_of_shares: "",
};
export const initialValues = {
  asset_sub_type: "Private Stocks/ Partnership Stake",
  stock_detail: [detailObj],
  
};
export const validationSchema = Yup.object().shape({
  stock_detail: Yup.array().of(
    Yup.object().shape({
      company_name: Yup.string()
        .required("*This is required")
        .min(2, "*This must have at least 2 characters")
        .max(100, "*This can't be longer than 100 characters")
        .matches(alphanumeric, "*Only White space is not allowed"),
      no_of_shares: Yup.number().when("percent_unit", {
        is: "no_of_share",
        then: Yup.number()
          .required("*This is required")
          .typeError("Only numbers allowed")
          .min(0, "Quantity must be greater than or equal to 0"),
      }),
      no_of_percent: Yup.number().when("percent_unit", {
        is: "percent",
        then: Yup.number()
          .typeError("Only numbers allowed")
          .required("*This is required")
          .max(100, "*Can't be greater than 100%")
          .test(
            "greaterThanZero",
            "number field must be greater than zero",
            (number) =>
              /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/.test(number)
          ),
      }),
    })
  ),
});
export const asset_sub_type = [
  "Private Stocks/ Partnership Stake",
  "ESOPs",
  "RSUs",
];
