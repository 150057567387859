import ReactTooltip from "react-tooltip";
import styles from "../../../assets/Tooltip.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Field } from "formik";
import { Form } from "react-bootstrap";
import style from "./ToolTip";

const FieldWithTooltip = React.forwardRef((props, ref) => {
  //const FieldWithTooltip = (props) => {
  const iconRef = React.createRef();
  const formik = props.formik;
  return (
    <>
      <Form.Label>
        {props.label}
        <span
          ref={iconRef}
          className={`${styles.tooltip}`}
          data-tip={props.text}
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        <ReactTooltip
          effect="solid"
          multiline={true}
          place={props.place ? props.place : "top"}
          //delayHide={10000}
          //className={props.DontHideOnHover}
          //className={`${style.DontHideOnHover}`}
        />
      </Form.Label>
      <Field name={props.name}>
        {({ field, meta }) => {
          return (
            <>
              {props.as !== undefined ? (
                <Form.Control
                  {...props}
                  {...field}
                  as={props.as}
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                  onFocus={() => {
                    //formik.onFocus;

                    ReactTooltip.show(iconRef.current);
                  }}
                  onBlur={() => {
                    formik?.handleBlur();

                    ReactTooltip.hide(iconRef.current);
                  }}
                  placeholder={
                    props.placeholder !== undefined ? props.placeholder : ""
                  }
                  ref={ref}
                />
              ) : (
                <Form.Control
                  {...field}
                  type="text"
                  autoComplete="off"
                  isInvalid={!!meta.error && !!meta.touched}
                  value={field.value ? field.value : ""}
                  onFocus={() => {
                    //formik.onFocus;
                    ReactTooltip.show(iconRef.current);
                  }}
                  onBlur={() => {
                    formik?.handleBlur();
                    ReactTooltip.hide(iconRef.current);
                  }}
                  placeholder={
                    props.placeholder !== undefined ? props.placeholder : ""
                  }
                  ref={ref}
                />
              )}
              {meta.touched && meta.error && (
                <Form.Control.Feedback type="invalid">
                  {meta.error}
                </Form.Control.Feedback>
              )}
            </>
          );
        }}
      </Field>
    </>
  );
});
export default FieldWithTooltip;
