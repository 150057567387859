import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { api_base, HTTP_OK, HTTP_VALIDATION_ERROR } from "../../constants";

function Log_in(action) {
  return axios({
    url: api_base + "/customer/login",
    method: "post",
    data: action.payload.data,
  });
}

function* Login_Generator(action) {
  try {
    var result = yield call(Log_in, action);
    if (result.status === HTTP_OK) {
      sessionStorage.setItem("data", JSON.stringify(result?.data.data));
      sessionStorage.removeItem("serverError");
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_VALIDATION_ERROR) {
      yield put({
        type: "FORM_ERROR",
        payload: error?.response?.data,
        moreError: error?.response?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}
export function* Login() {
  yield takeEvery("User_login", Login_Generator);
}
