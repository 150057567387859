import * as Yup from "yup";

import { notOnlyWhiteSpace, alphanumeric } from "../../../constants";

export const initialValues = {
  keyword: "",
  
};

export const validationSchema = Yup.object().shape({
  keyword: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters")
    .matches(alphanumeric, "*Only Alphabets,Numbers and Spaces are allowed "),

});
