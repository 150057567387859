import axios from "../interceptor";
import { call, put, takeEvery } from "redux-saga/effects";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../constants";

function addForm(action) {
  return axios({
    url: api_base + "/customer/add_executor",
    method: "post",
    data: action.payload,
  });
}

function* Form_G(action) {
  try {
    var result = yield call(addForm, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data.message,
        data: result?.data?.data.assetID,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function addForm2(action) {
  return axios({
    url: api_base + "/customer/add_executor_to_will",
    method: "post",
    data: action.payload,
  });
}

function* Add_Form_G(action) {
  try {
    var result = yield call(addForm2, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function getList() {
  return axios({
    url: api_base + "/customer/executor_list",
    method: "get",
  });
}

function* GetListG(action) {
  try {
    var result = yield call(getList);
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function remove_exe() {
  return axios({
    url: api_base + "/customer/remove_executor_from_will",
    method: "get",
  });
}

function* remove_exe_G(action) {
  try {
    var result = yield call(remove_exe, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

export function* Executor() {
  yield takeEvery("add_executor", Form_G);
  yield takeEvery("get_executor_list", GetListG);
  yield takeEvery("add_executor_to_will", Add_Form_G);
  yield takeEvery("remove_executor", remove_exe_G);
}
