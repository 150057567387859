import { useLocation } from "react-router-dom";
import { verifyEmailThunk } from "../../../redux/AllThunk/VerifyEmail";
import { connect } from "react-redux";
import { useEffect,useState } from "react";
import Layout3 from "../../layout/Layout3";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import { customiseFormSuccessMsg } from "../../../util";

const stateKey = "email_verified";
const VerifyEmail = (props) => {
  let search = useLocation().search;
  const verify_url = new URLSearchParams(search).get("verify_url");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props.dispatch(
      verifyEmailThunk({
        url: verify_url,
        key: stateKey,
      })
    );
  }, []);

  useEffect(()=>{
    setLoading(false);
  },[props.data])

  return (
    <Layout3>
      {loading && <Loader />}
      {props?.data?.error === true && (
        <CustomModal
          body={props.data.message}
          moreError={[props.data.error_msg]}
          stateKey={stateKey}
          type="error"
        />
      )}
      {props?.data?.success === true && (
        <CustomModal
          body={customiseFormSuccessMsg(props.data.message)}
          stateKey={stateKey}
          type="success"
        />
      )}
      <b> please wait we are verifying your email address. </b>
    </Layout3>
  );
};
export default connect((state) => {
  return {data:{...state.GetData[stateKey]}};
})(VerifyEmail);
