export const detailStr = (data, asset_type) => {
  let str = "";

  switch (asset_type) {
    case "bank_account": {
      str += `${data.asset_sub_type},`;
      str += `${data.acc_number},`;
      str += `${data.bank_name},`;
      str += `${data.branch_name},`;
      str += `${data.city},`;
      str += `${data.ownership_status}`;
      return str;
    }
    case "crypto_currency": {
      str += `${data.exchange_wallet} ,`;
      str += `${data.type},`;
      str += `${data.units},`;
      str += `${data.user_id},`;
      return str;
    }
    case "ppf": {
      str += `${data.acc_number} ,`;
      str += `${data.acc_via} `;
      return str;
    }
    case "pf": {
      str += `${data.acc_number} ,`;
      str += `${data.uan_no} `;
      return str;
    }
    case "property": {
      str += `${data.asset_sub_type} ,`;
      str += `${data.address_1} ,`;
      data.address_2 !== null && (str += `${data.address_2} ,`);
      data.address_3 !== null && (str += `${data.address_3} ,`);
      str += `${data.city} ,`;
      str += `${data.state} ,`;
      str += `${data.country} ,`;
      str += `${data.pincode} ,`;
      str += `${data.ownership_percentage}% OwnerShip`;
      return str;
    }
    case "mutual_funds": {
      str += `${data.folio_number} ,`;
      str += `${data.mf_amc} ,`;
      str += `${data.mf_units}`;
      return str;
    }
    case "stocks_and_etfs": {
      str += `${data.asin} ,`;
      str += `${data.stock_name} ,`;
      str += `${data.client_id} ,`;
      str += `${data.dp_id} ,`;
      str += `${data.bo_id} `;
      return str;
    }
    case "insurance": {
      str += `${data.asset_sub_type} ,`;
      str += `${data.policy_number} ,`;
      str += `${data.insurer_company} `;
      return str;
    }
    case "bonds": {
      str += `${data.bond_issuer} ,`;
      str += `${data.bond_id} ,`;
      str += `${data.bond_name} ,`;
      str += `${data.bond_units} `;
      return str;
    }
    case "cash": {
      str += `${data.amount}`;
      return str;
    }
    case "sovereign_gold_bond": {
      str += `${data.sgb_id} ,`;
      str += `${data.sgb_demat} `;
      return str;
    }

    case "gold": {
      str += `${data.weight} `;
      return str;
    }

    case "jewellery": {
      str += `${data.description} ,`;
      str += `${data.weight} `;
      return str;
    }
    case "painting": {
      str += `${data.description} `;
      return str;
    }
    case "memorabilia": {
      str += `${data.description} `;
      return str;
    }
    default: {
      return str;
    }
  }
};

export const detail = (data, asset_type) => {
  let arr = [];
  switch (asset_type) {
    case "bank_account": {
      arr[0] = data.asset_sub_type;
      arr[1] = data.acc_number;
      arr[2] = data.bank_name + "(" + data.branch_name + ")";
      //arr[4] = data.city;
      arr[3] = data.ownership_status;
      return arr;
    }
    case "crypto_currency": {
      arr[0] = data.exchange_wallet;
      arr[1] = data.type;
      arr[2] = data.units;
      arr[3] = data.user_id;
      return arr;
    }
    case "ppf": {
      arr[0] = data.acc_number;
      arr[1] = data.acc_via;
      return arr;
    }
    case "pf": {
      arr[0] = data.acc_number;
      arr[1] = data.uan_no;
      return arr;
    }
    case "property": {
      arr[0] = data.asset_sub_type;
      arr[1] = data.address_1;
      //data.address_2 !== null && (arr[2] = data.address_2);
      //data.address_3 !== null && (arr[3] = data.address_3);
      arr[4] = data.city;
      //arr[5] = data.state;
      //arr[6] = data.country;
      //arr[7] = data.pincode;
      arr[8] = `${data.ownership_percentage}% OwnerShip`;
      return arr;
    }
    case "mutual_funds": {
      let arr = [];
      data.mfList.map((each, i) => {
        if (i <= 2) {
          arr[i] = each.folio_no;
        }
        //arr[i] = i <= 2 ? each.folio_no + " - " + each.amc : false;
      });
      return arr;
    }
    case "stocks_and_etfs": {
      arr[1] = data.client_id;
      arr[2] = data.dp_id;
      return arr;
    }
    case "insurance": {
      arr[0] = data.asset_sub_type;
      arr[1] = data.policy_number;
      arr[2] = data.insurer_company;
      return arr;
    }
    case "bonds": {
      let arr = [];
      data.bondsList.map((each, i) => {
        arr[i] =
          i <= 2
            ? each.bond_id + " - " + each.bond_name + " - " + each.bond_units
            : false;
      });
      return arr;
    }
    case "cash": {
      let arr = [];
      data.cashList.map((each, i) => {
        arr[i] = i <= 2 ? each.amount + " - " + each.currency : false;
      });
      return arr;
    }
    case "sovereign_gold_bond": {
      arr[0] = data.sgb_id;
      arr[1] = data.sgb_demat;
      return arr;
    }
    case "jewellery": {
      let arr = [];
      data.jewelleryList.map((each, i) => {
        arr[i] = i <= 2 ? each.description.substring(0, 100) : false;
      });
      return arr;
    }
    case "others": {
      arr[0] = data.description.substring(0, 100);
      return arr;
    }
    case "art": {
      arr[0] = data.asset_sub_type;
      arr[1] = data.description.substring(0, 100);
      return arr;
    }
    case "automobiles": {
      let arr = [];
      data.vehicleList.map((each, i) => {
        arr[i] =
          i <= 2
            ? each.vehicle_type +
              " - " +
              each.vehicle_brand +
              " - " +
              each.vehicle_number
            : false;
      });
      return arr;
    }
    case "private-stocks": {
      arr[0] = data.asset_sub_type;
      data.stock_detail.map((each, i) => {
        arr[i] =
          i <= 2
            ? each.company_name +
              " - " +
              (each.percent_unit === "percent"
                ? each.no_of_percent + "%"
                : each.no_of_shares + " Shares")
            : false;
      });
      return arr;
    }
    case "gratuity": {
      arr[0] = data.acc_number;
      arr[1] = data.employer_name;
      return arr;
    }
    case "nps": {
      arr[0] = data.pran_number;
      return arr;
    }
    default: {
      return arr;
    }
  }
};
