//import Carousel from "react-bootstrap/Carousel";
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import priya from "../../../assets/images/priya.png";
import Shivajirao from "../../../assets/images/Shivajirao.png";
import { Row } from "react-bootstrap";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 2 },
];

const TestimonialSlider = () => {
  return (
    <>
      <Row className="justify-content-center row-style g-4">
        <div className="col-xxl-4 col-xl-4 col-lg-5">
          <div className="text-center card shadow">
            <img src={Shivajirao} alt={"logo"}/>
            <div className="card-body card-body-style"> 
            <div className="name">
              <b>Shivajirao Patil</b>
            </div>
            <span className="designation">Chief Legal Advisor – Willyourwish <br />
                District & Sessions Judge (Retired) & Senior Legal Consultant</span>
            <p className="mt-3">Mr. Shivajirao Patil is brings with him over 45 years of rich legal experience. As a judge, He has presided over innumerable Succession & Will 
            related cases and has unique understanding of issues and arguments from both sides of Succession disputes. His in-depth knowledge of the 
            succession laws, common issues in wills and deep academic expertise forms the core part of legal drafts generated by willyourwish.{" "}</p>
            </div>
          </div>
        </div>

        <div className="col-xxl-4 col-xl-4 col-lg-5">
          <div className="text-center card shadow">
            <img src={priya} alt={"logo"}/>
            <div className="card-body card-body-style-section"> 
            <div className="name">
              <b>Adv Priya Zoting</b>
            </div>
            <span className="designation">Sr. Legal Counsel – Willyourwish <br />
          Advocate, Counsellor and Psychotherapist</span>
            <p className="mt-3">Adv Priya Zoting is the backbone of Legal Operations and part of the core team that makes will making simple at willyourwish. She brings a very 
          unique combination of experience – She has over 17 years of experience as an Advocate specialising in Family Courts and Succession Laws, she is 
          also a certified Counsellor and a Psychotherapist. Her unique perspective considers Legality along with the emotional and inter-personal issues 
          surrounding Succession planning, Family Matters and Estate Disputes.{" "}</p>
          </div>
          </div>
        </div>
    </Row>
    </>
  );
};

export default TestimonialSlider;
