import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import { initialValues, getFormData, validationSchema } from "./util";
import { willUploadSuccess } from "../../../../route";
import { useNavigate } from "react-router-dom";
import DropBox from "../DropBox";
import { urltoFile, dataURLtoFile } from "../../../../util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const stateKey = "uploadWill";

const DragNDrop = (props) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    props.handleClose();
  };

  const [initial_values, setInitial_values] = useState(initialValues);

  const submitHandler = (values) => {
    

    //if (formik.isValid === true) {
    setLoading(true);
    //const formData = getFormData(values);

    let formData = new FormData();
    formData.append("file", upload);

    props.dispatch({
      type: "uplodWill",
      payload: formData,
      key: stateKey,
    });
    //}
  };

  const formik = useFormik({
    initialValues: initial_values,
    values: initial_values,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (props?.formResult?.success === true) {
      setShow(false);
      setTimeout(() => {
        formik.resetForm();
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });

        navigate(willUploadSuccess);
      }, 1000);
    }
  }, [props?.formResult?.loading]);

  const [upload, setUpload] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      setUpload(file);
      return file;
    });
  }, []);
  return (
    <>
      <Modal
        show={show}
        centered
        className=""
        onHide={() => {
          if (props?.restrictClose) {
            return false;
          } else {
            handleClose();
          }
        }}
        size="xl"
        backdrop="static"
      >
        <FontAwesomeIcon
          icon={faCircleXmark}
          onClick={handleClose}
          className="modalCloseIcon"
        />
        <Modal.Body>
          <DropBox onDrop={onDrop} />
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <button type="submit" className="mt-auto button form-button">
                Continue
              </button>
            </Form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[stateKey] },
  };
})(DragNDrop);
