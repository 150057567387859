import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Worker, Viewer, ProgressBar } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useNavigate, Link } from "react-router-dom";
import { paymentPlans } from "../../../route";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faPencil } from "@fortawesome/free-solid-svg-icons";

const getKey = "createDraft";
const deleteKey = "deleteDraft";

const ViewDraft = (props) => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch({
      type: "createDraft",
      key: getKey,
    });
  }, []);

  useEffect(() => {
    if(props.getData.success==true){
      setLoading(false);
      setShow(true);
    }else if(props.getData.error==true){
      setLoading(false);
    }
  }, [props.getData.data]);

  const closePdfView = () => {
    props.dispatch({
      type: "deleteDraft",
      key: deleteKey,
    });
    props.handleClose();
  };

  useEffect(() => {
    if (props.deleteData.success == true) {
      props.dispatch({
        type: "RESET_GET",
        key: getKey,
      });
      setShow(false);
    }
  }, [props.deleteData.success]);

  const renderPage: RenderPage = (props: RenderPageProps) => {
    return (
      <div style={{ userSelect: "none" }}>
        {props.canvasLayer.children}
        <div
          style={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            justifyContent: "center",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        ></div>
        {props.annotationLayer.children}
        {props.textLayer.children}
      </div>
    );
  };

  console.log('show',show);
  return (
    <>
      <Modal
        show={show}
        centered
        onHide={() => {
          if (props?.restrictClose) {
            return false;
          } else {
            closePdfView();
          }
        }}
        size="xl"
        backdrop="static"
      >
        <FontAwesomeIcon
          icon={faCircleXmark}
          onClick={closePdfView}
          className="modalCloseIcon"
        />
        <Modal.Body>
          {props.getData.error==true && (<>
          <div className="field-error">{props.getData.error_msg}</div>
          </>)}
          {props.getData.success==true && (<>
          <div className="col-md-4 mb-3">
            <b>Will Draft</b>
          </div>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
            <div
              className="viewdraft-pdfviewer custom-scroll"
            >
              {props.getData?.data?.fileLink !== "" &&
                props.getData?.data?.fileLink !== undefined && (
                  <Viewer
                    fileUrl={props.getData?.data?.fileLink}
                    renderLoader={(percentages) => (
                      <div style={{ width: "240px" }}>
                        Loading please wait........
                        <ProgressBar progress={Math.round(percentages)} />
                      </div>
                    )}
                    renderPage={renderPage}
                  />
                )}
            </div>
          </Worker>

          <div className="d-md-flex">
            <div className="col-md-6 col-12 mt-3">
              <b className="text-center mb-0">Instructions:</b>
            </div>
            <div className="col-md-6 col-12 d-flex flex-md-row-reverse mt-2 flex-content-center">
              <a
                href={props?.getData?.data?.downloadLink}
                target="_blank"
                className="btn-2 ms-2"
              >
                Download
              </a>{" "}
              
              {props.getData.data.progress === "unpaid" && (
                <>
                  <Link to={'paymentPlans'} className="btn-2 ms-2">
                    Continue
                  </Link>
                  
                  <Link to={paymentPlans} className="btn-2 ms-2">
                    Continue
                  </Link>
                  <button className="btn-2 me-1 ms-2" onClick={closePdfView}>
                    <FontAwesomeIcon icon={faPencil} />{" "}
                    <span style={{ marginLeft: "0.5rem" }}>Edit</span>
                  </button>
                </>
              )}
            </div>
          </div>
          </>)}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect((state) => {
  return {
    getData: { ...state.GetData[getKey] },
    deleteData: { ...state.GetData[deleteKey] },
    formatData: state.GetData[getKey],
  };
})(ViewDraft);
