import dustbinIcon from "../../../assets/images/dustbin.png";
import ReactTooltip from "react-tooltip";
import styles from "../../../assets/Tooltip.module.css";

const DeleteIconWithToolTip = (props) => {
  return (
    <>
      <img
        src={dustbinIcon}
        className="dltIcon"
        onClick={() => {
          props.handleClick();
        }}
        data-tip="Delete this entry"
      />
      <ReactTooltip
        effect="solid"
        multiline={true}
        place={props.place ? props.place : "top"}
      />
    </>
  );
};
export default DeleteIconWithToolTip;
