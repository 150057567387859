import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../common/Loader/Loader";
import { changeToDMY } from "../../../util";
import { Link,useNavigate } from "react-router-dom";
import { assetSummary, uploadWill, paymentPlans,personalDetail } from "../../../route";
import {
  faFile,
  faArrowUpFromBracket,
  faArrowUp,
  faAngleDown,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import ViewDraft from "../ViewDraft";
import AssetSummaryDropDownItem from "../common/AssetSummaryDropDownItem";
import MakeWillBtn from "../common/MakeWillBtn";
import { Form } from "react-bootstrap";
import { Field, FormikProvider, useFormik } from "formik";
import { Row } from "react-bootstrap";
import axios from "axios";
import { api_base, HTTP_OK, HTTP_VALIDATION_ERROR } from "../../../constants";
const getKey = "current_wills";

const CurrentWill = (props) => {
  const [loading, setLoading] = useState(true);
  const [showDraftPopup, setShowDraftPopup] = useState(false);
  const [willType,setWillType] = useState();
  const [value1,setValue1] = useState(false);
  const [value2,setValue2] = useState(false);
  const [planType,setPlanType] = useState();
  const navigate = useNavigate();
  const draftModal_closeHandler = () => {
    setShowDraftPopup(false);
  };

  const submitHandler = (values) => {
    if (formik.isValid === true) {
    }
  };
  const formik = useFormik({
      onSubmit: submitHandler,
  });

  useEffect(() => {
    props.dispatch({
      type: "get_uploadedWill",
      payload: "current",
      key: getKey,
    });

    const sess_data = sessionStorage.getItem("data");
    const sessionData = JSON.parse(sess_data);
    setWillType(sessionData.will_type)
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [props.List.data]);

  const getStatus = (status) => {
    return status == "paid"
      ? "Upload Pending"
      : status == "download"
      ? "Downloaded"
      : status == "upload"
      ? "Uploaded"
      : status == "receive_final_mail"
      ? "Received final mail"
      : status == "unpaid"
      ? "Unpaid"
      : "";
  };
  var i = 0;

  const viewDraft = () => {
    setShowDraftPopup(true);
  };

  const handleForm = () => {
    const sess_data = sessionStorage.getItem("data");
    const sessionData = JSON.parse(sess_data);
    axios.post(api_base + "/customer/update-will-type-in-user-will", { user_id:sessionData.id,will_type:planType })
        .then(res => {
          var storedData = sessionStorage.getItem("data");
          let myObject = storedData ? JSON.parse(storedData) : {};
          myObject.user_will_type = res.data.data.user_will_type;
          var updatedData = JSON.stringify(myObject);
          sessionStorage.setItem('data', updatedData);
          window.location.reload();
    })
  }

  const basicPlan = ()=> {
    setValue1(true);
    setValue2(false);
    setPlanType(1);
    
  }

  const advancePlan = () =>{
    setValue2(true);
    setValue1(false);
    setPlanType(2);
  }

  return (
    <>
      {loading && <Loader />}
      {showDraftPopup == true && (
        <ViewDraft handleClose={draftModal_closeHandler} />
      )}
      <div className="acc-page-heading">Ongoing/Draft Will</div>
      <div className="main-layout-cont">
        {props.List.data.length > 0 ? (
          props.List.data.map((each, index) => {
            i++;
            return (
              <div key={`willList${index}`} className="mb-6">
                <div className="rowStyle py-2 px-3 d-lg-flex d-md-flex">
                  <div className="col-lg-2 col-md-2 col-sm-12 col-12 my-2">
                    <b>{each.will_name}</b>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 row my-2">
                    <div className="col-3">
                      Status: <br />
                      {getStatus(each.progress)}
                    </div>
                    {/* <div className="col-md-2">
                    {each.finalized == 0 ? (
                      <Link to={paymentPlans} className="d-flex">
                        <FontAwesomeIcon icon={faFile} className="file-icon" />
                        <div>
                          Rs. 100
                          <br />
                          Upgrade
                        </div>
                      </Link>
                    ) : (
                      <div className="greyColor d-flex">
                        <FontAwesomeIcon
                          icon={faFile}
                          className="file-icon greyColor"
                        />
                        <div>
                          Rs. 100
                          <br />
                          Upgrade
                        </div>
                      </div>
                    )}
                    </div> */}

                    <div className="col-3">
                        Type: <br />
                        {each.will_type ==1 ? <div>Basic</div>:<div> Advance</div>}
                    </div>
                    <div className="col-2">
                      {each.progress == "download" ? (
                        <Link to={uploadWill} className="d-flex">
                          <FontAwesomeIcon
                            icon={faArrowUpFromBracket}
                            className="file-icon"
                          />
                          <div>Upload</div>
                        </Link>
                      ) : (
                        <div className="d-flex greyColor">
                          <FontAwesomeIcon
                            icon={faArrowUpFromBracket}
                            className="file-icon greyColor"
                          />
                          <div> Upload</div>
                        </div>
                      )}
                    </div>
                    <div className="col-3 executor-style">Executor</div>
                  </div>
                  
                  <div className="col-lg-4 col-md-4 col-sm-12 d-flex my-2">
                    <div className="col-4">
                      {
                        each.progress == "unpaid" ? 
                        each.will_type != undefined ? each.will_type == 1 ? 
                        <>
                          <Link to={personalDetail} className="d-flex">
                            <FontAwesomeIcon
                              icon={faPencil}
                              className="yellowColor"
                              style={{ margin: "10px" }}
                            />{" "}
                            <div>Edit</div>
                          </Link>
                          </>
                        :
                          <>
                          <Link to={assetSummary} className="d-flex">
                            <FontAwesomeIcon
                              icon={faPencil}
                              className="yellowColor"
                              style={{ margin: "10px" }}
                            />{" "}
                            <div>Edit</div>
                          </Link> 
                          </>
                        : null
                       : (
                        <div className="disabledEdit greyColor d-flex">
                          <FontAwesomeIcon
                            icon={faPencil}
                            className="greyColor editIcon"
                          />{" "}
                          <div>Edit</div>
                        </div>
                      )}
                    </div>
                    {
                      each.will_type == 1 ? 
                      <div className="col-4 btn btn-primary upgrade-Will-button" data-bs-toggle="modal" data-bs-target="#upgradeWill">Upgrade</div>
                      :null
                    }
                    
                    <div className="col-4 text-end">
                      <Dropdown className="willPageDropDown">
                        <Dropdown.Toggle>
                          Download{" "}
                          <FontAwesomeIcon icon={faAngleDown} className="" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#" onClick={viewDraft}>
                            Draft
                          </Dropdown.Item>
                          {each.downloadLink !== null && (
                            <Dropdown.Item href={each.downloadLink}>
                              Signed Copy
                            </Dropdown.Item>
                          )}
                          <AssetSummaryDropDownItem id={each.id} />
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="my-2 font-15">
                  <div className="col-md-8 d-flex" style={{ fontSize: "9px" }}>
                    <div>Created on: {changeToDMY(each.created_at)} </div>
                    {each.updated_at !== null && (
                      <div>| Modified on: {changeToDMY(each.updated_at)} </div>
                    )}
                    {/* <div>
                      Status: <b>{getStatus(each.progress)}</b>
                    </div> */}
                  </div>
                </div>
                <div className="mb-2">
                  <b>Progress:</b>
                </div>
                <div className="progressBar d-flex pt-3 pb-3 ps-lg-3 ps-md-3  ps-sm-3">
                  <div
                    className={`pointer col-sm-4 col-4 ${
                      (each.progress == "paid" ||
                        each.progress == "download" ||
                        each.progress == "upload" ||
                        each.progress == "receive_final_mail") &&
                      "active"
                    }`}
                  >
                    <div className={`pointer-icon start`}></div>
                    <b className="pointer-text start mt-3">Pay</b>
                  </div>
                  <div className={`pointer col-sm-4 col-4 d-flex`}>
                    <div className="col-6">
                      <div
                        className={`pointer-icon start ${
                          (each.progress == "download" ||
                            each.progress == "upload" ||
                            each.progress == "receive_final_mail") &&
                          "active"
                        }`}
                      ></div>
                      <b
                        className={`pointer-text center1 x mt-3 ${
                          (each.progress == "download" ||
                            each.progress == "upload" ||
                            each.progress == "receive_final_mail") &&
                          "active"
                        }`}
                      >
                        Download
                      </b>
                    </div>
                    <div className="d-flex flex-row-reverse col-6">
                      <div
                        className={`pointer-icon end ${
                          (each.progress == "upload" ||
                            each.progress == "receive_final_mail") &&
                          "active"
                        }`}
                      ></div>
                      <b
                        className={`pointer-text center2  d-flex flex-row-reverse ${
                          (each.progress == "upload" ||
                            each.progress == "receive_final_mail") &&
                          "active"
                        }`}
                      >
                        Upload
                      </b>
                    </div>
                  </div>
                  <div
                    className={`pointer col-sm-4 col-4 d-flex flex-row-reverse ${
                      each.progress == "receive_final_mail" && "active"
                    }`}
                  >
                    <div className={`pointer-icon end`}></div>
                    <b className="pointer-text text-end ms-2">
                      Received final mail
                    </b>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div key={`willList0`} className="mb-6">
            <div className="rowStyle pt-3 pb-3 pe-3 ps-3 mb-3 d-flex">
              <div className="col-6">You don't have any current will now</div>
              <div className="col-6 d-flex flex-row-reverse">
                <MakeWillBtn />
              </div>
            </div>
          </div>
        )}
        <div style={{ marginTop: "5rem" }} className="font-14">
          <div className="mb-3">
            <b>Must Read:</b>
          </div>
          <div className="mb-2 readMore">
            What is the Witness eligibility?
            <Link to="#" className="float-end">
              Read Now
            </Link>
          </div>
          <div className="readMore">
            Know about the will's terminologies
            <Link to="#" className="float-end">
              Read Now
            </Link>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div className="modal fade" id="upgradeWill" tabIndex="-1" aria-labelledby="upgradeWillType" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="upgradeWillType">Upgrade Will</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Group className="col-md-10 m-auto">
                  
                    <section className="section-price mt-lg-5 mt-md-4 mt-sm-3">
                      <div className="">
                        <Row>
                          <div className="col-md-6">
                            
                          
                            <div className="card">
                              <div className="card-body">
                                <div className="pb-3 head">
                                <div className="d-flex mb-4"> 
                              <Form.Check
                                value="basic_will"
                                type="radio"
                                onChange={()=>basicPlan()}
                                checked={value1}
                              />
                              <b>Basic Plan</b>
                            </div>
                                </div>
                                <div className="pb-3 price">{/* <b>Rs. 100</b> */}</div>
                                <p>
                                You should opt for a Simple WIll if:{" "}
                                </p>
                                <ul>
                                  <li>You want to nominate only one beneficiary for everything you have</li>
                                  <li>You do not want to individually mention each asset you own</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card">
                              <div className="card-body">
                                <div className="pb-3 head">
                                <div className="d-flex mb-4"> 
                              <Form.Check
                                value="advance_will"
                                type="radio"
                                onChange={()=>advancePlan()}
                                checked={value2}
                              />
                              <b>Advance Plan</b>
                            </div>
                                </div>
                                <div className="pb-3 price">{/* <b>Rs. 100</b> */}</div>
                                <p>
                                  You should opt for an advance will if:{" "}
                                </p>
                                <ul>
                                  <li>You want to nominate multiple beneficiaries</li>
                                  <li>You want to have multiple beneficiary for one or more assets you may have</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </section>
                    
                    </Form.Group>
                </Form>
            </FormikProvider>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={()=>handleForm()}>Update</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    List: { ...state?.GetData?.[getKey] },
  };
})(CurrentWill);
