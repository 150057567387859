import React from "react";

const Page404 = (props) => {
  return (
    <section className="layout">
      <div className="w-100">
        <div className="col-md-6">
          <h1 className="home-heading">404 Error </h1>
          <p>Page Not found</p>
        </div>

        <div className="col-md-6 home-left-box">
          <div className="home-left-box-inner">
            <div className="home-img1"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Page404;
