import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Navbar, Container, Row } from "react-bootstrap";
import { validationSchema, initialValues } from "./Util";
import { connect } from "react-redux";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import { useFormik, Field, FormikProvider } from "formik";
import loginImage from "../../../assets/images/login.png";
import { login } from "../../../route";
import Layout3 from "../../layout/Layout3";

const stateKey = "ForgotPass";

function ForgotPassword(props) {
  //const [captchaError, setCaptchaError] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitHandler = (values) => {
    // if (validateCaptcha(values.user_captcha) == true) {
    setLoading(true);
    props.dispatch({
      type: "Forgot_Pass",
      payload: {
        email: values.email,
      },
      key: stateKey,
    });
    // } else {
    //   setCaptchaError(true);
    // }
    
    // values.user_captcha = "";
  };

  var formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
    enableReinitialize: true,
  });

  useEffect(() => {
    setLoading(false);

    if (props.formResult.success === true) {
      setTimeout(() => {
        formik.resetForm();
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
        window.location.href = "/";
      }, 3000);
    }
  }, [props?.formResult?.success, props?.formResult?.error]);

  return (
    <Layout3>
      {loading === true && <Loader />}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {props?.formResult?.success === true && (
        <CustomModal
          body={props.formResult.message}
          stateKey={stateKey}
          type="success"
        />
      )}

      <div className="main-layout d-flex">
        <div className="col-md-6 mt-3 text-center">
          <img src={loginImage} className="login-img" />
        </div>
        <div className="col-md-6">
          <h1 className="login-title">Forgot Password</h1>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} className="login-form">
              <Form.Group className="col-md-10 mb-2">
                <Field name="email">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="email"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Email ID"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
              <Row className="text-md-start text-center">
                <div className="col-md-6 col-sm-12 mt-2">
                  <button className="button mt-auto" type="submit">
                    Reset Password
                  </button>
                </div>
                <div className="col-md-4 col-sm-12">
                  <Link
                    to={login}
                    className="d-md-flex flex-md-row-reverse reg-now-link yellowColor"
                  >
                    Cancel
                  </Link>
                </div>
              </Row>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </Layout3>
  );
}
export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[stateKey] },
  };
})(ForgotPassword);
