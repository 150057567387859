import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { faCircleCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SuccessModal = (props) => {
  const handleClose = () => {
    //setShow(false);
    props.handleClose();
  };
  const notify = () => {
    toast(
      <div className="successModal">
        {/* <div className="modal-body"></div>
        abcccccc */}
        <Modal.Body>
          <FontAwesomeIcon icon={faCircleCheck} /> Success
          <FontAwesomeIcon
            icon={faXmark}
            onClick={handleClose}
            style={{ float: "right" }}
          />
          {props?.body && <div className="text">{props?.body}</div>}
        </Modal.Body>
      </div>
    );
  };

  useEffect(() => {
    notify();
  }, []);

  return <></>;
};
export default SuccessModal;
