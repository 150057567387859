import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader/Loader";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getkey = "executorList";
const stateKey = "add_executor_to_will";

const ExecutorList = (props) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch({
      type: "get_executor_list",
      key: getkey,
    });
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [props.list.data]);

  const selectExe = (selectedKey) => {
    props.setSelectedExe(props.list.data[selectedKey]);
  };

  const deSelectExe = () => {
    props.setSelectedExe({});
  };
  var nameList =
    props?.list?.data.length > 0 &&
    props.list.data.map((each, key) => {
      return (
        <span key={key}>
          <cite
            onClick={() => {
              selectExe(key);
            }}
            style={{ fontStyle: "normal" }}
          >
            {each.name}
          </cite>
          <FontAwesomeIcon
            icon={faXmark}
            className="cancel-icon"
            onClick={() => {
              deSelectExe(each.name);
            }}
          />
        </span>
      );
    });

  return (
    <>
      {loading && <Loader />}
      <div className="name-list">{nameList}</div>
    </>
  );
};

export default connect((state) => {
  return {
    list: { ...state?.GetData?.[getkey] },
  };
})(ExecutorList);
