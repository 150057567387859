import MainLayout from "../layout/Mainlayout";
import { accountSetting, view_draft } from "../../route";
import { Link } from "react-router-dom";
import GobackArrow from "./common/GobackArrow";
export default () => {
  return (
    <MainLayout>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow goto={view_draft} />
          Document Successfully uploaded
        </div>
      </div>
      <div className="main-layout-cont">
        <p className="col-md-8">
          Please upload the signed document here for registration. The process
          would take about 2 days.The final document will be mailed to you on
          your registered mail ID.
        </p>
        <h3 className="layout__headings">Instructions/Next Steps:</h3>
        <div className="col-md-9">
          <ol className="pay-success-ol">
            <li>
              Once you have uploaded the document, you can view the will on the
              dashboard.{" "}
            </li>
            <li>You are allowed to make changes to your will.</li>
            <li>
              If you want to send notifications to your nominee you can turn on
              notification from accounts.{" "}
            </li>
            <li>You can track your activities on the dashboard.</li>
            <li>
              You can download the asset summary, signed copy, and draft from
              the dashboard.
            </li>
          </ol>
        </div>
      </div>
      <div>
        <Link to={accountSetting}>
          <button className="mt-auto button">Done</button>
        </Link>
      </div>
    </MainLayout>
  );
};
