import * as Yup from "yup";
import { notOnlyWhiteSpace, alphanumericWithSpecial } from "../../../constants";
import { changeToDMY } from "../../../util";

export const initialValues = {
  name: "",
  father_name: "",
  relation: "",
  other_relation: "",
  gender: "",
  marital_status: "",
  proof_type: "",
  proof_detail: "",
  dob: "",
  religion: "",
  p_add: "",
  p_pincode: "",
  p_city: "",
  p_state: "",
  p_country: "",
  
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("*Name is required")
    .min(2, "*Name must have at least 2 characters")
    .max(40, "*This can't be longer than 40 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  p_add: Yup.string()
    .max(200, "*This can't be longer than 200 characters")
    .required("*This is required"),
  father_name: Yup.string()
    .required("*Name is required")
    .min(2, "*Name must have at least 2 characters")
    .max(40, "*Name can't be longer than 40 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  religion: Yup.string()
    .max(40, "*This can't be longer than 40 characters")
    .required("*This is required"),
  marital_status: Yup.string()
    .max(40, "*This can't be longer than 40 characters")
    .required("*This is required"),
  gender: Yup.string()
    .max(15, "*This can't be longer than 15 characters")
    .required("*This is required"),
  dob: Yup.string().required("*This is required"),
  relation: Yup.string()
    .max(40, "*This can't be longer than 40 characters")
    .required("*This is required"),
  proof_type: Yup.string()
    .max(40, "*This can't be longer than 40 characters")
    .required("*This is required"),
  proof_detail: Yup.string()
    .max(40, "*This can't be longer than 40 characters")
    .required("*This is required")
    .matches(alphanumericWithSpecial, "*Invalid ID Proof detail"),


});

export const getFormData = (values) => {
  let formData = new FormData();

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  formData.append("dob", changeToDMY(values.dob));

  return formData;
};
