import PersonalDetailForm from "../../components/PersonalDetailForm";
import MainLayout from "../../layout/Mainlayout";
import UserCategory from "../UserCategory";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import GobackArrow from "../common/GobackArrow";
import { personalDetail } from "../../../route";

const stateKey = "user_category";
const PersonalDetail = (props) => {
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  let data = sessionStorage.getItem("data");
  const sessionData = JSON.parse(data);

  useEffect(()=>{
    if (sessionData.religion !== null &&
    sessionData.nationality !== null &&
      sessionData.religion !== "Muslim" &&
      sessionData.nationality !== "Other Nationality" 
    ) {
      setShowPersonalInfo(true);
      window.scrollTo(0, 0);
    }
  },[])

  useEffect(() => {
    if (
      props.categoryResult.error === true ||
      props.categoryResult.success === true
    ) {
      // setLoading(false);
    }
    if (props.categoryResult.success === true) {
      setTimeout(() => {
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
        if (
          sessionData.religion !== "Muslim" &&
          sessionData.nationality !== "Other Nationality"
        ) {
          setShowPersonalInfo(true);
          window.scrollTo(0, 0);
        }
      }, 1000);
    }
  }, [props.categoryResult.success, props.categoryResult.error]);

  return (
    <MainLayout>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow goto={personalDetail} /> Personal Details
        </div>
      </div>
      <div className="main-layout-cont">
        {showPersonalInfo === false && <UserCategory />}
        {showPersonalInfo === true && <PersonalDetailForm />}
      </div>
    </MainLayout>
  );
};
export default connect((state) => {
  return {
    categoryResult: { ...state.Forms[stateKey] },
  };
})(PersonalDetail);
