const Item = (props) => {
  return (
    <div className="card">
      <div className="card-body d-lg-flex d-md-flex">
        <div className="serialNo col-lg-4 col-md-4 col-sm-12">
          {props.no} <br /> {props.title}
        </div>
        <p className="col-lg-8 col-md-8 col-sm-12">{props.children}</p>
      </div>
    </div>
  );
};
export default Item;
