import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  addyourwish,
  personalDetail,
  selectAsset,
  addAsset,
  assetSummary,
  paymentPlans,
  paymentSuccess,
  uploadWill,
  willUploadSuccess,
  view_draft,
  addNominee
} from "../../route";

const Leftmenu = () => {
  const firstPath = window.location.pathname.split("/")[1];
  const [payTabActive, setPayTabActive] = useState(false);
  const [disable, setDisable] = useState(false);

  let data = sessionStorage.getItem("data");
  const sessionData = JSON.parse(data);

  useEffect(() => {
    sessionData.religion === "Muslim" || sessionData.nationality !== "Indian"
      ? setDisable(true)
      : setDisable(false);
  }, [sessionData]);

  useEffect(() => {
    if (
      `/${firstPath}` === paymentPlans ||
      `/${firstPath}` === paymentSuccess ||
      `/${firstPath}` === uploadWill ||
      `/${firstPath}` === willUploadSuccess
    ) {
      setPayTabActive(true);
    }
  }, [firstPath]);

  return (
    <div className="body_cont_side_bar col-md-2 col-lg-2 p-0 col-sm-12 col-12">
      <div className="left-menu-container">
        <ul>
          {/* <li className={`/${firstPath}` === aadharpage ? "active" : ""}>
            <Link to={aadharpage}>
              <span className="left-menu-icon">1</span>
              <span className="left-menu-text">Aadhaar Card Validation</span>
            </Link>
          </li> */}
          <li className={`/${firstPath}` === personalDetail ? "active" : ""}>
            <Link to={personalDetail}>
              <span className="left-menu-icon">1</span>
              <span className="left-menu-text">Personal Info</span>
            </Link>
          </li>
          {
            sessionData !== null && sessionData !== undefined 
            ? sessionData.will_type == 1 ?
              <li className={`/${firstPath}` === addyourwish ? "active" : ""}>
                <Link
                  to={disable == true ? "#" : addNominee}
                  className={disable ? "disabled" : ""}
                >
                  <span className="left-menu-icon">2</span>
                  <span className="left-menu-text">
                    Add Asset and beneficiaries
                  </span>
                </Link>
              </li>
              :
              <li className={(`/${firstPath}` === selectAsset || `/${firstPath}/:asset_type` === addAsset) ? "active" : ""}>
                  <Link
                    to={disable == true ? "#" : selectAsset}
                    className={disable ? "disabled" : ""}
                  >
                    <span className="left-menu-icon">2</span>
                    <span className="left-menu-text">
                      Add Asset and beneficiaries
                    </span>
                  </Link>
              </li>
                
                : null
            
          }
          
            <li className={`/${firstPath}` === assetSummary ? "active" : ""}>
            <Link
              to={disable == true ? "#" : assetSummary}
              className={disable ? "disabled" : ""}
            >
              <span className="left-menu-icon">3</span>
              <span className="left-menu-text">Summary and save as draft</span>
            </Link>
          </li>
          
          {
            sessionData.will_type == 2 ?
            <li className={`/${firstPath}` === addyourwish ? "active" : ""}>
              <Link
                to={disable == true ? "#" : addyourwish}
                className={disable ? "disabled" : ""}
              >
                <span className="left-menu-icon">4</span>
                <span className="left-menu-text">Add your wish</span>
              </Link>
            </li>
          : null
          }
          <li className={`/${firstPath}` === view_draft ? "active" : ""}>
            <Link
              to={disable == true ? "#" : view_draft}
              className={disable ? "disabled" : ""}
            >
              <span className="left-menu-icon">{
                sessionData.will_type == 1 ? 4 : 5
              }</span>
              <span className="left-menu-text">View Draft</span>
            </Link>
          </li>
          <li className={payTabActive === true ? "active" : ""}>
            <Link
              to={disable == true ? "#" : paymentPlans}
              className={disable ? "disabled" : ""}
            >
              <span className="left-menu-icon">{
                sessionData.will_type == 1 ? 5 : 6
              }</span>
              <span className="left-menu-text">Pay and Legalise</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Leftmenu;
