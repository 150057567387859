import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, useFormik, FormikProvider } from "formik";
import {
  initialValues,
  validationSchema,
  getFormData,
  mapNomineeList,
} from "./util";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import { view_draft } from "../../../route";
import IDProofDropdown from "../common/IDProofDropdown";
import RelationDropdown from "../common/RelationDropdown";
import GenderDropDown from "../common/GenderDropDown";
import MaritalStatusDropDown from "../common/MaritalStatusDropDown";
import CountryStateCityPincode from "../common/CountryStateCityPincode";
import MainLayout from "../../layout/Mainlayout";
import SubmitBtn from "../common/SubmitBtn";
import ExecutorList from "../ExecutorList";

const stateKey = "AddExecutor";

const AddExecutor = (props) => {
  const [initial_values, setInitial_values] = useState(initialValues);
  const [selectedExe, setSelectedExe] = useState({});
  const navigate = useNavigate();
  // const [captchaError, setCaptchaError] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
    //loadCaptchaEnginge(6);
  }, []);

  const submitHandler = (values) => {
    formik.validateForm();
    if (formik.isValid === true) {
      const { user_captcha, ...rest } = values;
      const formData = getFormData(rest);
      if (selectedExe.id !== undefined) {
        formData.append("selectedExe", selectedExe.id);
      }

      props.dispatch({
        type: "add_executor_to_will",
        payload: formData,
        key: stateKey,
      });
    }
  };
  const formik = useFormik({
    initialValues: initial_values,
    values: initial_values,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (props.formResult.success == true) {
      setTimeout(function () {
        navigate(view_draft);
      }, 1000);
    }
  }, [props.formResult.success]);

  

  useEffect(() => {
    if (Object.keys(selectedExe).length !== 0) {
      setInitial_values({
        ...initial_values,
        name: selectedExe.name,
        father_name: selectedExe.father_name,
        relation: selectedExe.relation,
        other_relation: selectedExe.other_relation,
        gender: selectedExe.gender,
        marital_status: selectedExe.marital_status,
        proof_type: selectedExe.proof_type,
        proof_detail: selectedExe.proof_detail,
        dob: selectedExe.dob,
        religion: selectedExe.religion,
        p_add: selectedExe.address_detail.address,
        p_pincode: selectedExe.address_detail.postal_code,
        p_city: selectedExe.address_detail.city,
        p_state: selectedExe.address_detail.state,
        p_country: selectedExe.address_detail.country,
      });
    } else {
      setInitial_values(initialValues);
    }
  }, [selectedExe]);

  return (
    <MainLayout>
      {loading && <Loader />}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {props?.formResult?.success === true && (
        <CustomModal
          body={props.formResult.message}
          stateKey={stateKey}
          type="success"
        />
      )}

      <div className="layout__headings">
        <div className="page-heading">Add Executor </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mx-0">
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Name</Form.Label>
                <Field name="name">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error}
                        value={field.value ? field.value : ""}
                      />
                      {meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
                <ExecutorList setSelectedExe={setSelectedExe} />
              </Form.Group>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Father Name</Form.Label>
                <Field name="father_name">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error}
                        value={field.value ? field.value : ""}
                      />
                      {meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Relationship</Form.Label>
                <RelationDropdown formik={formik} name="relation" />
              </Form.Group>
            </Row>
            <Row className="mx-0">
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Gender</Form.Label>
                <Field name="gender">
                  {({ field, meta, form }) => (
                    <GenderDropDown
                      validation={{
                        ...field,
                        isInvalid: !!meta.error,
                        error: meta.error,
                        form: form,
                      }}
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Marital Status</Form.Label>
                <Field name="marital_status">
                  {({ field, meta, form }) => (
                    <MaritalStatusDropDown
                      validation={{
                        ...field,
                        isInvalid: !!meta.error,
                        error: meta.error,
                        form: form,
                      }}
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>ID Proof Type</Form.Label>
                <Field name="proof_type">
                  {({ field, meta, form }) => {
                    return (
                      <IDProofDropdown
                        validation={{
                          ...field,
                          isInvalid: !!meta.error,
                          error: meta.error,
                          form: form,
                        }}
                      />
                    );
                  }}
                </Field>
              </Form.Group>
            </Row>
            <Row className="mx-0">
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>ID Proof Detail</Form.Label>
                <Field name="proof_detail">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error}
                        value={field.value ? field.value : ""}
                      />
                      {meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Date of Birth</Form.Label>
                <Field name="dob">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="date"
                        autoComplete="off"
                        isInvalid={!!meta.error}
                        value={field.value ? field.value : ""}
                      />
                      {meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Religion</Form.Label>
                <Field name="religion">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error}
                        value={field.value ? field.value : ""}
                      />
                      {meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>

            <Row className="col-md-12 mx-0">
              <Form.Group className="mb-3">
                <Form.Label>Permanent Address</Form.Label>
                <Field name="p_add">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        as="textarea"
                        autoComplete="off"
                        isInvalid={!!meta.error}
                        value={field.value ? field.value : ""}
                      />
                      {meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>

              <CountryStateCityPincode
                setInitial_values={setInitial_values}
                formik={formik}
                fieldname={{
                  pin: "p_pincode",
                  country: "p_country",
                  state: "p_state",
                  city: "p_city",
                }}
              />
            </Row>
            <Row className="mx-0 mb-3">
              <div className="col-6">
                <button
                  className="button"
                  onClick={() => {
                    navigate(view_draft);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="col-6">
                <SubmitBtn text="Save" />
              </div>
            </Row>
          </Form>
        </FormikProvider>
      </div>
    </MainLayout>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[stateKey] },
  };
})(AddExecutor);
