import { Container, Row } from "react-bootstrap";
import MakeWillBtn from "../components/common/MakeWillBtn";
import { blogs, support, faq } from "../../route";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../route";
import Header from "../layout/Header";

const BurgerMenuPage = (props) => {
  let data = sessionStorage.getItem("data");
  const dataobj = JSON.parse(data);
  const navigate = useNavigate();
  return (
    <>
      <div
        className="hamburger-page"
        style={{
          opacity: !props.isOpen ? "0" : "1",
          transition: "all .2s",
          visibility: !props.isOpen ? "hidden" : "visible",
        }}
      >
        <Header setIsOpen={props.setIsOpen} isOpen={props.isOpen} />
        <Container>
          <section className="main-section">
            <Row className="my-lg-5 my-md-4 my-sm-3 burgerPageContent">
              <div className="col-lg-7 col-md-12 col-sm-12 sectionLeft">
                <ul>
                  <li>
                    <Link
                      to={blogs}
                      className="text-decoration-none text-reset"
                    >
                      Blogs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={support}
                      className="text-decoration-none text-reset"
                    >
                      Support Learn
                    </Link>
                  </li>
                  <li>
                    <Link to={faq} className="text-decoration-none text-reset">
                      FAQ
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 sectionRight">
                <h1>
                  Create quick and <br /> hassle free will{" "}
                </h1>
                {dataobj !== null &&
                dataobj?.currentWillProgress !== "unpaid" ? (
                  <MakeWillBtn />
                ) : (
                  <>
                    <div className="will-style">
                    <button
                      onClick={() => {
                        navigate(login);
                      }}
                      className="mt-auto button bottom-space"
                    >
                      Start making will
                    </button>
                    </div>
                  </>
                )}
              </div>
            </Row>
          </section>
          <section className="text-lg-center text-md-center footer">
            <Row>
              <div className="col-lg-4">Call us on: +919820379057</div>
              <div className="col-lg-4">
                Mail us on: vaibhav@willyourwish.com
              </div>
              <div className="col-lg-4">Privacy Policy</div>
            </Row>
          </section>
        </Container>
      </div>
    </>
  );
};

export default BurgerMenuPage;
