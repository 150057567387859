import React, { useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, FormikProvider } from "formik";
import { initialValues, validationSchema, getFormData } from "./util";
import SubmitBtn from "../../common/SubmitBtn";
import FieldWithTooltip from "../../common/FieldWithTooltip";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";
const editAssetKey = "EditAssetDetail";

const PPF = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} PPF
        </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <FieldWithTooltip
                  label="PPF Account Number"
                  name="acc_number"
                  text="You can find this on your bank passbook/ Bank account statement"
                />
              </Form.Group>

              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <FieldWithTooltip
                  label="Account held at"
                  name="acc_via"
                  text="Is your PPF account in post Office or a bank?"
                  placeholder="India Post/ name of your bank"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="col-md-6 col-sm-12 mb-3">
                <Form.Label>Branch Name</Form.Label>
                <Field name="branch_name">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>

            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
    assetDetail: { ...state.GetData[editAssetKey] },
  };
})(PPF);
