import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, FormikProvider } from "formik";
import { initialValues, validationSchema, asset_sub_type } from "./util";
import SubmitBtn from "../../common/SubmitBtn";
import { Radio } from "@palmerhq/radio-group";
import { FRadioGroup } from "../../common/RadioButtons";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";

const Art = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} Art
        </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
              <Form.Group className="col-md-12 mb-3">
                <Form.Label>Choose Art Type</Form.Label>
                <FRadioGroup name="asset_sub_type" autoFocus={false}>
                  {asset_sub_type.map((each, index) => {
                    return (
                      <Radio key={`option${index}`} value={each}>
                        {each}
                      </Radio>
                    );
                  })}
                </FRadioGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group className="col-md-12">
                <Form.Label>Description</Form.Label>
                <Field name="description">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        as="textarea"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Add detail of the painting you owe"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(Art);
