import Carousel from "react-bootstrap/Carousel";
import { createRef, useCallback, useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownLong } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Videosection from "../components/LandingPage/Videosection";
import landingPageImg from "../../assets/images/landingPageImg.png";

const getKey = "LandingVideos";

const Design = (props) => {
  const [vidOne, setVidOne] = useState(null);
  const [vidTwo, setVidTwo] = useState(null);
  const [vidThree, setVidThree] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [index, setIndex] = useState(null);
  const sliderRef = createRef();

  const changeChild = useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        return activeSlide == 0 ? false : sliderRef?.current?.prev();
      } else if (e.key === "ArrowRight") {
        return activeSlide == 4 ? false : sliderRef?.current?.next();
      }
    },
    [sliderRef]
  );
  const scroll = useCallback(
    (y) => {
      if (y > 0) {
        return activeSlide == 4 ? false : sliderRef?.current?.next();
      } else {
        return activeSlide == 0 ? false : sliderRef?.current?.prev();
      }
    },
    [sliderRef]
  );

  useEffect(() => {
    window.addEventListener("wheel", (e) => {
      scroll(e.deltaY);
    });
  }, [scroll]);

  useEffect(() => {
    // props.dispatch({
    //   type: "RESET_SHADOW_HEADER",
    //   payload: false,
    //   key: "shadowHeader",
    // });

    props.dispatch({
      type: "get_landingPage_videos",
      key: getKey,
    });
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", changeChild);

    // return function cleanup() {
    //   document.removeEventListener("keydown", changeChild);
    // };
  }, [changeChild]);

  useEffect(() => {
    if (props.videos.success == true && props.videos.data.length > 0) {
      setVidOne(
        props.videos.data[0].filepath + "/" + props.videos.data[0].filename
      );
      setVidTwo(
        props.videos.data[0].filepath + "/" + props.videos.data[1].filename
      );
      setVidThree(
        props.videos.data[0].filepath + "/" + props.videos.data[2].filename
      );
    }
  }, [props.videos]);

  const changeHandler = (prop) => {
    if (prop.checked == true) {
      setTimeout(() => {
        return sliderRef?.current?.next();
      }, 1000);
    }
  };

  return (
    <section className="landing-pagee">
      <Carousel
        keyboard={true}
        indicators={true}
        interval={null}
        ref={sliderRef}
        controls={false}
        indicatorLabels={[1, 2, 3, 4]}
        onSelect={(e) => {
          setActiveSlide(e);
        }}
      >
        <Carousel.Item>
          <section className="pt-5 pb-5 ">
            <div className="row">
              <div className="col-6 pe-5">
                <div className="heading">
                  Ever wondered how easy <br /> and quick is will making?
                </div>
                <p className="mt-4 mb-5">
                  In India, people are yet uneducated about the will making
                  concept, we at willyouwish teaches you each terminology and
                  makes the process very easy.
                </p>
                <button className="button">Learn about it</button>
              </div>
              <div className="col-6">
                <img src={landingPageImg} />
              </div>
            </div>
          </section>
        </Carousel.Item>
        <Carousel.Item>
          <section className="pt-5 pb-5">
            <div className="row">
              <div className="col-6 pe-5">
                <div className="heading">Add Details of your assets</div>
                <p className="mt-4 mb-5">
                  Select your asset and fill in your asset details. All you need
                  to do is select asset type from the list provided
                </p>
              </div>
              <div className="col-6">
                {vidOne !== null && <Videosection vidurl={vidOne} />}
              </div>
            </div>
          </section>
        </Carousel.Item>
        <Carousel.Item>
          <section className="pt-5 pb-5">
            <div className="row">
              <div className="col-6 pe-5">
                <div className="heading">Add percentage to your nominees</div>
                <p className="mt-4 mb-5">
                  Add your nominees name, their relation with the will holder
                  and percentage. You can completly edit the name, relation and
                  percentage anytime.
                </p>
              </div>
              <div className="col-6">
                {vidTwo !== null && <Videosection vidurl={vidTwo} />}
              </div>
            </div>
          </section>
        </Carousel.Item>
        <Carousel.Item>
          <section className="pt-5 pb-5">
            <div className="row">
              <div className="col-6 pe-5">
                <div className="heading">The will is created instantly</div>
                <p className="mt-4 mb-5">
                  The will is made instantly online. All the legal matters are
                  formed in pdf format and can be downloaded once paid. These
                  are fully trusted legal documents.
                </p>
              </div>
              <div className="col-6">
                {vidThree !== null && <Videosection vidurl={vidThree} />}
              </div>
            </div>
          </section>
        </Carousel.Item>
        <Carousel.Item>
          <section className="pt-5 pb-5">
            <div className="row">
              <div className="col-6 pe-5">
                <div className="heading">Get printed, sign it</div>
                <p className="mt-4 mb-5">
                  Get your legal documents printed. Sign on those documents and
                  get it notarized by us. Your will making process would be
                  complete and you can still access it online.
                </p>
              </div>
              <div className="col-6 text-center pt-5">
                <div>
                  <FontAwesomeIcon
                    icon={faArrowDownLong}
                    className="diveInIcon"
                    onClick={() => {
                      props.setShowWebsite(true);
                    }}
                  />
                </div>
                <div
                  className="diveIn fw-bold"
                  onClick={() => {
                    props.setShowWebsite(true);
                  }}
                >
                  Dive in to <br /> know more
                </div>
              </div>
            </div>
          </section>
        </Carousel.Item>
      </Carousel>
    </section>
  );
};

export default connect((state) => {
  return {
    videos: { ...state.GetData[getKey] },
  };
})(Design);
