import * as Yup from "yup";
import { notOnlyWhiteSpace } from "../../../../constants";

export const initialValues = {
  asset_sub_type: "Painting",
  description: "",
  
};
export const asset_sub_type = ["Painting", "Memorabilia", "Art"];
export const validationSchema = Yup.object().shape({
  description: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(200, "*This can't be longer than 200 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),


});

export const getFormData = (values, asset_type) => {
  let formData = new FormData();

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  formData.append("asset_type", asset_type);
  return formData;
};
