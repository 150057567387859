import { createStore, applyMiddleware } from "redux";
import Reducers from "./RootReducer";
import RootSaga from "./RootSaga";
import createSaga from "redux-saga";
import thunk from "redux-thunk";

var sagaMiddleware = createSaga();
var middlewares = applyMiddleware(thunk, sagaMiddleware);

export default createStore(Reducers, middlewares);
sagaMiddleware.run(RootSaga);
