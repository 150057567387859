import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { faCircleXmark, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ErrorModal = (props) => {
  const [show, setShow] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const handleClose = () => {
    setShow(false);
    props.handleClose();
  };

  const customClass = "";
  let moreErrors =
    props.moreError !== undefined && props.moreError.length > 0
      ? props.moreError.map((each, i) => {
          i++;
          return (
            <React.Fragment key={i}>
              <div className="py-2 border-bottom text-left">{`${i}. ${each}`}</div>
            </React.Fragment>
          );
        })
      : [];

  return (
    <>
      <Modal
        show={show}
        className="ErrorModal"
        onHide={() => {
          if (props?.restrictClose) {
            return false;
          } else {
            props.handleModalclose();
          }
        }}
      >
        <Modal.Body>
          <FontAwesomeIcon icon={faCircleXmark} onClick={handleClose} /> Error
          <FontAwesomeIcon
            icon={faXmark}
            onClick={handleClose}
            style={{ float: "right" }}
          />
          {props?.body && showMore == false && (
            <div className="errorText">{props?.body}</div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ErrorModal;
