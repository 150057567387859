import PersonalDetailForm from "../../components/PersonalDetailForm";

const Account = () => {
  return (
    <>
      <div className="acc-page-heading">General Info </div>
      <div className="main-layout-cont">
        <PersonalDetailForm />
      </div>
    </>
  );
};
export default Account;
