import { Row, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useFormik, FormikProvider, Field } from "formik";
import {
  initialValues_popup,
  validationSchema_popup,
  getPopupFormData,
} from "./util";
import { connect } from "react-redux";
import CustomModal from "../common/CustomModal";
import { customiseFormSuccessMsg } from "../../../util";
import Loader from "../common/Loader/Loader";
import ContactForm from "./ContactForm";

const stateKey = "contactForm";
const MuslimPopup = (props) => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    props.closeHandler(false);
    setShow(false);
  };

  const submitHandler = (values) => {
    

    if (formik.isValid === true) {
      setShow(false);
      setLoading(true);
      const formData = getPopupFormData(values);
      props.dispatch({
        type: "contact_form",
        payload: formData,
        key: stateKey,
      });
    }
    // } else {
    //   setCaptchaError(true);
    // }
    
    
  };

  const formik = useFormik({
    initialValues: initialValues_popup,
    values: initialValues_popup,
    validationSchema: validationSchema_popup,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (props.formResult.error === true || props.formResult.success === true) {
      setLoading(false);
      setShow(false);
    }
    if (props.formResult.success === true) {
      setTimeout(() => {
        props.closeHandler(false);
        //formik?.resetForm();
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
      }, 3000);
    }
  }, [props.formResult.success, props.formResult.error]);

  return (
    <>
      {/* {loading && <Loader />} */}

      {loading && ""}

      {props?.formResult?.success === true && (
        <CustomModal
          body={customiseFormSuccessMsg(props.formResult.message)}
          stateKey={stateKey}
          type="success"
        />
      )}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      <Modal
        show={show}
        centered
        className="executorPopup"
        onHide={() => {
          if (props?.restrictClose) {
            return false;
          } else {
            handleClose();
          }
        }}
        size="xl"
        backdrop="static"
      >
        <FontAwesomeIcon
          icon={faCircleXmark}
          onClick={handleClose}
          className="modalCloseIcon"
        />
        <Modal.Body>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} className="main-layout-cont">
              <div className="col-md-12 mb-2">
                <b>Looks like you need a helping hand</b>
              </div>
              <p>
                We are yet to publish Wills under Muslim Law at willurwish.com.
                We are hard at work studying the Muslim law and drafting the
                easiest and most accurate process to make a will compatible with
                the Muslim Law. This should be available soon and will allow you
                to make your own will quickly.
              </p>
              <p>
                But don’t worry,
                <b>You can still make a will on willyourwish.com</b>. Please
                leave your details in the form below. We will get one of our
                expert Lawyer partners who are qualified in muslim law, to help
                you draft the will personally. Once you leave the details, our
                team will call you and setup an online consultation appointment.
              </p>
              <ContactForm />
              {/* <Row>
                <Form.Group className="col-md-4 col-12 mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Field name="full_name">
                    {({ field, meta }) => (
                      <>
                        <Form.Control
                          {...field}
                          autoComplete="off"
                          isInvalid={!!meta.error && !!meta.touched}
                          value={field.value ? field.value : ""}
                        />
                        {meta.touched && meta.error && (
                          <div className="field-error">{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                </Form.Group>
                <Form.Group className="col-md-4 col-12 mb-3">
                  <Form.Label>Mobile Number</Form.Label>
                  <Field name="mobile">
                    {({ field, meta }) => (
                      <>
                        <Form.Control
                          {...field}
                          autoComplete="off"
                          isInvalid={!!meta.error && !!meta.touched}
                          value={field.value ? field.value : ""}
                        />
                        {meta.touched && meta.error && (
                          <div className="field-error">{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                </Form.Group>
                <Form.Group className="col-md-4 col-12 mb-3">
                  <Form.Label>Email</Form.Label>
                  <Field name="email">
                    {({ field, meta }) => (
                      <>
                        <Form.Control
                          {...field}
                          type="email"
                          autoComplete="off"
                          isInvalid={!!meta.error && !!meta.touched}
                          value={field.value ? field.value : ""}
                        />
                        {meta.touched && meta.error && (
                          <div className="field-error">{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="col-md-4 col-12 mb-3">
                  <Form.Label>City</Form.Label>
                  <Field name="city">
                    {({ field, meta }) => (
                      <>
                        <Form.Control
                          {...field}
                          autoComplete="off"
                          isInvalid={!!meta.error && !!meta.touched}
                          value={field.value ? field.value : ""}
                        />
                        {meta.touched && meta.error && (
                          <div className="field-error">{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                </Form.Group>
                <Form.Group className="col-md-4 col-12 mb-3">
                  <Form.Label>State</Form.Label>
                  <Field name="state">
                    {({ field, meta }) => (
                      <>
                        <Form.Control
                          {...field}
                          autoComplete="off"
                          isInvalid={!!meta.error && !!meta.touched}
                          value={field.value ? field.value : ""}
                        />
                        {meta.touched && meta.error && (
                          <div className="field-error">{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                </Form.Group>
                <Form.Group className="col-md-4 col-12 mb-3">
                  <Form.Label>Country</Form.Label>
                  <Field name="country">
                    {({ field, meta }) => (
                      <>
                        <Form.Control
                          {...field}
                          autoComplete="off"
                          isInvalid={!!meta.error && !!meta.touched}
                          value={field.value ? field.value : ""}
                        />
                        {meta.touched && meta.error && (
                          <div className="field-error">{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="col-md-12 col-12 mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Field name="notes">
                    {({ field, meta }) => (
                      <>
                        <Form.Control
                          {...field}
                          as="textarea"
                          autoComplete="off"
                          isInvalid={!!meta.error && !!meta.touched}
                          value={field.value ? field.value : ""}
                          placeholder=" Have a specific query? want to add a message to the team?"
                        />
                        {meta.touched && meta.error && (
                          <div className="field-error">{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                </Form.Group>
              </Row>

              <button
                type="submit"
                className="mt-auto form-button button"
                //onClick={handleClick}
              >
                Submit
              </button> */}
            </Form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(MuslimPopup);
