import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import { initialValues, validationSchema, getFormData } from "./util";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import { customiseFormSuccessMsg } from "../../../util";
import MainLayout from "../../layout/Mainlayout";
import { Button } from "bootstrap";
import { uploadWill } from "../../../route";
import okIcon from "../../../assets/images/icon-ok.png";
import downloadIcon from "../../../assets/images/download-arrow.png";
import SuccessModal from "../common/successModal2";

const stateKey = "";
const getKey = "payment_success";
const downloadLinkKey = "will_download_link";

const PaymentSuccess = (props) => {
  const navigate = useNavigate();

  //useEffect(() => {
  // props.dispatch({
  //   type: "payment_success",
  //   key: getKey,
  // });
  //}, []);

  // const [captchaError, setCaptchaError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initial_values, setInitial_values] = useState(initialValues);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [upload_enabled, setUploadEnable] = useState(true);

  // const submitHandler = (values) => {
  //   

  //   if (formik.isValid === true) {
  //     setLoading(true);
  //     const formData = getFormData(values);
  //     props.dispatch({
  //       type: "personal_detail",
  //       payload: formData,
  //       key: stateKey,
  //     });
  //   }
  // };

  // const formik = useFormik({
  //   initialValues: initial_values,
  //   values: initial_values,
  //   validationSchema: validationSchema,
  //   enableReinitialize: true,
  //   onSubmit: submitHandler,
  // });

  const upload_doc = () => {
    navigate(uploadWill);
  };

  // useEffect(() => {
  //   if (props.formResult.error === true || props.formResult.success === true) {
  //     setLoading(false);
  //   }
  //   if (props.formResult.success === true) {
  //     setTimeout(() => {
  //       formik.resetForm();
  //       props.dispatch({
  //         type: "RESET_FORM",
  //         key: stateKey,
  //       });
  //     }, 1000);
  //   }
  // }, [props.formResult.success, props.formResult.error]);
  const DownloadHandler = () => {
    window.location.href = props.downloadLink.data;
    setTimeout(() => {
      setShowSuccessModal(true);
    }, 2000);

    setTimeout(() => {
      setShowSuccessModal(false);
      setUploadEnable(false);
    }, 3000);
  };

  return (
    <MainLayout>
      {showSuccessModal == true && (
        <CustomModal
          body={"The document has successfully been downloaded"}
          stateKey={stateKey}
          type="success"
        />
      )}
      {loading && <Loader />}
      <div className="layout__headings">
        <div className="page-heading">
          <img src={okIcon} className="pay-success-icon" /> Payment Success
        </div>
      </div>
      <div
        className="main-layout-cont"
        style={{ marginTop: "27px", fontSize: "18px" }}
      >
        <p className="col-md-8">
          The payment for your will making against Vaibhav is successfull. You
          can download your will from your registered mail or click on the link
          below to download.
        </p>
        <h3 className="layout__headings">Instructions/Next Steps:</h3>
        <div className="col-md-9">
          <ol className="pay-success-ol">
            <li>
              You can download the will by clicking on download button below of
              visit your account
            </li>
            <li>Take a print of download form</li>
            <li>
              You need to sign on the document along with one witness. Learn
              about the witness criterias
            </li>
            <li>There is no period validation for this will</li>
            <li>Upload the signed document </li>
          </ol>
        </div>
        {/* <a target="_blank" href="#" className="mt-auto  download-button">
          <img src={downloadIcon} />
          Download
        </a> */}
        <button
          className="mt-auto d-flex download-button "
          onClick={() => {
            DownloadHandler();
          }}
        >
          <img src={downloadIcon} />
          Download
        </button>
        <button
          onClick={upload_doc}
          className="mt-auto button float-end"
          disabled={upload_enabled}
        >
          Next
        </button>
      </div>
    </MainLayout>
  );
};

export default connect((state) => {
  return {
    successResult: { ...state.Forms[stateKey] },
    downloadLink: { ...state.GetData[downloadLinkKey] },
  };
})(PaymentSuccess);
