import React, { useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, FormikProvider, FieldArray } from "formik";
import { initialValues, validationSchema, listObj } from "./util";
import SubmitBtn from "../../common/SubmitBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";

const Automobiles = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} Automobiles
        </div>
      </div>

      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FieldArray
              name="vehicleList"
              render={(arrayHelpers) => {
                const { push, remove, form } = arrayHelpers;
                const { values } = form;
                const row =
                  values !== undefined
                    ? values.vehicleList?.map((each, index) => {
                        let border =
                          values.vehicleList.length - 1 == index ? false : true;
                        return (
                          <div
                            key={`vehicleList${index}`}
                            style={
                              border == true
                                ? {
                                    borderBottom: "2px solid #c1c1c1",
                                    marginBottom: "1rem",
                                  }
                                : {}
                            }
                          >
                            {index > 0 && (
                              <Row className="d-flex flex-row-reverse">
                                <div
                                  className="col-md-1"
                                  style={{ textAlign: "right" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    className="removeFieldIcon"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  />
                                </div>
                              </Row>
                            )}

                            <Row>
                              <Form.Group className="col-md-6 mb-3">
                                <Form.Label>Vehicle Type</Form.Label>
                                <Field
                                  name={`vehicleList[${index}]['vehicle_type']`}
                                >
                                  {({ field, meta }) => (
                                    <>
                                      <Form.Control
                                        {...field}
                                        autoComplete="off"
                                        isInvalid={!!meta.error && meta.touched}
                                        value={field.value ? field.value : ""}
                                        placeholder="Car/Bike/Scooter/Truck"
                                      />
                                      {!!meta.error && meta.touched && (
                                        <div className="field-error">
                                          {meta.error}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </Form.Group>
                              <Form.Group className="col-md-6 mb-3">
                                <Form.Label>Vehicle Brand Name</Form.Label>
                                <Field
                                  name={`vehicleList.${index}.vehicle_brand`}
                                >
                                  {({ field, meta }) => {
                                    return (
                                      <>
                                        <Form.Control
                                          {...field}
                                          autoComplete="off"
                                          isInvalid={
                                            !!meta.error && meta.touched
                                          }
                                          value={field.value ? field.value : ""}
                                        />
                                        {!!meta.error && meta.touched && (
                                          <div className="field-error">
                                            {meta.error}
                                          </div>
                                        )}
                                      </>
                                    );
                                  }}
                                </Field>
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group className="col-md-6 mb-3">
                                <Form.Label>Vehicle Number </Form.Label>
                                <Field
                                  name={`vehicleList[${index}]['vehicle_number']`}
                                >
                                  {({ field, meta }) => (
                                    <>
                                      <Form.Control
                                        {...field}
                                        autoComplete="off"
                                        isInvalid={!!meta.error && meta.touched}
                                        value={field.value ? field.value : ""}
                                      />
                                      {!!meta.error && meta.touched && (
                                        <div className="field-error">
                                          {meta.error}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </Form.Group>
                            </Row>
                          </div>
                        );
                      })
                    : "";

                const result =
                  values !== undefined ? (
                    <>
                      {row}
                      <div className="form-btns mt-4">
                        <button
                          type="button"
                          className="button"
                          onClick={() => push(listObj)}
                        >
                          Add More Vehicles
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  );
                return result;
              }}
            ></FieldArray>
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(Automobiles);
