import * as Yup from "yup";

export const initialValues = {
  plan_id: "",
  coupon: "",
  confirmation: false,
};
export const validationSchema = Yup.object().shape({
  plan_id: Yup.string().required("*This is required"),
  terms: Yup.bool().oneOf([true], "*Please confirm before proceeding further."),

  //   Yup.string()
  //     .min(6, "*Captcha must have at least 6 characters")
  //     .max(6, "*Captcha can't be longer than 6 characters")
  //     .required("*Captcha is required"),
});

export const getFormData = (values) => {
  let formData = new FormData();

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  return formData;
};
