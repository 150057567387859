import * as Yup from "yup";
import { notOnlyWhiteSpace } from "../../../../constants";

export const initialValues = {
  bank_name: "",
  acc_number: "",
  branch_name: "",
  city: "",
  ownership_status: "Single Account",
  asset_sub_type: "Saving Account",
  all_fds: false,
  
};
export const validationSchema = Yup.object().shape({
  bank_name: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  branch_name: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  city: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
  // acc_number: Yup.string()
  //   .required("*This is required")
  //   .min(2, "*This must have at least 2 characters")
  //   .max(100, "*This can't be longer than 100 characters")
  //   .matches(
  //     notOnlyWhiteSpace,
  //     "*Only Alphabets,Numbers and Spaces are allowed "
  //   ),
  acc_number: Yup.string().when("all_fds", {
    is: false,
    then: Yup.string()
      .required("*This is required")
      .min(2, "*This must have at least 2 characters")
      .max(100, "*This can't be longer than 100 characters")
      .matches(
        notOnlyWhiteSpace,
        "*Only Alphabets,Numbers and Spaces are allowed "
      ),
  }),
  ownership_status: Yup.string()
    .optional()
    .min(2, "*This must have at least 2 characters")
    .max(100, "*This can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),


});

export const getFormData = (values, asset_type) => {
  let formData = new FormData();

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  formData.append("asset_type", asset_type);
  formData.append("all_fds", values.all_fds == "false" ? false : true);
  formData.append(
    "acc_number",
    values.all_fds == "true" || values.all_fds == true ? "" : values.acc_number
  );
  return formData;
};

export const asset_sub_type = [
  "Saving Account",
  "Fixed Deposit Account",
  "Recurring Deposit Account",
  "Current Account",
  //"NRI/NRE Account",
  "NRE Account",
  "NRO Account",
];

export const splitAndCapitalize = (name) => {
  var NAME = name.replaceAll("^", " ");

  if (typeof NAME !== "string") return "";
  return NAME.charAt(0).toUpperCase() + NAME.slice(1);
};

export const checkExisting = () => {};
