import * as Yup from "yup";
import { notOnlyWhiteSpace, dec_two_places } from "../../../../constants";

export const listObj = {
  bond_issuer: "",
  bond_id: "",
  bond_name: "",
  bond_units: "",
};
export const initialValues = {
  bondsList: [listObj],
  
};
export const validationSchema = Yup.object().shape({
  bondsList: Yup.array().of(
    Yup.object().shape({
      bond_issuer: Yup.string()
        .required("*This is required")
        .min(2, "*This must have at least 2 characters")
        .max(100, "*This can't be longer than 100 characters")
        .matches(
          notOnlyWhiteSpace,
          "*Only Alphabets,Numbers and Spaces are allowed "
        ),
      bond_id: Yup.string()
        .required("*This is required")
        .min(2, "*This must have at least 2 characters")
        .max(100, "*This can't be longer than 100 characters")
        .matches(
          notOnlyWhiteSpace,
          "*Only Alphabets,Numbers and Spaces are allowed "
        ),
      bond_name: Yup.string()
        .required("*This is required")
        .min(2, "*This must have at least 2 characters")
        .max(100, "*This can't be longer than 100 characters")
        .matches(
          notOnlyWhiteSpace,
          "*Only Alphabets,Numbers and Spaces are allowed "
        ),
      bond_units: Yup.string()
        .required("*This is required")
        .matches(
          dec_two_places,
          "*Only positive decimal number upto 2 places is allowed"
        ),
    })
  ),


});

export const getFormData = (values, asset_type) => {
  let formData = new FormData();

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  formData.append("asset_type", asset_type);
  return formData;
};
