import React, { Suspense } from "react";
import { connect } from "react-redux";
//import NotificationSetting from "../NotificationSetting";
import PasswordSetting from "../PasswordSetting";
// import Help from "../Help";
import PastWill from "../PastWill";
import CurrentWill from "../CurrentWill";
import { Tab, Row, Col, Nav } from "react-bootstrap";

import Account from "../../design/account-setting/Account";
import Help from "../../design/account-setting/Help";
import Layout2 from "../../layout/Layout2";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { willtype } from "../../../route";
var NotificationSetting = React.lazy(() => import("../NotificationSetting"));

const AccountSetting = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("current-will");

  const changeActiveTab = ($tab) => {
    setActiveTab($tab);
  };

  return (
    <Layout2>
      <Tab.Container defaultActiveKey={activeTab}>
        <Row className="acc-page-container mt-5">
          <Col
            sm={12}
            md={12}
            lg={2}
            variant="pills"
            className="acc-page-menu-divider"
          >
            <Nav.Link
              eventKey="current-will"
              className={`acc-page-menu ${
                activeTab === "current-will" && "active"
              }`}
              onClick={() => {
                changeActiveTab("current-will");
              }}
            >
              Current Will
            </Nav.Link>
            <Nav.Link
              eventKey="notification"
              className={`acc-page-menu ${
                activeTab === "notification" && "active"
              }`}
              onClick={() => {
                changeActiveTab("notification");
              }}
            >
              Notification
            </Nav.Link>
            <Nav.Link
              eventKey="past-will"
              className={`acc-page-menu ${
                activeTab === "past-will" && "active"
              }`}
              onClick={() => {
                changeActiveTab("past-will");
              }}
            >
              Past Will
            </Nav.Link>
            <Nav.Link
              eventKey="account"
              className={`acc-page-menu ${activeTab === "account" && "active"}`}
              onClick={() => {
                changeActiveTab("account");
              }}
            >
              Account
            </Nav.Link>
            <Nav.Link
              eventKey="will-type"
              className={`acc-page-menu ${activeTab === "will-type" && "active"}`}
              onClick={() => {
                navigate(willtype);
              }}
            >
              {/* Will Type
            </Nav.Link>
            <Nav.Link
              eventKey="help"
              className={`acc-page-menu ${activeTab === "help" && "active"}`}
              onClick={() => {
                changeActiveTab("help");
              }}
            > */}
              Help
            </Nav.Link>
            <Nav.Link
              eventKey="password"
              className={`acc-page-menu ${
                activeTab === "password" && "active"
              }`}
              onClick={() => {
                changeActiveTab("password");
              }}
            >
              Password
            </Nav.Link>
          </Col>
          <Col md={12} sm={12} lg={10}>
            <Tab.Content className="ms-2">
              <Tab.Pane eventKey="current-will">
                <CurrentWill />
              </Tab.Pane>

              <Tab.Pane eventKey="notification">
                <Suspense fallback={<div>Loading...</div>}>
                  <NotificationSetting />
                </Suspense>
              </Tab.Pane>

              <Tab.Pane eventKey="past-will">
                <PastWill />
              </Tab.Pane>
              <Tab.Pane eventKey="account">
                <Account />
              </Tab.Pane>
              <Tab.Pane eventKey="help">
                <Help />
              </Tab.Pane>
              <Tab.Pane eventKey="password">
                <PasswordSetting />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Layout2>
  );
};

export default AccountSetting;
