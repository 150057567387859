import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, useFormik, FormikProvider } from "formik";
import {
  initialValues,
  nominee_validation,
  getFormData,
  gaurd_init,
  gaurdian_validation,
} from "./util";
import { Link, useParams, useNavigate } from "react-router-dom";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import { calculateAge, customiseFormSuccessMsg } from "../../../util";
import IDProofDropdown from "../common/IDProofDropdown";
import MainLayout from "../../layout/Mainlayout";
import SubmitBtn from "../common/SubmitBtn";
import RelationDropdown from "../common/RelationDropdown";
import CountryStateCityPincode from "../common/CountryStateCityPincode";
import GobackArrow from "../common/GobackArrow";
import { addyourwish,view_draft } from "../../../route";

const stateKey = "AddNominee";

const AddNominee = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  
  const [initial_values, setInitial_values] = useState(initialValues);
  const [validations, setValidations] = useState(nominee_validation);
  const [NomineeAge, setNomineeAge] = useState(18);

  useEffect(() => {
    setLoading(false);
  }, []);

  const submitHandler = (values) => {

    if (formik.isValid === true) {
      setLoading(true);
      const { user_captcha, ...formval } = values;
      const formData = getFormData(formval);
      props.dispatch({
        type: "add_nominee",
        payload: formData,
        key: stateKey,
      });
      // const sess_data = sessionStorage.getItem("data");
      //     const sessionData = JSON.parse(sess_data);
      //     if (sessionData !== null && sessionData !== undefined) {
      //       if(sessionData.will_type == 1)
      //       {
      //         navigate(view_draft);
      //       }
      //       else
      //       {
      //         navigate(addyourwish);
      //       }
      //     }
    }   
  };
  const formik = useFormik({
    initialValues: initial_values,
    values: initial_values,
    validationSchema: validations,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    const age =
      formik.values.dob.length > 0 ? calculateAge(formik.values.dob) : 18;
    setNomineeAge(age);
    if (age < 18) {
      setInitial_values({ ...formik.values, ...gaurd_init });
      setValidations(
        Yup.object().shape({ ...nominee_validation, ...gaurdian_validation })
      );
    } else {
      const {
        g_name,
        g_relation,
        g_proof_type,
        g_proof_detail,
        g_dob,
        g_address,
        g_pincode,
        g_country,
        g_state,
        g_city,
        ...rest
      } = formik.values;
      setInitial_values(rest);
      setValidations(Yup.object().shape({ ...nominee_validation }));
    }
  }, [formik.values.dob]);

  useEffect(() => {
    setLoading(false);

    if(props.formResult.success==true){
      props.dispatch({
        type: "get_nomineeList",
        key: "nomineeList",
      });
    }
  }, [props.formResult.success, props.formResult.error]);

  

  return (
    <MainLayout>
      {loading && <Loader />}
      {props?.formResult?.success === true && (
        <CustomModal
          body={customiseFormSuccessMsg(props.formResult.message)}
          stateKey={stateKey}
          type="success"
        />
      )}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow /> Add Nominee{" "}
        </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Nominee Name</Form.Label>
                <Field name="name">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Relationship</Form.Label>
                <RelationDropdown formik={formik} name="relation" />
              </Form.Group>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>Date of Birth</Form.Label>
                <Field name="dob">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="date"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>
            <Row  className="mt-3">
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>ID Proof Type</Form.Label>
                <Field name="proof_type">
                  {({ field, meta, form }) => {
                    return (
                      <IDProofDropdown
                        validation={{
                          ...field,
                          isInvalid: !!meta.error,
                          error: meta.error,
                          form: form,
                        }}
                      />
                    );
                  }}
                </Field>
              </Form.Group>
              <Form.Group className="col-md-4 mb-3">
                <Form.Label>ID Proof Detail</Form.Label>
                <Field name="proof_detail">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>

            {NomineeAge < 18 && (
              <div>
                <div className="layout__headings mb-5">
                  <div className="page-heading">
                    Add Nominee's Gaurdian <br />
                    <small style={{ fontSize: "12px" }}>
                      Please add gaurdian as nominee's age is below 18 yet{" "}
                    </small>
                  </div>
                </div>
                <Row>
                  <Form.Group className="col-md-4 mb-3">
                    <Form.Label>Gaurdian Name</Form.Label>
                    <Field name="g_name">
                      {({ field, meta }) => (
                        <>
                          <Form.Control
                            {...field}
                            type="text"
                            autoComplete="off"
                            isInvalid={!!meta.error && !!meta.touched}
                            value={field.value ? field.value : ""}
                          />
                          {meta.touched && meta.error && (
                            <Form.Control.Feedback type="invalid">
                              {meta.error}
                            </Form.Control.Feedback>
                          )}
                        </>
                      )}
                    </Field>
                  </Form.Group>

                  <Form.Group className="col-md-4 mb-3">
                    <Form.Label>ID Proof Type</Form.Label>

                    <Field name="g_proof_type">
                      {({ field, meta, form }) => {
                        return (
                          <IDProofDropdown
                            validation={{
                              ...field,
                              isInvalid: !!meta.error,
                              error: meta.error,
                              form: form,
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Form.Group>
                  <Form.Group className="col-md-4 mb-3">
                    <Form.Label>ID Proof Detail</Form.Label>
                    <Field name="g_proof_detail">
                      {({ field, meta }) => (
                        <>
                          <Form.Control
                            {...field}
                            type="text"
                            autoComplete="off"
                            isInvalid={!!meta.error && !!meta.touched}
                            value={field.value ? field.value : ""}
                          />
                          {meta.touched && meta.error && (
                            <Form.Control.Feedback type="invalid">
                              {meta.error}
                            </Form.Control.Feedback>
                          )}
                        </>
                      )}
                    </Field>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="col-md-4 mb-3">
                    <Form.Label>Relationship</Form.Label>
                    <RelationDropdown formik={formik} name="g_relation" />
                  </Form.Group>
                  <Form.Group className="col-md-4 mb-3">
                    <Form.Label>Date of Birth</Form.Label>
                    <Field name="g_dob">
                      {({ field, meta }) => (
                        <>
                          <Form.Control
                            {...field}
                            type="date"
                            autoComplete="off"
                            isInvalid={!!meta.error && !!meta.touched}
                            value={field.value ? field.value : ""}
                          />
                          {meta.touched && meta.error && (
                            <Form.Control.Feedback type="invalid">
                              {meta.error}
                            </Form.Control.Feedback>
                          )}
                        </>
                      )}
                    </Field>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="col-md-12 mb-3">
                    <Form.Label>Permanent Address</Form.Label>
                    <Field name="g_address">
                      {({ field, meta }) => (
                        <>
                          <Form.Control
                            {...field}
                            as="textarea"
                            autoComplete="off"
                            isInvalid={!!meta.error && !!meta.touched}
                            value={field.value ? field.value : ""}
                          />
                          {meta.touched && meta.error && (
                            <Form.Control.Feedback type="invalid">
                              {meta.error}
                            </Form.Control.Feedback>
                          )}
                        </>
                      )}
                    </Field>
                  </Form.Group>

                  <CountryStateCityPincode
                    setInitial_values={setInitial_values}
                    formik={formik}
                    fieldname={{
                      pin: "g_pincode",
                      country: "g_country",
                      state: "g_state",
                      city: "g_city",
                    }}
                  />
                </Row>
              </div>
            )}
            <Row className="my-3">
              <div className="col-6">
                <button
                  className="mt-auto button"
                  onClick={() => {
                    props.setSelectedNominee({});
                  }}
                  type="button"
                >
                  Cancel
                </button>
              </div>
              <div className="col-6">
                <SubmitBtn text="Save" />
              </div>
            </Row>
          </Form>
        </FormikProvider>
      </div>
    </MainLayout>
  );
};

export default connect((state) => {
  return { formResult: { ...state?.Forms?.[stateKey] } };
})(AddNominee);
