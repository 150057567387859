import Carousel from "react-bootstrap/Carousel";
import { createRef, useCallback, useEffect, useState, useMemo } from "react";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownLong } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Videosection from "./Videosection";
import landingPageImg from "../../../assets/images/landingPageImg.png";
import Loader from "../common/Loader/Loader";

const getKey = "LandingVideos";

const LandingPage = (props) => {
  const [vidOne, setVidOne] = useState(null);
  const [vidTwo, setVidTwo] = useState(null);
  const [vidThree, setVidThree] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef = createRef();
  var x = document.getElementsByClassName("carousel-indicators");
  const [indicatorState, setIndicatorState] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleIndicator = () => {
    if (activeSlide == 0) {
      setIndicatorState(false);
    } else {
      setTimeout(() => {
        setIndicatorState(true);
      }, 1000);
    }
  };

  const changeChild = useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        return activeSlide == 0 ? false : sliderRef?.current?.prev();
      } else if (e.key === "ArrowRight") {
        return activeSlide == 4 ? false : sliderRef?.current?.next();
      }
    },
    [sliderRef]
  );

  useEffect(() => {
    document.addEventListener("keydown", changeChild);

    return function cleanup() {
      document.removeEventListener("keydown", changeChild);
    };
  }, [changeChild]);

  const scroll = useCallback(
    (y) => {
      if (y > 0) {
        return activeSlide == 4 ? false : sliderRef?.current?.next();
      } else {
        return activeSlide == 0 ? false : sliderRef?.current?.prev();
      }
    },
    [sliderRef]
  );

  useEffect(() => {
    window.addEventListener("wheel", (e) => {
      if (window.innerWidth > 991) {
        scroll(e.deltaY);
      }
    });
    toggleIndicator();
  }, [scroll]);

  useEffect(() => {
    props.dispatch({
      type: "get_landingPage_videos",
      key: getKey,
    });
  }, []);

  useEffect(() => {
    if (props.videos.success == true || props.videos.error == true) {
      setLoading(false);
    }
    if (props.videos.success == true && props.videos.data.length > 0) {
      setVidOne(
        props.videos.data[0].filepath  + props.videos.data[0].filename
      );
      setVidTwo(
        props.videos.data[0].filepath  + props.videos.data[1].filename
      );
      setVidThree(
        props.videos.data[0].filepath  + props.videos.data[2].filename
      );
    }
  }, [props.videos]);

  const changeHandler = () => {
    setTimeout(() => {
      return sliderRef?.current?.next();
    }, 1000);
  };

  useEffect(() => {
    toggleIndicator();
  }, [activeSlide]);

  return (
    <>
      {loading && <Loader />}
      <section className="landing-page">
        <Carousel
          keyboard={true}
          indicators={indicatorState}
          interval={null}
          ref={sliderRef}
          controls={false}
          onSelect={(e) => {
            setActiveSlide(e);
          }}
        >
          <Carousel.Item>
            <section className="d-md-flex d-lg-flex text-sm-center">
              <div className="col-lg-6 col-md-6 col-sm-12 pe-md-5 text-md-start text-lg-start">
                <div className="heading">
                  The Fastest & Easiest way <br /> to make a Will Online
                </div>
                <p className="mt-lg-4 mt-md-4 mt-sm-4">
                  Willyourwish is an Online Will making Platform that takes away
                  all the hassles of Will making. Its an easy 4 step process. We
                  have made it as easy as booking a flight ticket!
                </p>
                <p className="mt-lg-4 mt-md-4 mt-sm-2">
                  Scroll on to learn how simple the process is, and start
                  building your legacy with your own will.
                </p>
                <button
                  className="button"
                  onClick={() => {
                    props.setShowWebsite(true);
                  }}
                >
                  Learn about it
                </button>
              </div>
              <div className="pt-sm-3 ps-md-5 ps-lg-5 col-sm-12 col-md-6 col-lg-6 mt-sm-4 vidsection">
                <img
                  src={landingPageImg}
                  className="img-fluid bannerImg float-md-end float-lg-end"
                />
              </div>
            </section>
          </Carousel.Item>
          <Carousel.Item>
            <section className="d-md-flex d-lg-flex text-sm-center">
              <div className="pe-md-5 pe-lg-5 col-lg-6 col-sm-12 col-md-6 text-md-start text-lg-start">
                <div className="heading">Add Details of your assets</div>
                <p className="mt-lg-4 mt-md-4 mt-sm-4">
                  Select an Asset and add its details in a super simple form.
                  You don’t even need to mention the value of the assets.
                </p>
              </div>
              <div className="col-lg-6 p-2 col-sm-12 col-md-6 mt-sm-4 vidsection">
                {vidOne !== null && <Videosection vidurl={vidOne} />}
              </div>
            </section>
          </Carousel.Item>
          <Carousel.Item>
            <section className="d-md-flex d-lg-flex text-sm-center">
              <div className="col-lg-6 pe-md-5 pe-lg-5 col-sm-12 col-md-6 text-md-start text-lg-start">
                <div className="heading">
                  Divide the Asset among your nominees
                </div>
                <p className="mt-lg-4 mt-md-4 mt-sm-4">
                  Simply tell us what % of the asset goes to which nominee. You
                  can add multiple nominees and split assets any way you like.
                </p>
              </div>
              <div className="col-lg-6 p-2 col-sm-12 col-md-6 mt-sm-4 vidsection">
                {vidTwo !== null && <Videosection vidurl={vidTwo} />}
              </div>
            </section>
          </Carousel.Item>
          <Carousel.Item>
            <section className="d-md-flex d-lg-flex text-sm-center">
              <div className="col-lg-6 pe-md-5 pe-lg-5 col-sm-12 col-md-6 text-md-start text-lg-start">
                <div className="heading">Your personalized will is Ready!</div>
                <p className="mt-lg-4 mt-md-4 mt-sm-4">
                  We take all the information you provided and draft a will
                  document. Its complete with accurate Legal language and
                  Essential clauses to make your Will fool-proof.
                </p>
              </div>
              <div className="col-lg-6 p-2 col-sm-12 col-md-6 text-md-start text-lg-start mt-sm-4 vidsection">
                {vidThree !== null && <Videosection vidurl={vidThree} />}
              </div>
            </section>
          </Carousel.Item>
          <Carousel.Item>
            <section className="d-md-flex d-lg-flex text-sm-center">
              <div className="col-lg-6 pe-md-5 pe-lg-5 col-sm-12 col-md-6 text-md-start text-lg-start vidsection">
                <div className="heading">Print & Sign to Activate the will</div>
                <p className="mt-lg-4 mt-md-4 mt-sm-4">
                  Follow simple instructions in the end to bring your Will into
                  effect. You just have to Print and Sign the draft we provide
                  to activate. That’s it! You now have a perfectly Legal & Valid
                  Will in force.
                </p>
                <p className="mb-lg-5 mb-md-5 mb-sm-2">
                  Dive into our website to Learn about Wills & Get started with
                  your own Will in a few clicks.
                </p>
              </div>
              <div className="col-lg-6 text-center p-lg-5 p-md-5 mt-sm-4 col-sm-12 col-md-6">
                <div>
                  <FontAwesomeIcon
                    icon={faArrowDownLong}
                    className="diveInIcon"
                    onClick={() => {
                      props.setShowWebsite(true);
                    }}
                  />
                </div>
                <div
                  className="diveIn fw-bold"
                  onClick={() => {
                    props.setShowWebsite(true);
                  }}
                >
                  Dive in to <br /> know more
                </div>
              </div>
            </section>
          </Carousel.Item>
        </Carousel>
        <Footer
          setShowWebsite={props.setShowWebsite}
          changeHandler={changeHandler}
          indicatorState={indicatorState}
        />
      </section>
    </>
  );
};

export default connect((state) => {
  return {
    videos: { ...state.GetData[getKey] },
  };
})(LandingPage);
