import React, { useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, FormikProvider, FieldArray } from "formik";
import { initialValues, validationSchema, listObj } from "./util";
import SubmitBtn from "../../common/SubmitBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";

const MF = (props) => {
  const boxRef = React.createRef();

  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  let tooltip_text = (
    <p>
      Download CAS (Consolidated Account Statement) from NSDL or CAMS to find
      all the Folios in one place. Link for NSDL and CAMS is given below. Make
      sure you register it with the same email id which you have been using for
      investing in Mutual Funds. Link for NSDL CAS:
      <a href="https://nsdlcas.nsdl.com/">https://nsdlcas.nsdl.com/</a>, (If you
      are already registered here, you would be getting monthly emails from NSDL
      CAS) Link for CAMS CAS:{" "}
      <a href="https://www.camsonline.com/Investors/Statements/Consolidated-Account-Statement">
        https://www.camsonline.com/Investors/Statements/Consolidated-Account-Statement
      </a>
    </p>
  );

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} Mutual Funds
        </div>
      </div>

      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div className="d-lg-flex d-md-flex">
              <div className="col-lg-8 col-md-8 col-12 mb-3">
                <FieldArray
                  name="mfList"
                  render={(arrayHelpers) => {
                    const { push, remove, form } = arrayHelpers;
                    const { values } = form;
                    const row =
                      values !== undefined
                        ? values.mfList?.map((each, index) => {
                            return (
                              <Row className="" key={`mfList${index}`}>
                                <Form.Group className="col-md-5 col-10 mb-3">
                                  <Form.Label>
                                    Folio Number{" "}
                                    {index == 0 && (
                                      <FontAwesomeIcon
                                        style={{ color: "#575757" }}
                                        icon={faInfoCircle}
                                        onMouseEnter={() => {
                                          boxRef.current.style.visibility =
                                            "visible";
                                        }}
                                        onMouseLeave={() => {
                                          boxRef.current.style.visibility =
                                            "hidden";
                                        }}
                                      />
                                    )}
                                  </Form.Label>
                                  <Field name={`mfList[${index}]['folio_no']`}>
                                    {({ field, meta }) => (
                                      <>
                                        <Form.Control
                                          {...field}
                                          autoComplete="off"
                                          isInvalid={
                                            !!meta.error && meta.touched
                                          }
                                          value={field.value ? field.value : ""}
                                          onFocus={() => {
                                            boxRef.current.style.visibility =
                                              "visible";
                                          }}
                                          onBlur={() => {
                                            boxRef.current.style.visibility =
                                              "hidden";
                                          }}
                                        />
                                        {!!meta.error && meta.touched && (
                                          <div className="field-error">
                                            {meta.error}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </Form.Group>
                                <Form.Group className="col-md-5 col-10 mb-3">
                                  <Form.Label>Mutual Fund AMC</Form.Label>
                                  <Field name={`mfList.${index}.amc`}>
                                    {({ field, meta }) => {
                                      return (
                                        <>
                                          <Form.Control
                                            {...field}
                                            autoComplete="off"
                                            isInvalid={
                                              !!meta.error && meta.touched
                                            }
                                            value={
                                              field.value ? field.value : ""
                                            }
                                            placeholder="Axis Mutual Fund/ HDFC Mutual Fund"
                                          />
                                          {!!meta.error && meta.touched && (
                                            <div className="field-error">
                                              {meta.error}
                                            </div>
                                          )}
                                        </>
                                      );
                                    }}
                                  </Field>
                                </Form.Group>

                                {index > 0 && (
                                  <div className="col-md-1 col-1">
                                    <FontAwesomeIcon
                                      icon={faCircleXmark}
                                      className="removeFieldIcon"
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                    />
                                  </div>
                                )}
                              </Row>
                            );
                          })
                        : "";

                    const result =
                      values !== undefined ? (
                        <>
                          {row}
                          <div className="form-btns mt-1">
                            <button
                              type="button"
                              className="button"
                              onClick={() => push(listObj)}
                            >
                              Add More Folio
                            </button>
                          </div>
                        </>
                      ) : (
                        <></>
                      );
                    return result;
                  }}
                ></FieldArray>
              </div>
              <div
                className="col-lg-4 col-md-4 col-12 mutual-fund-box hidden"
                ref={boxRef}
              >
                {tooltip_text}
              </div>
            </div>
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(MF);
