import React, { useState } from "react";
import { Modal, Form, Row } from "react-bootstrap";
import { useFormik, FormikProvider, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const ExecutorPopup = (props) => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    props.return("");
    setShow(false);
  };

  const formik = useFormik({
    initialValues: { add_executor: "yes" },
    values: { add_executor: "yes" },
    enableReinitialize: true,
  });

  const handleClick = () => {
    props.return(formik.values.add_executor);
    setShow(false);
  };
  return (
    <Modal
      show={show}
      centered
      className="executorPopup"
      onHide={() => {
        if (props?.restrictClose) {
          return false;
        } else {
          handleClose();
        }
      }}
      backdrop="static"
    >
      <FontAwesomeIcon
        icon={faCircleXmark}
        onClick={handleClose}
        className="modalCloseIcon"
      />
      <Modal.Body>
        <FormikProvider value={formik}>
          <div className="col-md-12 mb-2">
            <b>Executor</b>
          </div>
          <Row>
            <div className="col-md-8 col-sm-12 font-17 pt-1">
              Do you want an executor ?
            </div>
            <div className="col-md-4 col-4">
              <Field name="add_executor">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      as="select"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Control>
                    {meta.touched && meta.error && (
                      <div className="field-error">{meta.error}</div>
                    )}
                  </>
                )}
              </Field>
            </div>
          </Row>
          <p>
            An executor is a legal representative (a person or an institution)
            of the person making the will chosen to carry out the process of
            distribution of assets.
            <br />
            This section lets you add an executor for your will. It is not a
            mandatory step and you can choose 'No' from the dropdown to proceed.
            However it has been seen that adding an Executor helps in avoiding
            disputes among the beneficiaries and thereby smoothens the Asset
            Distribution Process.
          </p>
          <button
            type="button"
            className="mt-auto form-button button"
            onClick={handleClick}
          >
            Next
          </button>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
};
export default ExecutorPopup;
