import MasterLayout from "../../layout/MasterLayout";
import { Row } from "react-bootstrap";
import Terminology from "../Support/Terminology";
import { Link } from "react-router-dom";
import { blog_detail } from "../../../route";

const Blogs = () => {
  return (
    <MasterLayout fluid={true} containerClass="p-lg-0 p-md-0" webFooter="true">
      <section className="blogs">
        <section className="blog-section-one py-md-5 pt-4 ps-md-5">
          <div className="col-md-10 col-11">
            <h2 className="yellowColor">Know more</h2>
            <p>
              We have covered it all even if you have very little knowledge
              about will making.
            </p>
          </div>
        </section>
        <section className="blog-search-section d-flex flex-column justify-content-center py-md-5 py-4">
          <div>
            <Terminology />
          </div>
          {/* <ul className="d-flex flex-row p-0 ">
            <li>Tags</li>
            <li>Legal Updates</li>
            <li>documents</li>
            <li>Assets to Add</li>
            <li>Executor</li>
            <li>Validity</li>
          </ul>
          <Row className="mt-md-5 mt-4">
            <div className="col-2">Tags</div>
            <div className="col-2">#Legal Updates</div>
            <div className="col-2">#documents</div>
            <div className="col-2">#Assets to Add</div>
            <div className="col-2">#Executor</div>
            <div className="col-2">#Validity</div>
          </Row> */}
        </section>
        <section className="py-md-5 py-4 blog-section">
          <Row className="px-md-5">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-md-5 mb-4 px-3">
              <div className="card">
                <div className="card-body p-4">
                  <div className="title mb-3 fw-bold">
                    What are the eligibility for a person to be as a witness?
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Pharetra convallis posuere rbi leo urna molestie at
                    Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Pharetra convallis posuere rbi leo urna molestie at{" "}
                  </p>
                  <Link to={`/blog-detail/blog-one`}>Read more</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-md-5 mb-4 px-3">
              <div className="card">
                <div className="card-body p-4">
                  <div className="title mb-3 fw-bold">
                    What are the eligibility for a person to be as a witness?
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Pharetra convallis posuere rbi leo urna molestie at
                    Lorem ipsum dolor sit amet, onsectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Pharetra convallis posuere rbi leo urna molestie at{" "}
                  </p>
                  <Link to={`/blog-detail/blog-two`}>Read more</Link>
                </div>
              </div>
            </div>
          </Row>
          <Row className="justify-content-center">
            <button className="button">Learn More</button>
          </Row>
        </section>
      </section>
    </MasterLayout>
  );
};
export default Blogs;
