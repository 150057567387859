import Item from "./Item";
import { Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const SectionTwo = () => {
  return (
    <>
      <div className="mb-lg-5 mb-md-4 mb-sm-2 mobile-view-space">
        <h2 className="section-heading">Will Making Made Easy</h2>
        <div className="border-line"></div>
      </div>
      <div className="desktop">
        <Row className="mb-5">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-md-0 mb-sm-5">
            <Item no="01" title="Personalized Will">
              Our Will writing program guides you to enter all your Asset
              details easily. You can also Add a Personalised Message to your
              loved ones or state any specific Wishes. We will then auto
              generate a personalised Will draft that is Legally Valid and easy
              to understand.
            </Item>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <Item no="02" title="Centralised Repository">
              Your will includes a complimentary access to your Secure Will
              Repository. You can upload Signed Copy of the Will to your profile
              and access it anytime you want in future. We also provide a neat
              Asset Summary - You should print it and keep a copy at Home for
              easy access in unfortunate incidents.
            </Item>
          </div>
        </Row>
        <Row>
          <div className="col-md-6 mb-md-0 mb-sm-5">
            <Item no="03" title="Amendments">
              If you acquire new Assets, or want to make any changes to your
              will, you can easily Amend your last will on willyourwish, without
              having to enter all the details again.
            </Item>
          </div>
          <div className="col-md-6">
            <Item no="04" title="Nominee Access">
              Once your will is ready, You have an option to enable intimation
              to your nominees. We will send an email to all your nominees,
              without divulging the details, that they are a beneficiary of your
              will. They can access a copy of the will after you without any
              confusion (coming soon).
            </Item>
          </div>
        </Row>
      </div>
      <div className="slider d-none">
        <Carousel breakpoints={breakPoints} controls={false}>
          <Carousel.Item>
            <Item no="01" title="Personalized Will">
              Our Will writing program guides you to enter all your Asset
              details easily. You can also Add a Personalised Message to your
              loved ones or state any specific Wishes. We will then auto
              generate a personalised Will draft that is Legally Valid and easy
              to understand.
            </Item>
          </Carousel.Item>
          <Carousel.Item>
            <Item no="02" title="Centralised Repository">
              Your will includes a complimentary access to your Secure Will
              Repository. You can upload Signed Copy of the Will to your profile
              and access it anytime you want in future. We also provide a neat
              Asset Summary - You should print it and keep a copy at Home for
              easy access in unfortunate incidents.
            </Item>
          </Carousel.Item>
          <Carousel.Item>
            <Item no="03" title="Amendments">
              If you acquire new Assets, or want to make any changes to your
              will, you can easily Amend your last will on willyourwish, without
              having to enter all the details again.
            </Item>
          </Carousel.Item>
          <Carousel.Item>
            <Item no="04" title="Nominee Access">
              Once your will is ready, You have an option to enable intimation
              to your nominees. We will send an email to all your nominees,
              without divulging the details, that they are a beneficiary of your
              will. They can access a copy of the will after you without any
              confusion (coming soon).
            </Item>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};
export default SectionTwo;
