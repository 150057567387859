const RemoveEntryReducer = function (
  state = {
    showModal: false,
    rowKey: "",
    action: "",
  },
  action
) {
  switch (action.type) {
    case "RESET_REMOVE_CONFIRMATION": {
      state = { showModal: false, rowKey: "", action: "" };
      return state;
    }
    case "SET_REMOVE_CONFIRMATION": {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
};

export default RemoveEntryReducer;
