import * as Yup from "yup";
import {
  notOnlyWhiteSpace,
  alphanumeric,
  emailRegExp,
} from "../../../constants";

export const initialValues = {
  full_name: "",
  email: "",
  notes: "",
  
};

export const validationSchema = Yup.object().shape({
  full_name: Yup.string()
    .required("*Name is required")
    .min(2, "*Name must have at least 2 characters")
    .max(100, "*Name can't be longer than 100 characters")
    .matches(alphanumeric, "*Only Alphabets,Numbers and Spaces are allowed "),
  email: Yup.string()
    .required("*This is required")
    .matches(emailRegExp, "*Please enter valid email address "),
  notes: Yup.string()
    .required("*This is required")
    .max(500, "*This can't be longer than 500 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),

});

export const getFormData = (values) => {
  let formData = new FormData();

  formData.append(`via`, "home_page");

  for (var key in values) {
    formData.append(`${key}`, values[key]);
  }
  return formData;
};
