import * as Yup from "yup";
import {
  passwordRegex,
  notOnlyWhiteSpace,
  phoneRegExp,
  numeric,
  emailRegExp,
} from "../../../constants";

export const initialValues = {
  name: "",
  email: "",
  phone: "",
  country_code: "",
  password: "",
  cpass: "",
  
  terms: false,
};
export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "*Name must have at least 2 characters")
    .max(100, "*Name can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    )
    .required("*Name is required"),
  country_code: Yup.string()
    .required("*Country Code required")
    .matches(numeric, "*Country Code is not valid")
    .max(3, "*Country Code can not be more than 3 digits")
    .min(1, "*Country Code must be minimum 1 digits"),
  phone: Yup.string()
    .required("*Mobile number required")
    .matches(numeric, "*Mobile number is not valid")
    .max(12, "*Mobile number can not be more than 12 digits")
    .min(6, "*Mobile number must be minimum 6 digits"),
  email: Yup.string()
    .required("*Email is required")
    .email("*Must be a valid email address")
    .matches(emailRegExp, "*Please enter valid email address "),
  //.max(100, "*Email must be less than 100 characters")
  password: Yup.string()
    .required("*Password is required")
    .max(15, "*Password must not be more than 15 characters")
    .min(8, "*Password must be atleast 8 characters")
    // .matches(
    //   passwordRegex,
    //   "*Password must fullfill requirements,refer to the notes "
    // ),
    .matches(
      passwordRegex,
      "*Password must be atleast 8 characters,Including atleast one uppercase letter (A, Z), one lowercase letter (a,z),one special character (!, @, #, $, etc.),one number (0-9)"
    ),
  cpass: Yup.string()
    .required("Confirm Password is required")
    .max(15, "*Password must not be more than 15 characters")
    .min(8, "*Password must be atleast 8 characters")
    .matches(
      passwordRegex,
      "*Password must be atleast 8 characters,Including atleast one uppercase letter (A, Z), one lowercase letter (a,z),one special character (!, @, #, $, etc.),one number (0-9)"
    )
    .oneOf(
      [Yup.ref("password"), null],
      "New Password and Confirm Passwords don't match"
    ),
  terms: Yup.bool().oneOf([true], "*Please accept the terms and conditions"),

});
