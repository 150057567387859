import MainLayout from "../../layout/Mainlayout";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { decrypt, encrypt, customiseFormSuccessMsg } from "../../../util";
import { connect } from "react-redux";
import { useFormik } from "formik";
import CustomModal from "../common/CustomModal";
import Loader from "../common/Loader/Loader";
import BankAccount from "./bankAccount";
import PPF from "./PPF";
import Cash from "./Cash";
import Jewellery from "./Jewellery";
import Art from "./Art";
import PF from "./PF";
import Mutual_funds from "./Mutual_funds";
import Stocks_etf from "./Stocks_etf";
import Property from "./Property";
import Insurance from "./Insurance";
import Bonds from "./Bonds";
import SGB from "./SGB";
import CryptoCurrency from "./CryptoCurrency";
import PrivateStocks from "./PrivateStocks";
import Other from "./Other";
import Automobiles from "./Automobiles";
import Gratuity from "./Gratuity";
import Nps from "./Nps";

const AddformKey = "AddAssetForm";
const editAssetKey = "EditAssetDetail";

const AddAsset = (props) => {
  const { asset_type, asset_id } = useParams();
  const firstPath = window.location.pathname.split("/")[1];
  const navigate = useNavigate();
  const [initial_values, setInitial_values] = useState();
  const [validationSchema, setValidationSchema] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (asset_id !== undefined) {
      props.dispatch({
        type: "get_asset_detail",
        payload: decrypt(asset_id),
        key: editAssetKey,
      });
    } else {
      setLoading(false);
    }
  }, []);

  const pull_data = (data) => {
    setInitial_values(data.initialValues);
    setValidationSchema(data.validationSchema);
  };

  const submitHandler = (values) => {
    if (formik.isValid === true) {
      setLoading(true);
      const { user_captcha, ...formval } = values;

      let formData = new FormData();

      for (var key in formval) {
        if (
          typeof formval[key] === "object" ||
          typeof formval[key] === "array"
        ) {
          formval[key].map((object, index) => {
            for (const property in object) {
              formData.append(
                `${key}[${index}][${property}]`,
                object[property]
              );
            }
          });
        } else {
          formData.append(`${key}`, formval[key]);
        }
      }
      formData.append("asset_type", asset_type);
      if (firstPath === "edit-asset") {
        formData.append("asset_id", decrypt(asset_id));
        props.dispatch({
          type: "update_asset",
          payload: formData,
          key: AddformKey,
        });
      } else {
        props.dispatch({
          type: "add_asset",
          payload: formData,
          key: AddformKey,
        });
      }
    }
  };
  const formik = useFormik({
    initialValues: initial_values,
    values: initial_values,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
    //validateOnBlur: false,
  });

  useEffect(() => {
    if (asset_id !== undefined) {
      const { master_asset, ...data } = props.assetDetail.data;

      if (master_asset?.url_type == "private-stocks") {
        const { stock_detail, ...rest } = data;
        const detail = [];
        props.assetDetail.data.stock_detail.map((i) => {
          let { no_of_shares, no_of_percent, ...rest } = i;
          const noOfShare = i.no_of_shares == null ? "" : i.no_of_shares;
          const noOfPercent = i.no_of_percent == null ? "" : i.no_of_percent;
          detail.push({
            ...rest,
            no_of_percent: noOfPercent,
            no_of_shares: noOfShare,
          });
        });
        setInitial_values({ ...rest, stock_detail: detail });
      } else if (master_asset?.url_type == "bank_account") {
        const { acc_number, all_fds, ...rest } = data;
        const allFds =
          all_fds == "false" ? false : all_fds == "true" ? true : false;
        const accNo = acc_number == null ? "" : acc_number;

        setInitial_values({ ...rest, all_fds: allFds, acc_number: accNo });
      } else {
        setInitial_values(data);
      }
      setLoading(false);
    }
  }, [props.assetDetail]);

  useEffect(() => {
    if (props.formResult.error === true || props.formResult.success === true) {
      setLoading(false);
    }
    if (props.formResult.success === true) {
      setTimeout(() => {
        formik.resetForm();
        props.dispatch({
          type: "RESET_FORM",
          key: AddformKey,
        });
        if (firstPath === "edit-asset") {
          navigate(
            `/edit-asset-nominee/${encrypt(
              props.formResult?.data?.assetID
            )}/${encrypt(props.formResult?.data?.masterAssetId)}`
          );
        } else {
          navigate(
            `/add-nominee/${encrypt(props.formResult?.data?.assetID)}/${encrypt(
              props.formResult?.data?.masterAssetId
            )}`
          );
        }
      }, 1000);
    }
  }, [props.formResult.success, props.formResult.error]);

  const assetPage = () => {
    switch (asset_type) {
      case "bank_account": {
        return (
          <BankAccount
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "ppf": {
        return (
          <PPF
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "pf": {
        return (
          <PF
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "property": {
        return (
          <Property
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "mutual_funds": {
        return (
          <Mutual_funds
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "stocks_and_etfs": {
        return (
          <Stocks_etf
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "insurance": {
        return (
          <Insurance
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "bonds": {
        return (
          <Bonds
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "cash": {
        return (
          <Cash
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "sovereign_gold_bond": {
        return (
          <SGB
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "jewellery": {
        return (
          <Jewellery
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "art": {
        return (
          <Art
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "private-stocks": {
        return (
          <PrivateStocks
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "crypto_currency": {
        return (
          <CryptoCurrency
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "others": {
        return (
          <Other
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "automobiles": {
        return (
          <Automobiles
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "gratuity": {
        return (
          <Gratuity
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
      case "nps": {
        return (
          <Nps
            editAssetKey={editAssetKey}
            pull_data={pull_data}
            formik={formik}
          />
        );
      }
    }
  };
  return (
    <MainLayout>
      <div>
        {props?.formResult?.success === true && (
          <CustomModal
            body={customiseFormSuccessMsg(props.formResult.message)}
            stateKey={AddformKey}
            type="success"
          />
        )}
        {props?.formResult?.error === true && (
          <CustomModal
            body={props.formResult.message}
            moreError={props.formResult.moreError}
            stateKey={AddformKey}
            type="error"
          />
        )}
        {loading && <Loader />}
        {assetPage()}
      </div>
    </MainLayout>
  );
};
export default connect((state) => {
  return {
    formResult: { ...state.Forms[AddformKey] },
    assetDetail: { ...state.GetData[editAssetKey] },
  };
})(AddAsset);
