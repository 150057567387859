import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row } from "react-bootstrap";
import { validationSchema, initialValues } from "./Util";
import { connect } from "react-redux";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import { useFormik, Field, FormikProvider } from "formik";
import loginImage from "../../../assets/images/login.png";
import { login } from "../../../route";
import Layout3 from "../../layout/Layout3";
import PassToggle from "../common/PassToggle";
import { PassRequirements } from "../common/PassRequirements";

const stateKey = "ResetPass";

function ResetPassword(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const navigate = useNavigate();

  //const [captchaError, setCaptchaError] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitHandler = (values) => {
    // if (validateCaptcha(values.user_captcha) == true) {
    //setLoading(true);
    props.dispatch({
      type: "Reset_Pass",
      payload: {
        email: values.email,
        token: token,
        password: values.password,
        password_confirmation: values.password_confirmation,
      },
      key: stateKey,
    });
    // } else {
    //   setCaptchaError(true);
    // }
    
    // values.user_captcha = "";
  };

  var formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
    enableReinitialize: true,
  });

  useEffect(() => {
    setLoading(false);

    if (props.formResult.success === true) {
      setTimeout(() => {
        formik.resetForm();
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
        //window.location.href = "/";
        navigate(login);
      }, 3000);
    }
  }, [props?.formResult?.success, props?.formResult?.error]);

  return (
    <Layout3>
      {loading === true && <Loader />}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {props?.formResult?.success === true && (
        <CustomModal
          body={props.formResult.message}
          stateKey={stateKey}
          type="success"
        />
      )}

      <div className="main-layout d-flex">
        <div className="col-md-6 mt-3 text-center">
          <img src={loginImage} className="login-img" />
        </div>
        <div className="col-md-6">
          <h1 className="login-title">Reset Password</h1>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} className="login-form">
              <Form.Group className="col-md-10 mb-2">
                <Field name="email">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="email"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Email ID/Phone Number"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group className="col-md-10 mb-2 position-relative">
                <PassToggle name="password" placeholder="New Password" />
              </Form.Group>
              <Form.Group className="col-md-10 mb-2 position-relative">
                <PassToggle
                  name="password_confirmation"
                  placeholder="Confirm Password"
                />
              </Form.Group>
              <Row>
                <div className="col-md-6 col-sm-12 text-md-start text-center mt-2">
                  <button className="button mt-auto" type="submit">
                    Reset Password
                  </button>
                </div>
              </Row>
            </Form>
          </FormikProvider>
          <PassRequirements formik={formik} />

          {/* <div className="col-md-10 mt-md-2">
            <small className="fw-bold">Note :</small>
            <ButtonGroup className="w-100 radio-group d-block password-notes">
              <li>
                <FontAwesomeIcon icon={faEye} />
                Password must be atleast 7 characters.
              </li>
            </ButtonGroup>
            <ButtonGroup className="w-100 radio-group d-block password-notes">
              <ToggleButton className="d-block">
                Password must be atleast 7 characters.
              </ToggleButton>
              <ToggleButton className="d-block">
                Including atleast one uppercase letter (A, Z)
              </ToggleButton>
              <ToggleButton className="d-block">
                one lowercase letter (a,z)
              </ToggleButton>
              <ToggleButton className="d-block">
                one special character (!, @, #, $, etc.)
              </ToggleButton>
              <ToggleButton className="d-block">one number (0-9)</ToggleButton>
            </ButtonGroup>
          </div> */}
        </div>
      </div>
    </Layout3>
  );
}
export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[stateKey] },
  };
})(ResetPassword);
