import { ButtonGroup, ToggleButton, Row } from "react-bootstrap";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export const PassRequirements = (props) => {
  const formik = props.formik;
  var upperCase = new RegExp("[A-Z]");
  var lowerCase = new RegExp("[a-z]");
  var numericCase = new RegExp("[0-9]");
  var specialCharCase = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  const [p1, setP1] = useState("");
  const [p2, setP2] = useState("");
  const [p3, setP3] = useState("");
  const [p4, setP4] = useState("");
  const [p5, setP5] = useState("");

  useEffect(() => {
    let val = formik.values.password;
    if (val.length > 0) {
      val.length < 7 ? setP1("red") : setP1("green");
      upperCase.test(val) ? setP2("green") : setP2("red");
      lowerCase.test(val) ? setP3("green") : setP3("red");
      specialCharCase.test(val) ? setP4("green") : setP4("red");
      numericCase.test(val) ? setP5("green") : setP5("red");
    } else {
      setP1("");
      setP2("");
      setP3("");
      setP4("");
      setP5("");
    }
  }, [formik.values.password]);

  return (
    <>
      <div className="col-md-10 mt-md-2 passRequirements">
        <small className="fw-bold">Note :</small>
        <ul>
          <li>
            <FontAwesomeIcon
              icon={faCheckSquare}
              className={`icon me-2 ${p1}`}
            />
            Password must be atleast 7 characters.
          </li>
          <li>
            <FontAwesomeIcon
              icon={faCheckSquare}
              className={`icon me-2 ${p2}`}
            />
            Including atleast one uppercase letter (A, Z)
          </li>
          <li>
            <FontAwesomeIcon
              icon={faCheckSquare}
              className={`icon me-2 ${p3}`}
            />
            one lowercase letter (a,z)
          </li>
          <li>
            <FontAwesomeIcon
              icon={faCheckSquare}
              className={`icon me-2 ${p4}`}
            />
            one special character (!, @, #, $, etc.)
          </li>
          <li>
            <FontAwesomeIcon
              icon={faCheckSquare}
              className={`icon me-2 ${p5}`}
            />
            one number (0-9)
          </li>
        </ul>
      </div>
    </>
  );
};
