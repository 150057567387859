import axios from "../interceptor";
import { call, put, takeEvery } from "redux-saga/effects";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../constants";

function addForm(action) {
  return axios({
    url: api_base + "/customer/payment_success",
    // method: "post",
    // data: action.payload,
  });
}

function* AddPaymentG(action) {
  try {
    var result = yield call(addForm, action);
    if (result?.status === HTTP_OK) {
      //============================//
      let data = sessionStorage.getItem("data");
      const dataobj = JSON.parse(data);
      dataobj.currentWillProgress = "paid";
      dataobj.lastPaidWill = "paid";
      sessionStorage.setItem("data", JSON.stringify(dataobj));

      //====================//

      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data?.message,
        key: action.key,
      });

      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data?.downloadFinalWill_Link,
        key: "will_download_link",
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: result?.response?.data?.error,
        key: action.key,
      });
    }
  } catch (error) {
    yield put({
      type: "FORM_ERROR",
      payload: "There are some validation error please check & retry",
      moreError: result?.response?.data?.error,
      key: action.key,
    });
  }
}

export function* PaymentSaga() {
  yield takeEvery("payment_success", AddPaymentG);
}
