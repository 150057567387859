import axios from "../interceptor";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../constants";

export const verify_coupon = (action) => {
  return (dispatch) => {
    axios({
      url: api_base + "/customer/verifycoupon",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data?.data?.message,
            key: action.key,
          });

          dispatch({
            type: "GET_SUCCESS",
            payload: response?.data?.data?.downloadFinalWill_Link,
            key: "will_download_link",
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data,
            moreError: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.response?.data,
          moreError: error?.response?.data,
          key: action.key,
        });
      }
    );
  };
};
