import React, { useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, FormikProvider } from "formik";
import {
  initialValues,
  validationSchema,
  getFormData,
  asset_sub_type,
} from "./util";
import { Radio } from "@palmerhq/radio-group";
import { FRadioGroup } from "../../common/RadioButtons";
import SubmitBtn from "../../common/SubmitBtn";
import GobackArrow from "../../common/GobackArrow";
import FieldWithTooltip from "../../common/FieldWithTooltip";

const stateKey = "AddAssetForm";

const Property = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} Property
        </div>
      </div>

      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="col-md-12 mb-3">
                <Form.Label>What type of Real Estate is this?</Form.Label>
                <Field name="asset_sub_type">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="House/Flat/Plot/Land/Shop/Commercial Lot/Office Space/Factory Premises/Parking Space/ Any other type of Real estate"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="col-md-12 mb-3">
                <Form.Label>Address 1</Form.Label>
                <Field name="address_1">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="col-md-12 mb-3">
                <Form.Label>Address 2</Form.Label>
                <Field name="address_2">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="col-md-12 mb-3">
                <Form.Label>Address 3</Form.Label>
                <Field name="address_3">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="col-md-4  col-sm-12 mb-3">
                <Form.Label>City</Form.Label>
                <Field name="city">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group className="col-md-4 col-sm-12 mb-3">
                <Form.Label>State</Form.Label>
                <Field name="state">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group className="col-md-4 col-sm-12 mb-3">
                <Form.Label>Country</Form.Label>
                <Field name="country">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="col-md-4 col-sm-12 mb-3">
                <Form.Label>Pincode</Form.Label>
                <Field name="pincode">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>

              <Form.Group className="col-md-4 col-sm-12 mb-3">
                <FieldWithTooltip
                  label="Ownership Percentage"
                  name="ownership_percentage"
                  text="What % of this property do you own.If you are sole owner, add 100%"
                  formik={formik}
                />
                {/* <Form.Label>Ownership Percentage</Form.Label>
                <Field name="ownership_percentage">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field> */}
              </Form.Group>
            </Row>
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(Property);
