import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../common/Loader/Loader";

const getkey = "nomineeList";

const MyNominees = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props.dispatch({
      type: "get_nomineeList",
      key: getkey,
    });
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [props.list.data]);
  return (
    <>
      {loading && <Loader />}
      <table style={{ border: "1px solid " }}>
        <thead>
          <tr style={{ border: "1px solid " }}>
            <th style={{ border: "1px solid " }}>Nominee Name</th>
            <th style={{ border: "1px solid " }}>Father Name</th>
            <th style={{ border: "1px solid " }}>Relation</th>
            <th style={{ border: "1px solid " }}>Proof Type</th>
            <th style={{ border: "1px solid " }}>Proof Detail</th>
            <th style={{ border: "1px solid " }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {props?.list?.data.length > 0 &&
            props.list.data.map((each, index) => {
              return (
                <tr key={`nom${index}`} style={{ border: "1px solid " }}>
                  <td style={{ border: "1px solid " }}>{each.name}</td>
                  <td style={{ border: "1px solid " }}>{each.father_name}</td>
                  <td style={{ border: "1px solid " }}>{each.relation}</td>
                  <td style={{ border: "1px solid " }}>{each.proof_type}</td>
                  <td style={{ border: "1px solid " }}>{each.proof_detail}</td>
                  <td style={{ border: "1px solid " }}></td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};
export default connect((state) => {
  return {
    list: { ...state?.GetData?.[getkey] },
  };
})(MyNominees);
