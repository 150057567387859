const Item = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="title mb-3 fw-bold">{props.title}</div>
        <p>{props.children}</p>
      </div>
    </div>
  );
};
export default Item;
