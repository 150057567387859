import axios from "../interceptor";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  api_base,
  HTTP_OK,
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_VALIDATION_ERROR,
} from "../../constants";

function getForm(action) {
  return axios({
    url: api_base + "/customer/get_master_asset_list",
    method: "get",
  });
}

function* get_Form_G(action) {
  try {
    var result = yield call(getForm, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function addForm(action) {
  return axios({
    url: api_base + "/customer/add_asset",
    method: "post",
    data: action.payload,
  });
}

function* Form_G(action) {
  try {
    var result = yield call(addForm, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data.message,
        //data: result?.data?.data.assetID,
        data: {
          assetID: result?.data?.data?.assetID,
          masterAssetId: result?.data?.data?.masterAssetId,
        },
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function getSummary() {
  return axios({
    url: api_base + "/customer/get_asset_summary",
    method: "get",
  });
}

function* getAssetSummary_G(action) {
  try {
    var result = yield call(getSummary);
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function getAssetDetail(action) {
  return axios({
    url: api_base + "/customer/asset_detail/" + action.payload,
    method: "get",
  });
}

function* getAssetDetailG(action) {
  try {
    var result = yield call(getAssetDetail, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function updateAssetForm(action) {
  return axios({
    url: api_base + "/customer/update_asset",
    method: "post",
    data: action.payload,
  });
}

function* updateAssetFormG(action) {
  try {
    var result = yield call(updateAssetForm, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data.message,
        //data: result?.data?.data.assetID,
        data: {
          assetID: result?.data?.data?.assetID,
          masterAssetId: result?.data?.data?.masterAssetId,
        },
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function getAssetNominee(action) {
  return axios({
    url: api_base + "/customer/get_asset_nominee/" + action.payload,
    method: "get",
  });
}

function* getAssetNomineeG(action) {
  try {
    var result = yield call(getAssetNominee, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function getAssetName(action) {
  return axios({
    url: api_base + "/customer/get_asset_name/" + action.payload,
    method: "get",
  });
}

function* getAssetNameG(action) {
  try {
    var result = yield call(getAssetName, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function updateAssetNominee(action) {
  return axios({
    url: api_base + "/customer/update_asset_nominee",
    method: "post",
    data: action.payload,
  });
}

function* updateAssetNomineeFormG(action) {
  try {
    var result = yield call(updateAssetNominee, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data.message,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: result?.response?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

//====delete asset=======

function deleteAsset(action) {
  return axios({
    url: api_base + "/customer/deleteAsset",
    method: "post",
    data: action.payload,
  });
}

function* deleteAssetFormG(action) {
  try {
    var result = yield call(deleteAsset, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "FORM_SUCCESS",
        payload: result?.data?.data.message,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: result?.response?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

//======

function get_summary_pdf(action) {
  return axios({
    url: api_base + "/customer/create_asset_summary_pdf/" + action.payload,
    method: "get",
  });
}

function* get_summary_pdfG(action) {
  try {
    var result = yield call(get_summary_pdf, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

function deleteAssetSummaryPdf() {
  return axios({
    url: api_base + "/customer/deleteAssetSummaryPdf",
    method: "get",
  });
}

function* delete_summary_pdfG(action) {
  try {
    var result = yield call(deleteAssetSummaryPdf, action);
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "FORM_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: result?.response?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        moreError: error?.response?.data?.data,
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

//=====================
function download_summary_pdf() {
  return axios({
    url: api_base + "/customer/download_current_asset_summary_pdf",
    method: "get",
  });
}

function* download_current_asset_summaryG(action) {
  try {
    var result = yield call(download_summary_pdf);
    if (result.status === HTTP_OK) {
      yield put({
        type: "GET_SUCCESS",
        payload: result?.data?.data,
        key: action.key,
      });
    }
  } catch (error) {
    if (error?.response?.status == HTTP_UNPROCESSABLE_ENTITY) {
      yield put({
        type: "GET_ERROR",
        payload: "There are some validation error please check & retry",
        key: action.key,
      });
    } else {
      yield put({
        type: "GET_ERROR",
        payload: "Something went wrong",
        key: action.key,
      });
    }
  }
}

export function* AssetSaga() {
  yield takeEvery("add_asset", Form_G);
  yield takeEvery("update_asset", updateAssetFormG);

  yield takeEvery("get_master_assets", get_Form_G);
  yield takeEvery("get_asset_name", getAssetNameG);
  yield takeEvery("get_asset_summary", getAssetSummary_G);
  yield takeEvery("get_asset_detail", getAssetDetailG);
  yield takeEvery("get_Asset_nominee", getAssetNomineeG);
  yield takeEvery("update_Asset_nominee", updateAssetNomineeFormG);
  yield takeEvery("deleteAsset", deleteAssetFormG);
  yield takeEvery("get_asset_summary_pdf", get_summary_pdfG);
  yield takeEvery("delete_asset_summary_pdf", delete_summary_pdfG);

  yield takeEvery(
    "download_current_asset_summary",
    download_current_asset_summaryG
  );
}
