import { IdleTimerProvider, useIdleTimerContext } from "react-idle-timer";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function Child() {
  const idleTimer = useIdleTimerContext();
  return <>{idleTimer.isIdle()}</>;
}

const IdleTimerContainer = (props) => {
  const { dispatch } = props;
  const navigate = useNavigate();

  const onIdle = () => {
    dispatch({
      type: "deleteDraft",
      key: "deleteDraft",
    });

    dispatch({
      type: "User_logout",
      key: "Logout",
    });

    // Close Modal Prompt
    // Do some idle action like log out your user
  };

  //   const onActive = (event) => {
  //     // Close Modal Prompt
  //     // Do some active action
  //   };

  //   const onAction = (event) => {
  //     // Do something when a user triggers a watched event
  //   };

  //   const onPrompt = () => {
  //     // Fire a Modal Prompt
  //   };

  useEffect(() => {
    if (props.formResult.success === true) {
      sessionStorage.clear();
      window.location.href = "/";
      navigate("/");
    }
  }, [props.formResult.success]);

  return (
    <IdleTimerProvider
      timeout={20000 * 60}
      //onPrompt={onPrompt}
      onIdle={onIdle}
      //   onActive={onActive}
      //   onAction={onAction}
    >
      <Child />
    </IdleTimerProvider>
  );
};

export default connect(function (state) {
  return {
    formResult: { ...state?.Forms?.Logout },
  };
})(IdleTimerContainer);
