import * as Yup from "yup";
import { notOnlyWhiteSpace } from "../../../constants";

export const obj = { description: "" };
// export const initialValues = {
//   wishList: [obj],
// };

// export const validationSchema = Yup.object().shape({
//   wishList: Yup.array().of(
//     Yup.object().shape({
//       description: Yup.string()
//         .required("*This is required")
//         .min(2, "*This must have at least 2 characters")
//         //.max(100, "*This can't be longer than 100 characters")
//         .matches(
//           notOnlyWhiteSpace,
//           "*Only Alphabets,Numbers and Spaces are allowed "
//         ),
//     })
//   ),
// });

export const initialValues = {
  description: "",
};

export const validationSchema = Yup.object().shape({
  description: Yup.string()
    .required("*This is required")
    .min(2, "*This must have at least 2 characters")
    .max(1000, "*This can't be longer than 1000 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    ),
});
