import * as Yup from "yup";
import { alphanumeric, notOnlyWhiteSpace } from "../../../constants";

export const initialValues = {
  
  coupon: "",
};

export const validationSchema = Yup.object().shape({
  coupon: Yup.string()
    .required("*This is required")
    .max(10, "*This can't be longer than 11 characters")
    .matches(alphanumeric, "*Only Alphabets,Numbers and Spaces are allowed ")
    .matches(notOnlyWhiteSpace, "*Only White space is not allowed"),

  //   Yup.string()
  //     .min(6, "*Captcha must have at least 6 characters")
  //     .max(6, "*Captcha can't be longer than 6 characters")
  //     .required("*Captcha is required"),
});
