import ReactTooltip from "react-tooltip";

const AssetTooltip = () => {
  return (
    <ReactTooltip
      effect="solid"
      multiline={true}
      className="AssetIconTooltip"
    />
  );
};
export default AssetTooltip;
