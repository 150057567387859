import React, { Fragment, useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, FieldArray, FormikProvider } from "formik";
import { initialValues, validationSchema, listObj, getFormData } from "./util";
import SubmitBtn from "../../common/SubmitBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import GobackArrow from "../../common/GobackArrow";

const stateKey = "AddAssetForm";

const Jewellery = (props) => {
  useEffect(() => {
    props.pull_data({ initialValues, validationSchema });
  }, []);
  const formik = props.formik;
  const firstPath = window.location.pathname.split("/")[1];

  return (
    <>
      <div className="layout__headings">
        <div className="page-heading">
          <GobackArrow />
          {firstPath === "edit-asset" ? "Update" : "Add"} Jewellery
        </div>
      </div>
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FieldArray
              name="jewelleryList"
              render={(arrayHelpers) => {
                const { push, remove, form } = arrayHelpers;
                const { values } = form;
                const row =
                  values !== undefined
                    ? values.jewelleryList?.map((each, index) => {
                        return (
                          <Fragment key={`wishList${index}`}>
                            <Row className="d-flex flex-row-reverse">
                              <div
                                className="col-md-1"
                                style={{ textAlign: "right" }}
                              >
                                {index > 0 && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faCircleXmark}
                                      className="removeFieldIcon mt-lg-3  mt-md-3  mt-sm-3 mt-0"
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group className="col-md-12">
                                <Form.Label>Jewellery Description </Form.Label>
                                <Field
                                  name={`jewelleryList[${index}]['description']`}
                                >
                                  {({ field, meta }) => (
                                    <>
                                      <Form.Control
                                        {...field}
                                        as="textarea"
                                        autoComplete="off"
                                        isInvalid={!!meta.error && meta.touched}
                                        value={field.value ? field.value : ""}
                                      />
                                      {!!meta.error && meta.touched && (
                                        <div className="field-error">
                                          {meta.error}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </Form.Group>
                            </Row>
                          </Fragment>
                        );
                      })
                    : "";

                const result =
                  values !== undefined ? (
                    <>
                      {row}
                      <div className="form-btns mt-4">
                        <button
                          type="button"
                          className="button"
                          onClick={() => push(listObj)}
                        >
                          Add More Jewellery
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  );
                return result;
              }}
            ></FieldArray>
            {/* <Row className="mb-3">
              <Form.Group className="col-md-12">
                <Form.Label>Jewellery Description</Form.Label>
                <Field name="description">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        as="textarea"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Add description here"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Row> */}
            <SubmitBtn text={firstPath === "edit-asset" ? "Update" : "Save"} />
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
  };
})(Jewellery);
