import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ContactForm from "./ContactForm";
import BlogSlider from "./BlogSlider";
import TestimonialSlider from "./TestimonialSlider";
import MasterLayout from "../layout/MasterLayout";
import { useState } from "react";
import LandingPage from "./LandingPage";
import SectionTwo from "./HomeSliders/SectionTwo";
import SectionThree from "./HomeSliders/SectionThree";
import HelpDeskSection from "./HomeSliders/HelpDeskSection";

const Home = () => {
  const [showWebsite, setShowWebsite] = useState(false);
  return (
    <MasterLayout
      showWebsite={showWebsite}
      fluid={showWebsite}
      containerClass="p-lg-0 p-md-0"
      webFooter={showWebsite}
    >
      {showWebsite == false && <LandingPage setShowWebsite={setShowWebsite} />}
      {showWebsite == true && (
        <section className="home-page">
          <section className="section-one position-relative">
            <div className="d-lg-flex d-md-flex">
              <div className="col-md-8 col-sm-12 leftSection">
                <div className="col-md-8 col-sm-12">
                  <h2 className="why-trust-head">
                    Why Trust <br /> Willyourwish ?
                  </h2>
                  <p>
                    With the Legal Terminology, Variety of Laws, Legal Clauses –
                    it can be a really daunting experience to write a Will in
                    India.
                  </p>
                  <p>
                    We have you completely covered from the beginning to the
                    end.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 pt-lg-5 pt-md-5 py-sm-1 px-lg-0 px-md-0 px-sm-4 rightSection">
                <div className="card">
                  <div className="card-body">
                    <div className="serialNo">1</div>
                    <div className="ps-3">
                      <b>Backed by a Strong Legal Team</b>
                      <p>
                        Our Legal Team consists of Retired Judges & Experienced
                        Practicing Lawyers. All their knowledge and countless
                        hours have gone into creating WillyouWish’s Will writing
                        program. So you can rest assured that the Will made is
                        perfectly Legal.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="serialNo">2</div>
                    <div className="ps-3">
                      <b>Privacy Guarantee</b>
                      <p>
                        We Guarantee that we will never sell your Data to any
                        external agencies. Your data is Safe with us and is
                        stored via State-of-the-Art Encryption Algorithms for
                        maximum security.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="serialNo">3</div>
                    <div className="ps-3">
                      <b>Built for Everyone</b>
                      <p>
                        We have worked tirelessly to make the whole process
                        Simple & Easy to operate. You will not only create a
                        Will, but also learn about important Legal aspects in
                        easy to understand language.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-testimonial mt-lg-5 mt-md-4 mt-sm-3">
            <div>
              <div className="mb-lg-5 mb-md-4 mb-sm-2 mobile-view-space">
                <h2 className="section-heading">Our Legal Team</h2>
                <div className="border-line"></div>
              </div>
              <Row>
                <div className="col-lg-12">
                  <TestimonialSlider />
                </div>
              </Row>
            </div>
          </section>
          <section className="section-two position-relative">
            <SectionTwo />
          </section>
          <section className="section-three">
            <SectionThree />
          </section>
          <div className="position-relative four-five-background">
            <section className="section-four my-lg-5 my-md-4 my-sm-3">
              <div className="">
                <div className="mb-lg-5 mb-md-4 mb-sm-2 mobile-view-space">
                  <h2 className="section-heading">Will making process</h2>
                  <div className="border-line"></div>
                </div>
                <Row>
                  <div className="col-md-3 mycard">
                    <div className="card">
                      <div className="card-body">
                        <div className="serialNo">1</div>
                        <div className="text">Add details of your assets</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mycard">
                    <div className="card">
                      <div className="card-body">
                        <div className="serialNo">2</div>
                        <div className="text">
                          Add percentage to your nominees
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mycard">
                    <div className="card">
                      <div className="card-body">
                        <div className="serialNo">3</div>
                        <div className="text">Legal draft will be created</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mycard">
                    <div className="card">
                      <div className="card-body">
                        <div className="serialNo">4</div>
                        <div className="text">Print and sign the will</div>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </section>
            <section className="section-price mt-lg-5 mt-md-4 mt-sm-3">
              <div className="">
                <div className="mb-lg-5 mb-md-4 mb-sm-2 mobile-view-space">
                  <h2 className="section-heading">Price and Plan</h2>
                  <div className="border-line"></div>
                </div>
                <Row>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="pb-3 head">
                          <b>Easywill</b>
                        </div>
                        <div className="pb-3 price">{/* <b>Rs. 100</b> */}</div>
                        <p>
                          Lorem ipsum dolor sit amet, onsectetur adipiscing
                          elit, sed do{" "}
                        </p>
                        <ul>
                          <li>Draft with all your assets and beneficiaries</li>
                          <li> upload signed will copy for future use</li>
                          <li> Upto 5 amendmends to the draft over</li>
                        </ul>
                        <div className="text-center">
                          <button>Select</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="pb-3 head">
                          <b>Notarize</b>
                        </div>
                        <div className="pb-3 price">
                          {/* <b>Rs. 1000</b> */}
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, onsectetur adipiscing
                          elit, sed do{" "}
                        </p>
                        <ul>
                          <li>Draft with all your assets and beneficiaries</li>
                          <li> upload signed will copy for future use</li>
                          <li> Upto 5 amendmends to the draft over</li>
                        </ul>
                        <div className="text-center">
                          <button>Select</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="pb-3 head">
                          <b>Register</b>
                        </div>
                        <div className="pb-3 price">{/* <b>Rs.2000</b> */}</div>
                        <p>
                          Lorem ipsum dolor sit amet, onsectetur adipiscing
                          elit, sed do{" "}
                        </p>
                        <ul>
                          <li>Draft with all your assets and beneficiaries</li>
                          <li> upload signed will copy for future use</li>
                          <li> Upto 5 amendmends to the draft over</li>
                        </ul>
                        <div className="text-center">
                          <button>Select</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </section>
          </div>
          <section className="section-help mt-lg-5 mt-md-4 mt-sm-3">
            <HelpDeskSection />
          </section>       
          <section className="section-blog mt-lg-5 mt-md-4 mt-sm-3">
            <div>
              <div className="mb-lg-5 mb-md-4 mb-sm-2">
                <h2 className="section-heading">Blogs</h2>
                <div className="border-line"></div>
              </div>
              <Row className="px-lg-5 px-md-5 px-sm-1">
                <div className="col-lg-12">
                  <BlogSlider />
                </div>
              </Row>
            </div>
          </section>
        </section>
      )}
    </MasterLayout>
  );
};

export default Home;
