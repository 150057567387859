import { Link } from "react-router-dom";
import {
  addNominee,
  userCategory,
  executorList,
  myNominees,
  aadharpage,
} from "../../route";

export default function OtherLinksPage(props) {
  return (
    <main>
      <li>
        <Link to={aadharpage}>Open first page</Link>
      </li>
      <li>
        <Link to={addNominee}>Add nominee</Link>
      </li>
      <li>
        <Link to={userCategory}>Set Your Category</Link>
      </li>
      <li>
        <Link to={executorList}>Executor List</Link>
      </li>
      <li>
        <Link to={myNominees}>My Nominee List</Link>
      </li>
      <div className="main-layout__area">{props.children}</div>
    </main>
  );
}
