import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Field, FormikProvider, useFormik } from "formik";
import { initialValues, validationSchema } from "./util";
import { Form } from "react-bootstrap";

const stateKey = "terminology";

const Terminology = (props) => {
  const submitHandler = (values) => {
    if (formik.isValid === true) {
      //setLoading(true);
      const { user_captcha, ...formval } = values;
      const formData = new FormData();
      props.dispatch({
        type: "search_terminology",
        payload: formData,
        key: stateKey,
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <div className="d-flex">
          <div className="col-md-8 col-7 me-md-4 me-3">
            <Field name="keyword">
              {({ field, meta }) => (
                <>
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="terminlogoy-search-icon"
                  />
                  <Form.Control
                    {...field}
                    autoComplete="off"
                    isInvalid={!!meta.error && !!meta.touched}
                    value={field.value ? field.value : ""}
                    placeholder="Search by Keyword"
                  />
                  {meta.touched && meta.error && (
                    <div className="field-error">{meta.error}</div>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="col-md-2 col-3">
            <input type="submit" className="button search-btn" value="Search" />
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};
export default connect((state) => {
  return {};
})(Terminology);
