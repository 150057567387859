import * as Yup from "yup";

export const initialValues = {
  
  email: "",
};

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  //   Yup.string()
  //     .min(6, "*Captcha must have at least 6 characters")
  //     .max(6, "*Captcha can't be longer than 6 characters")
  //     .required("*Captcha is required"),
});
