import axios from "../interceptor";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../constants";

export const verifyEmailThunk = (action) => {
  return (dispatch) => {
    axios({
      url: action.url,
      method: "get",
    }).then(
      (response) => {
        console.log('action',response.response.data)
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "GET_SUCCESS",
            payload: response?.data?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "GET_ERROR",
            payload: response?.response?.data?.message,
            moreError: response?.response?.data?.message,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_ERROR",
          payload: error?.response?.data?.data,
          moreError: error?.response?.data?.data,
          key: action.key,
        });
      }
    );
  };
};

export const verify_email_otp = (action) => {
  return (dispatch) => {
    axios({
      url: api_base + "/customer/verifyEmailOtp",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data.message,
            data: response?.data.verified_at,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data,
            moreError: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.response?.data,
          moreError: error?.response?.data,
          key: action.key,
        });
      }
    );
  };
};

export const resend_email_otp = (action) => {
  return (dispatch) => {
    axios({
      url: api_base + "/customer/resendEmailOtp",
      method: "get",
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "GET_SUCCESS",
            payload: response?.data.message,
            key: action.key,
          });
        } else {
          dispatch({
            type: "GET_ERROR",
            payload: response?.response?.data,
            moreError: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_ERROR",
          payload: error?.response?.data,
          moreError: error?.response?.data,
          key: action.key,
        });
      }
    );
  };
};
