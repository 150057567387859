import * as Yup from "yup";
import { numeric } from "../../../constants";

export const initialValues = {
  proof_detail: "",
  //proof_type: "aadhar_card",
};

export const validationSchema = Yup.object().shape({
  //proof_type: Yup.string().required("*This is required"),
  proof_detail: Yup.string()
    .required("*This is required")
    .min(10, "*This must have at least 10 characters")
    .max(30, "*This can't be longer than 30 characters")
    .matches(numeric, "*Only Numbers are allowed "),

});
