import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import SummaryDraftModal from "../SummaryDraftModal";

const getKey = "AssetSummaryPdf";
const AssetSummaryDropDownItem = (props) => {
  const [showDraftPopup, setShowDraftPopup] = useState(false);

  const getAssetSummary = () => {
    props.dispatch({
      type: "get_asset_summary_pdf",
      payload: props.id,
      key: getKey,
    });
  };

  const draftModal_closeHandler = () => {
    setShowDraftPopup(false);
  };
  // useEffect(() => {
  //   if (props.result.success == true) {
  //     setShowDraftPopup(true);
  //   }
  // }, [props.result]);

  return (
    <>
      {props?.result?.success == true && (
        <SummaryDraftModal handleClose={draftModal_closeHandler} />
      )}
      <Dropdown.Item
        href="#"
        onClick={() => {
          getAssetSummary();
        }}
      >
        Asset Summary
      </Dropdown.Item>
    </>
  );
};

export default connect((state) => {
  return {
    result: { ...state?.GetData?.[getKey] },
  };
})(AssetSummaryDropDownItem);
