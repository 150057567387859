import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getkey = "nomineeList";

const MyNomineesDropdown = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props.dispatch({
      type: "get_nomineeList",
      key: getkey,
    });
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [props.list.data]);

  const selectNominee = (selectedKey) => {
    props.setSelectedNominee(props.list.data[selectedKey]);
  };

  const deSelectNominee = () => {
    props.setSelectedNominee({});
  };
  var nameList =
    props?.list?.data.length > 0 &&
    props.list.data.map((each, key) => {
      return (
        <span key={key}>
          <cite
            onClick={() => {
              selectNominee(key);
            }}
            style={{ fontStyle: "normal" }}
          >
            {each.name}
          </cite>
          <FontAwesomeIcon
            icon={faXmark}
            className="cancel-icon"
            onClick={() => {
              deSelectNominee(each.name);
            }}
          />
        </span>
      );
    });
  return (
    <>
      {loading && <Loader />}
      <div className="name-list">
        {nameList}
        <span
          key="new"
          onClick={() => {
            props.setSelectedNominee("new");
          }}
        >
          Add New
        </span>
      </div>
    </>
  );
};
export default connect((state) => {
  return { list: { ...state?.GetData?.[getkey] } };
})(MyNomineesDropdown);
