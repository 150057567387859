import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, FormikProvider, useFormik } from "formik";
import { useState, useEffect } from "react";
import MainLayout from "../../layout/Mainlayout";
import SubmitBtn from "../common/SubmitBtn";
import ToolTip from "../common/ToolTip";
import { initialValues, validationSchema } from "./util";
import Loader from "../common/Loader/Loader";
import { userCategory } from "../../../route";
import { useNavigate } from "react-router-dom";
import CustomModal from "../common/CustomModal";
import { customiseFormSuccessMsg } from "../../../util";
import FieldWithTooltip from "../common/FieldWithTooltip";

const stateKey = "add_user_id_proof";
const getKey = "get_user_id_proof";

const Aadhar_Page = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [initial_values, setInitial_values] = useState(initialValues);

  useEffect(() => {
    props.dispatch({
      type: "Get_user_Id_proof",
      key: getKey,
    });
  }, []);

  useEffect(() => {
    //if (props.detail?.data?.aadhar_card?.length > 0) {
    setInitial_values({
      ...initial_values,
      proof_detail: props.detail?.data?.aadhar_card,
    });
    //}
  }, [props.detail.data]);

  const submitHandler = (values) => {
    

    if (formik.isValid === true) {
      setLoading(true);
      let formData = new FormData();
      formData.append("proof_type", "aadhar_card");
      formData.append("proof_detail", values["proof_detail"]);

      props.dispatch({
        type: "Add_user_Id_proof",
        payload: formData,
        key: stateKey,
      });
    }
    // } else {
    //   setCaptchaError(true);
    // }
    
    
  };
  const formik = useFormik({
    initialValues: initial_values,
    values: initial_values,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (props.formResult.error === true || props.formResult.success === true) {
      setLoading(false);
    }
    if (props.formResult.success === true) {
      setTimeout(() => {
        formik.resetForm();
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });

        navigate(userCategory);
      }, 1000);
    }
  }, [props.formResult.success, props.formResult.error]);

  return (
    <MainLayout>
      {loading && <Loader />}
      <div className="layout__headings">
        <div className="page-heading">Aadhaar Card Validation</div>
      </div>
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {props?.formResult?.success === true && (
        <CustomModal
          body={customiseFormSuccessMsg(props.formResult.message)}
          stateKey={stateKey}
          type="success"
        />
      )}
      <div className="main-layout-cont">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3 col-md-5">
              <FieldWithTooltip
                label="Enter your Aadhaar Card Number"
                name="proof_detail"
                text="Type your 12 digit number"
              />
              {/* <Form.Label>
                Enter your Aadhaar Card Number
                <ToolTip text="Ener your ID proof Detail" />
              </Form.Label>
              <Field name="proof_detail">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="text"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      placeholder="Type your 12 digit number"
                    />
                    {meta.touched && meta.error && (
                      <div className="field-error">{meta.error}</div>
                    )}
                  </>
                )}
              </Field> */}
            </Form.Group>

            <SubmitBtn text="Save" />
          </Form>
        </FormikProvider>
      </div>
    </MainLayout>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state.Forms[stateKey] },
    detail: { ...state.GetData[getKey] },
  };
})(Aadhar_Page);
