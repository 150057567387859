import { Formik, FormikProvider, Field, useFormik } from "formik";
import { Form } from "react-bootstrap";
import { initialValues, validationSchema } from "./util";
import { verify_coupon } from "../../../redux/AllThunk/CouponThunk";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../common/Loader/Loader";
import CustomModal from "../common/CustomModal";
import { paymentSuccess } from "../../../route";
import { useNavigate } from "react-router-dom";

const stateKey = "coupon";

const Coupon = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submitHandler = (values) => {
    
    if (formik.isValid === true) {
      setLoading(true);

      props.dispatch(
        verify_coupon({
          payload: {
            coupon: values.coupon,
          },
          key: stateKey,
        })
      );
    }
    // } else {
    //   setCaptchaError(true);
    // }
    
    values.user_captcha = "";
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    setLoading(false);

    if (props.formResult.success === true) {
      setTimeout(() => {
        formik.resetForm();
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
        navigate(paymentSuccess);
      }, 2000);
    }
  }, [props?.formResult?.success, props?.formResult?.error]);

  return (
    <>
      {loading === true && <Loader />}
      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props.formResult.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      {props?.formResult?.success === true && (
        <CustomModal
          body={props.formResult.message}
          stateKey={stateKey}
          type="success"
        />
      )}
      <div className="couponBox">
        <p>
          <b>Use Coupen Code & Proceed for free</b>
        </p>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} className="d-flex">
            <div className="col-4 ">
              <Field name="coupon">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="text"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      placeholder="Please enter Coupon code here"
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className="offset-1 col-4 ">
              <button className="button mt-auto" type="submit">
                Apply
              </button>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[stateKey] },
  };
})(Coupon);
