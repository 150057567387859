import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { selectAsset, personalDetail,willtype } from "../../../route";
import CustomModal from "./CustomModal";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Loader from "../common/Loader/Loader";

const stateKey = "create_new_will";
const getKey = "PersonalDetail";

const MakeWillBtn = (props) => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  const handleYes = () => {

    navigate(willtype);

    /* let formData = new FormData();
    formData.append("type", "continue");
    props.dispatch({
      type: "createNewWill",
      key: stateKey,
      payload: formData,
    }); */


    setShow(false);
    setLoading(true);
  };

  const handleNo = () => {
    let formData = new FormData();
    formData.append("type", "fresh");
    props.dispatch({
      type: "createNewWill",
      key: stateKey,
      payload: formData,
    });
    setShow(false);
    setLoading(true);
  };
  const startMakingWill = () => {
    let data = sessionStorage.getItem("data");
    const dataobj = JSON.parse(data);
    dataobj?.lastPaidWill !== null ? setShow(true) : handleYes();
  };

  useEffect(() => {
    setLoading(false);
    if (props.formResult.success == true) {
      setTimeout(() => {
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
        if (props.getData.data.proof_type.length > 0) {
          navigate(selectAsset);
        } else {
          navigate(personalDetail);
        }
      }, 1000);
    }
  }, [props.formResult]);
  return (
    <>
      {loading && <Loader />}

      {props?.formResult?.error === true && (
        <CustomModal
          body={props.formResult.message}
          moreError={props?.formResult?.moreError}
          stateKey={stateKey}
          type="error"
        />
      )}
      <button
        onClick={() => {
          startMakingWill();
        }}
        className="mt-auto button"
      >
        Start making will
      </button>
      <Modal show={show} centered onHide={handleClose}>
        <FontAwesomeIcon
          icon={faCircleXmark}
          onClick={handleClose}
          className="modalCloseIcon"
        />
        <Modal.Body>
          <p className="text-center">
            <b>Do you want to use previous will data in next will?</b>
          </p>
          <div className="mt-5">
            <button type="button" className="button col-4" onClick={handleYes}>
              Yes - Auto fill
            </button>
            <button
              type="button"
              className="button col-4 float-end col-offset-1"
              onClick={handleNo}
            >
              No - Start Fresh
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[stateKey] },
    getData: { ...state.GetData[getKey] },
  };
})(MakeWillBtn);
